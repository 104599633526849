import React, { useEffect, useState } from "react";
import "./widgetSm.css";
import CryptoJS from "crypto-js";
import { KEY } from "../../Constants";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      fontSize: 16,
      color: "darkblue",
      backgroundColor: "rgb(245,245,245)",
    },
  },
  userListUser: {
    display: "flex",
  },
  dataGridContainer: {
    height: "350px",
  },
  widgetSmTitle: {
    fontWeight: 600,
    fontSize: "18px",
    color: "#333",
    marginBottom: "20px",
  },
});

export default function WidgetSm() {
  const classes = useStyles();
  const [tenants, setTenants] = useState([]);
  const [status, setStatus] = useState(localStorage.getItem("isNew"));

  useEffect(() => {
    const decrypted = CryptoJS.AES.decrypt(localStorage.getItem("portfolioDetails"), KEY);
    const originalText = decrypted.toString(CryptoJS.enc.Utf8);
    const tenantsData = Array.from(new Map(JSON.parse(originalText)).get("tenants"));
    setTenants(tenantsData);
  }, []);

  const columns = [
    {
      field: "user",
      headerName: "Name",
      width: 200,
      renderCell: (params) => (
          <div className={classes.userListUser}>
            {params.row.first_name} {params.row.last_name}
          </div>
      ),
    },
    {
      field: "phone_number",
      align: "center",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "date_created",
      align: "center",
      headerName: "Date",
      width: 200,
    },
  ];

  return (
      <div className="widgetSm">
        <span className={classes.widgetSmTitle}>New Tenants</span>
        <div className={classes.dataGridContainer}>
          <DataGrid
              className={classes.root}
              rows={tenants}
              disableSelectionOnClick
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
          />
        </div>
      </div>
  );
}
