import React, {useEffect, useState} from "react";
import "./testSidebar.css";
import {Menu, MenuItem, Sidebar, SubMenu, useProSidebar,} from "react-pro-sidebar";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {Link} from "react-router-dom";
import {
    BackupOutlined,
    BusinessOutlined,
    CalendarTodayOutlined,
    ChatBubbleOutlineOutlined,
    DashboardOutlined,
    ErrorOutlineOutlined,
    MonetizationOnOutlined,
} from "@material-ui/icons";
import logo from "../../assets/react-logo-no-background.svg";
import {
    AutoGraphOutlined,
    ElderlyOutlined,
    GroupsOutlined,
    LogoutOutlined,
    MoneyOffCsredOutlined,
    PaymentsOutlined,
} from "@mui/icons-material";
import {useMediaQuery} from "@mui/material";
import CryptoJS from "crypto-js";
import {KEY} from "../../Constants";

export default function TestSidebar(props) {
    const matches = useMediaQuery("(max-width:800px)");

    let myMapDetailsDecrypted;
    try {
        myMapDetailsDecrypted = CryptoJS.AES.decrypt(
            localStorage.getItem("myMap"),
            KEY
        );
    } catch (e) {
        myMapDetailsDecrypted = "";
    }

    const [originalText, setOriginalText] = React.useState(
        myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8)
    );

    let loadUserManagement = false

    try {
        let decodedOriginalText = JSON.parse(originalText);
        // console.log(decodedOriginalText)
        loadUserManagement = true
    } catch (e) {
        loadUserManagement = false;
    }
    //
    // console.log("original text : ")
    // console.log(originalText)

    const [agentId, setAgentId] = useState(true);

    // console.log("Agentid : " + agentId)

    const [logoDisplay, setLogoDisplay] = useState(true);
    const [forceUpdate, setForceUpdate] = useState(false);
    const {collapseSidebar} = useProSidebar();

    useEffect(() => {
        if (matches) {
            collapseSidebar();
            setLogoDisplay(false);
        }
    }, [matches]);

    const logout = () => {
        // 👇️ toggle class on click
        localStorage.clear();
        // props.fn();
        window.location.href = "/";
    };

    return {
        setForceUpdate,
        sideBar: (
            <div
                style={{
                    zIndex: 1000,
                    display:
                        window.location.pathname === "/" ||
                        window.location.pathname === "/signup"
                            ? "none"
                            : "",
                }}
            >
                <div id="app">
                    <Sidebar style={{height: "100vh", position: "sticky", top: "0"}}>
                        <Menu
                            menuItemStyles={{
                                button: ({level, active, disabled}) => {
                                    // only apply styles on first level elements of the tree
                                    // if (level === 0)
                                    if (level === 0) {
                                        return {
                                            color: active ? "#FFFFFF" : undefined,
                                            backgroundColor: active ? "#3D8B84" : undefined,
                                        };
                                    } else {
                                        return {
                                            color: active ? "#FFFFFF" : undefined,
                                            backgroundColor: active ? "#67BC89" : undefined,
                                        };
                                    }
                                },
                            }}
                        >
                            <MenuItem
                                icon={<MenuOutlinedIcon/>}
                                onClick={() => {
                                    if (!matches) {
                                        setLogoDisplay(!logoDisplay);
                                    }
                                    collapseSidebar();
                                }}
                                style={{textAlign: "center"}}
                            >
                                {" "}
                                {/*<h2>Hide</h2>*/}
                            </MenuItem>

                            <div
                                className="topLeft"
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <img
                                    style={{
                                        width: "200px",
                                        height: "150px",
                                        display: logoDisplay ? "" : "none",
                                    }}
                                    src={logo}
                                    alt=""
                                />
                            </div>

                            <MenuItem
                                active={window.location.pathname === "/home"}
                                component={<Link to="/home"/>}
                                icon={<DashboardOutlined
                                    style={{
                                        color: "#9B245A"
                                    }}/>}
                            >
                                Dashboard
                            </MenuItem>

                            <MenuItem
                                active={window.location.pathname === "/analytics"}
                                component={<Link to={"/analytics"}/>}
                                icon={<AutoGraphOutlined
                                    style={{
                                        color: "#9B245A"
                                    }}
                                />}
                            >
                                Analytics
                            </MenuItem>

                            <SubMenu
                                icon={<GroupsOutlined
                                    style={{
                                        color: "#9B245A"
                                    }}
                                />}
                                label="Tenants"
                                active={window.location.pathname
                                    .toString()
                                    .toLowerCase()
                                    .includes("tenant")}
                            >
                                <MenuItem
                                    active={window.location.pathname === "/tenantList"}
                                    component={<Link to="/tenantList"/>}
                                    icon={<PeopleOutlinedIcon
                                        style={{
                                            color: "#9B245A"
                                        }}
                                    />}
                                >
                                    All tenants
                                </MenuItem>

                                <MenuItem
                                    active={window.location.pathname === "/vacatingTenantList"}
                                    component={<Link to={"/vacatingTenantList"}/>}
                                    icon={<PeopleOutlinedIcon
                                        style={{
                                            color: "#9B245A"
                                        }}
                                    />}
                                >
                                    Vacating tenants
                                </MenuItem>
                                <MenuItem
                                    active={window.location.pathname === "/oldTenantList"}
                                    component={<Link to={"/oldTenantList"}/>}
                                    icon={<ElderlyOutlined
                                        style={{
                                            color: "#9B245A"
                                        }}
                                    />}
                                >
                                    Old tenants
                                </MenuItem>
                                <MenuItem
                                    active={window.location.pathname === "/tenantIssues"}
                                    component={<Link to={"/tenantIssues"}/>}
                                    icon={<ErrorOutlineOutlined
                                        style={{
                                            color: "#9B245A"
                                        }}
                                    />}
                                >
                                    Tenant issues
                                </MenuItem>
                            </SubMenu>
                            <MenuItem
                                active={window.location.pathname === "/propertyList"}
                                component={<Link to={"/propertyList"}/>}
                                icon={<BusinessOutlined
                                    style={{
                                        color: "#9B245A"
                                    }}
                                />}
                            >
                                Properties
                            </MenuItem>
                            <SubMenu
                                icon={<MonetizationOnOutlined
                                    style={{
                                        color: "#9B245A"
                                    }}
                                />}
                                label="Money"
                                active={
                                    window.location.pathname
                                        .toString()
                                        .toLowerCase()
                                        .includes("transactions") ||
                                    window.location.pathname
                                        .toString()
                                        .toLowerCase()
                                        .includes("expenses")
                                }
                            >
                                <MenuItem
                                    active={window.location.pathname === "/transactions"}
                                    component={<Link to="/transactions"/>}
                                    icon={<PaymentsOutlined
                                        style={{
                                            color: "#9B245A"
                                        }}
                                    />}
                                >
                                    Transactions
                                </MenuItem>
                                <MenuItem
                                    active={window.location.pathname === "/expenses"}
                                    component={<Link to={"/expenses"}/>}
                                    icon={<MoneyOffCsredOutlined
                                        style={{
                                            color: "#9B245A"
                                        }}
                                    />}
                                >
                                    Expenses
                                </MenuItem>
                            </SubMenu>
                            <MenuItem
                                active={window.location.pathname === "/messages"}
                                component={<Link to={"/messages"}/>}
                                icon={<ChatBubbleOutlineOutlined
                                    style={{
                                        color: "#9B245A"
                                    }}
                                />}
                            >
                                Messages
                            </MenuItem>

                            {/*<MenuItem icon={<PeopleOutlinedIcon />}>Team</MenuItem>*/}
                            {/*<MenuItem icon={<ContactsOutlinedIcon />}>Contacts</MenuItem>*/}

                            {/*<MenuItem*/}
                            {/*    active={window.location.pathname === "/events"}*/}
                            {/*    component={<Link to={"/events"}/>}*/}
                            {/*    icon={*/}
                            {/*        <CalendarTodayOutlined*/}
                            {/*            style={{*/}
                            {/*                color: "#9B245A"*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    }*/}

                            {/*>*/}
                            {/*    Calender*/}
                            {/*</MenuItem>*/}

                            <MenuItem
                                active={window.location.pathname === "/backup"}
                                component={<Link to={"/backup"}/>}
                                icon={
                                    <BackupOutlined
                                        style={{
                                            color: "#9B245A"
                                        }}
                                    />
                                }
                            >
                                Backup
                            </MenuItem>

                            {/*<MenuItem*/}
                            {/*    active={window.location.pathname === "/settings"}*/}
                            {/*    component={<Link to={"/settings"}/>}*/}
                            {/*    icon={<SettingsOutlined*/}
                            {/*        style={{*/}
                            {/*            color:"#9B245A"*/}
                            {/*        }}*/}
                            {/*    />}*/}
                            {/*>*/}
                            {/*    Settings*/}
                            {/*</MenuItem>*/}


                            {/*<MenuItem*/}
                            {/*  active={window.location.pathname === "/settings"}*/}
                            {/*  component={<Link to={"/settings"} />}*/}
                            {/*  icon={<SupportAgent />}*/}
                            {/*>*/}
                            {/*  Customer care*/}
                            {/*</MenuItem>*/}
                            <MenuItem onClick={logout} icon={<LogoutOutlined
                                style={{
                                    color: "#E77772"
                                }}
                            />}>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Sidebar>
                </div>
            </div>
        ),
    };
}
