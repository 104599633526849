import "./propertyList.css";
import React, { useEffect } from "react";
import { DeleteOutline } from "@mui/icons-material";
import { ApiDetails, KEY } from "../../Constants";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import CryptoJS from "crypto-js";
import { useMediaQuery } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      fontSize: 16,
      color: "darkblue",
      backgroundColor: "rgb(245,245,245)",
    },
  },
});
const steps = [
  "Enter property name",
  "Download Template",
  "Fill Template",
  "Upload Preperty Details",
];
export default function PropertyList(props) {
  const matches = useMediaQuery("(max-width:700px)");

  const navigate = useNavigate();
  const classes = useStyles();
  let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
    localStorage.getItem("myMap"),
    KEY
  );
  let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(
    localStorage.portfolioDetails,
    KEY
  );
  let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);
  let originalText2 = portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8);

  //Loading Screen
  const [backDrop, setBackDrop] = useState(
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={null}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  let [properties, updateProperties] = React.useState([]);
  const [searchResults, setSearchResults] = useState(properties);

  //Trying To Use UseEffect To Set Component Data
  useEffect(() => {
    props.refreshSideBar();
    //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
    fetch(ApiDetails + "pegasus/visionary/property/get/all/properties", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
      }),
    })
      .then(async (response) => {
        let data = await response.json();

        if (response.status === 200) {
          // console.log(data);
          updateProperties(data);
          setSearchResults(data);
          setBackDrop(<></>);
          // setDetailsFetched(true);
        } else {
          props.snackBar({ text: "Failed Fetching Details", type: "error" });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const [open, setOpen] = useState(false);
  const [addPropertyModal, setAddPropertyModal] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({
    id: "",
    name: "",
  });

  const [linkSet, setLinkSet] = useState(false);
  const [file, setFile] = useState("");
  const [downloadedTemplate, setDownloadedTemplate] = useState(false);
  const searchProperty = (e) => {
    setSearchResults(
      Array.from(properties).filter((item) =>
        item.property_name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleClose = () => {
    setOpen(false);
    setAddPropertyModal(false);
  };

  function changePage(page) {
    navigate("/property/" + page, {
      state: {
        property_id: page,
      },
    });
  }

  const handleDelete = () => {
    fetch(ApiDetails + "pegasus/visionary/property/delete", {
      method: "POST",
      body: JSON.stringify({ property_id: propertyDetails.id }),
    })
      .then(async (response) => {
        if (response.status === 200) {
          handleClose();
          getUserDetails({
            landlord_id: JSON.parse(originalText)[0][1],
          });
          props.snackBar({
            text: "Property Deleted Successfully",
            type: "success",
          });
        } else if (response.status === 401) {
          props.snackBar({
            text: "Maximum concurrent logins reached",
            type: "warning",
          });
        } else if (response.status === 500) {
          props.snackBar({
            text: "Something went wrong. Please try again later.",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Try Again Later",
          type: "error",
        });
      });
  };

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  let newPropertyDetails = null;
  let newPropertyName = "";

  const getUserDetails = () => {
    //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
    fetch(ApiDetails + "pegasus/visionary/property/get/all/properties", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
      }),
    })
      .then(async (response) => {
        let data = await response.json();

        if (response.status === 200) {
          // console.log(data);
          updateProperties(data);
          setSearchResults(data);
          // setDetailsFetched(true);
        } else {
          props.snackBar({ text: "Failed Fetching Details", type: "error" });
        }
      })
      .catch((err) => console.log(err));
  };
  // const handleChange = () => (event) => {
  //   setNewPropertyName(event.target.value);
  //   searchHistory.set(event.target.name, event.target.value);
  // };
  function base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
  const download = () => {
    //Download File
    loadingTrue();
    const blob = base64ToBlob(file);
    const blobURL = URL.createObjectURL(blob);
    const link = document.createElement("a");
    //link.setAttribute('target', '_blank');
    link.setAttribute("download", `New Property`);
    link.href = blobURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadedTemplate(true);
    loadingFalse();
    //Move to next page here
    // eslint-disable-next-line no-unused-expressions
    //next();
  };
  const [next, setNext] = useState(false);
  const getDownloadLink = (values) => {
    newPropertyName = document.getElementById("newPropertyName").value;
    if (newPropertyName.trim() === "") {
      props.snackBar({
        text: "Property name can not be blank",
        type: "error",
      });
      return;
    }
    getLinkLoading(true);
    // return;
    if (download) {
      fetch(ApiDetails + "pegasus/visionary/files/download", {
        method: "POST",
        body: JSON.stringify({
          email_address: JSON.parse(originalText)[5][1],
          Property_Name: newPropertyName,
        }),
      })
        .then(async (response) => {
          let data = await response.json();
          if (response.status === 200) {
            getLinkLoading(false);
            setLinkSet(true);
            setFile(data["File"]);
            setNext(true);
          } else if (response.status === 401) {
            getLinkLoading(false);
          } else if (response.status === 400) {
            getLinkLoading(false);
          }
        })
        .catch((err) => {
          getLinkLoading(false);
          console.log(err);
        });
    }
    //props.resetForm();
  };
  function handleFile(e) {
    // setNewPropertyDetails(e.target.files[0]);
  }
  const uploadFile = () => {
    setUploadingFile(true);
    if (newPropertyDetails === null) {
      props.snackBar({
        text: "Please Select File To Upload",
        type: "error",
      });
      return;
    }
    loadingToSendTrue();
    let formData = new FormData();
    formData.append(
      "details",
      JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
        email_address: JSON.parse(originalText)[5][1],
      })
    );

    formData.append("updated_document", newPropertyDetails);

    fetch(ApiDetails + "pegasus/visionary/files/finishSetup", {
      method: "POST",
      body: formData,
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.status === 200) {
          getUserDetails({
            landlord_id: JSON.parse(originalText)[0][1],
          });
          //new Map(JSON.parse(localStorage.portfolioDetails));
          props.snackBar({
            text: "Property Added Successfully",
            type: "success",
          });
          setNext(true);
          setUploadingFile(false);
        } else if (response.status === 401) {
          props.snackBar({
            text: "Something went wrong. Please try again later",
            type: "error",
          });
          setUploadingFile(false);
        } else if (response.status === 400) {
          props.snackBar({
            text: "Something went wrong. Please try again later",
            type: "error",
          });
          setUploadingFile(false);
        } else {
          setUploadingFile(false);
          let error = new Map(Object.entries(data)).get("Message");

          if (error.includes("properties_property_name_landlord_id_uindex")) {
            props.snackBar({
              text: "Property With Provided Name Already Exists.",
              type: "error",
            });
          } else {
            props.snackBar({
              text: "Something went wrong. Please try again later",
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    loadingToSendFalse();
  };

  const [loading, setLoading] = React.useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [linkLoading, getLinkLoading] = React.useState(false);

  function loadingTrue() {
    setLoading(true);
  }
  function loadingFalse() {
    setLoading(false);
  }

  const [loadingToSend, setLoadingToSend] = React.useState(false);
  function loadingToSendTrue() {
    setLoadingToSend(true);
  }
  function loadingToSendFalse() {
    setLoadingToSend(false);
  }

  const handleNextToFileUpload = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    setNext(false);
    setContent(
      <>
        {downloadedTemplate}

        <div>
          <Typography></Typography>
          <Typography>
            Select the excel file you downloaded and edited
          </Typography>
          <br />
          <input
            type={"file"}
            onChange={(e) => {
              newPropertyDetails = e.target.files[0];
            }}
          />{" "}
          <br />
          <LoadingButton
            style={{
              marginTop: 10,
            }}
            onClick={uploadFile}
            endIcon={<SendIcon />}
            loading={uploadingFile}
            loadingPosition="end"
            variant="contained"
          >
            Upload
          </LoadingButton>
        </div>
      </>
    );
  };

  //Test Stepper
  //Test Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const [content, setContent] = useState(
    <>
      <div>
        <TextField
          required
          id="newPropertyName"
          label={"property Name"}
          name={"newPropertyName"}
          type={"text"}
          variant="outlined"
          style={{ width: "100%" }}
        />
      </div>
      <br />
      <>
        <Typography variant="caption">Generate Download Link</Typography>

        <br />

        <LoadingButton
          style={{
            marginTop: 10,
          }}
          variant="contained"
          color="primary"
          onClick={getDownloadLink}
          endIcon={<SendIcon />}
          loading={linkLoading}
          loadingPosition="end"
        >
          Get Link
        </LoadingButton>

        <Button
          style={{
            marginTop: 10,
          }}
          // variant="contained"
          // color="primary"
          onClick={handleNextToFileUpload}
        >
          Already have template file?
        </Button>
      </>
    </>
  );
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if (activeStep + 1 === 0) {
      setContent(
        <>
          <div>
            <TextField
              required
              id="newPropertyName"
              label={"property Name"}
              name={"newPropertyName"}
              type={"text"}
              variant="outlined"
              style={{ width: "100%" }}
            />
          </div>
          <br />
          <>
            <Typography variant="caption">Generate Download Link</Typography>

            <br />

            <LoadingButton
              style={{
                marginTop: 10,
              }}
              variant="contained"
              color="primary"
              onClick={getDownloadLink}
              endIcon={<SendIcon />}
              loading={linkLoading}
              loadingPosition="end"
            >
              Get Link
            </LoadingButton>

            <Button
              style={{
                marginTop: 10,
              }}
              // variant="contained"
              // color="primary"
              onClick={handleNextToFileUpload}
            >
              Already have template file?
            </Button>
          </>
        </>
      );
    } else if (activeStep + 1 === 1) {
      setContent(
        <>
          <>
            <p>
              Please download and fill in your property details in the excel
              document provided below as accurately as possible.
            </p>
            <br />
            <p>
              The first sheet represents an example of what is expected of you.
            </p>
            <br />
            <p>
              Make sure to input the : - House number, - Water meter reading ie:
              put 0 if not applicable - Electrcity merter reading ie: put 0 if
              not applicable For all units even if the unit is vacant.
            </p>

            <br />
            <br />
            <Typography variant="caption">Click to download</Typography>

            <br />

            <LoadingButton
              style={{
                marginTop: 10,
              }}
              onClick={download}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              DOWNLOAD
            </LoadingButton>
          </>
        </>
      );
    } else if (activeStep + 1 === 2) {
      setContent(
        <>
          <p>
            Please fill in your property details in the excel document you
            downloaded as accurately as possible.
          </p>
          <br />
          <p>
            Make sure to input the : - House number, - Water meter reading ie:
            put 0 if not applicable - Electrcity merter reading ie: put 0 if not
            applicable For all units even if the unit is vacant.
          </p>
          <br />
          <p>
            Kindly note that if the document is note filled following the states
            guidelines it might not go through.
          </p>
        </>
      );
    } else if (activeStep + 1 === 3) {
      setNext(false);
      setContent(
        <>
          {downloadedTemplate}

          <div>
            <Typography></Typography>
            <Typography>
              Select the excel file you downloaded and edited
            </Typography>
            <br />
            <input
              type={"file"}
              onChange={(e) => {
                newPropertyDetails = e.target.files[0];
              }}
            />{" "}
            <br />
            <LoadingButton
              style={{
                marginTop: 10,
              }}
              onClick={uploadFile}
              endIcon={<SendIcon />}
              loading={uploadingFile}
              loadingPosition="end"
              variant="contained"
            >
              Upload
            </LoadingButton>
          </div>
        </>
      );
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep - 1 === 0) {
      setContent(
        <>
          <div>
            <TextField
              required
              id="newPropertyName"
              label={"property Name"}
              name={"newPropertyName"}
              type={"text"}
              variant="outlined"
              style={{ width: "100%" }}
            />
          </div>
          <br />
          <>
            <Typography variant="caption">Generate Download Link</Typography>

            <br />

            <LoadingButton
              style={{
                marginTop: 10,
              }}
              variant="contained"
              color="primary"
              onClick={getDownloadLink}
              endIcon={<SendIcon />}
              loading={linkLoading}
              loadingPosition="end"
            >
              Get Link
            </LoadingButton>
          </>
        </>
      );
    } else if (activeStep - 1 === 1) {
      setContent(
        <>
          {linkSet === false ? (
            <div
              style={{
                visibility: "hidden",
              }}
            >
              <p>
                Please download and fill in your property details in the excel
                document provided below as accurately as possible.
              </p>
              <br />
              <p>
                The first sheet represents an example of what is expected of
                you.
              </p>
              <br />
              <p>
                Make sure to input the : - House number, - Water meter reading
                ie: put 0 if not applicable - Electrcity merter reading ie: put
                0 if not applicable For all units even if the unit is vacant.
              </p>

              <br />
              <br />
              <Typography variant="caption">Click to download</Typography>

              <br />

              <Button
                style={{
                  marginTop: 10,
                }}
                variant="contained"
                color="primary"
                onClick={download}
              >
                DOWNLOAD
              </Button>
            </div>
          ) : (
            <>
              <p>
                Please download and fill in your property details in the excel
                document provided below as accurately as possible.
              </p>
              <br />
              <p>
                The first sheet represents an example of what is expected of
                you.
              </p>
              <br />
              <p>
                Make sure to input the : - House number, - Water meter reading
                ie: put 0 if not applicable - Electrcity merter reading ie: put
                0 if not applicable For all units even if the unit is vacant.
              </p>

              <br />
              <br />
              <Typography variant="caption">Click to download</Typography>

              <br />

              <LoadingButton
                style={{
                  marginTop: 10,
                }}
                onClick={download}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                variant="contained"
              >
                DOWNLOAD
              </LoadingButton>
            </>
          )}
        </>
      );
    } else if (activeStep - 1 === 2) {
      setContent(
        <>
          <p>
            Please fill in your property details in the excel document you
            downloaded as accurately as possible.
          </p>
          <br />
          <p>
            Make sure to input the : - House number, - Water meter reading ie:
            put 0 if not applicable - Electrcity merter reading ie: put 0 if not
            applicable For all units even if the unit is vacant.
          </p>
          <br />
          <p>
            Kindly note that if the document is note filled following the states
            guidelines it might not go through.
          </p>
        </>
      );
    } else if (activeStep - 1 === 3) {
      setContent(
        <>
          {downloadedTemplate === false ? (
            <></>
          ) : (
            <div>
              <Typography></Typography>
              <Typography>
                Select the excel file you downloaded and edited
              </Typography>
              <br />
              <input
                type={"file"}
                onChange={(e) => {
                  newPropertyDetails = e.target.files[0];
                }}
              />{" "}
              <br />
            </div>
          )}
        </>
      );
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
    if (activeStep + 1 === 0) {
      setContent(
        <>
          <div>
            <TextField
              required
              id="newPropertyName"
              label={"property Name"}
              name={"newPropertyName"}
              type={"text"}
              variant="outlined"
              style={{ width: "100%" }}
            />
          </div>
          <br />
          <>
            <Typography variant="caption">Generate Download Link</Typography>

            <br />

            <LoadingButton
              style={{
                marginTop: 10,
              }}
              variant="contained"
              color="primary"
              onClick={getDownloadLink}
              endIcon={<SendIcon />}
              loading={linkLoading}
              loadingPosition="end"
            >
              Get Link
            </LoadingButton>
          </>
        </>
      );
    } else if (activeStep + 1 === 1) {
      setContent(
        <>
          {linkSet === false ? (
            <div
              style={{
                visibility: "hidden",
              }}
            >
              <p>
                Please download and fill in your property details in the excel
                document provided below as accurately as possible.
              </p>
              <br />
              <p>
                The first sheet represents an example of what is expected of
                you.
              </p>
              <br />
              <p>
                Make sure to input the : - House number, - Water meter reading
                ie: put 0 if not applicable - Electrcity merter reading ie: put
                0 if not applicable For all units even if the unit is vacant.
              </p>

              <br />
              <br />
              <Typography variant="caption">Click to download</Typography>

              <br />

              <Button
                style={{
                  marginTop: 10,
                }}
                variant="contained"
                color="primary"
                onClick={download}
              >
                DOWNLOAD
              </Button>
            </div>
          ) : (
            <>
              <p>
                Please download and fill in your property details in the excel
                document provided below as accurately as possible.
              </p>
              <br />
              <p>
                The first sheet represents an example of what is expected of
                you.
              </p>
              <br />
              <p>
                Make sure to input the : - House number, - Water meter reading
                ie: put 0 if not applicable - Electrcity merter reading ie: put
                0 if not applicable For all units even if the unit is vacant.
              </p>

              <br />
              <br />
              <Typography variant="caption">Click to download</Typography>

              <br />

              <LoadingButton
                style={{
                  marginTop: 10,
                }}
                onClick={download}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                variant="contained"
              >
                DOWNLOAD
              </LoadingButton>
            </>
          )}
        </>
      );
    } else if (activeStep + 1 === 2) {
      setContent(
        <>
          <p>
            Please fill in your property details in the excel document you
            downloaded as accurately as possible.
          </p>
          <br />
          <p>
            Make sure to input the : - House number, - Water meter reading ie:
            put 0 if not applicable - Electrcity merter reading ie: put 0 if not
            applicable For all units even if the unit is vacant.
          </p>
          <br />
          <p>
            Kindly note that if the document is note filled following the states
            guidelines it might not go through.
          </p>
        </>
      );
    } else if (activeStep + 1 === 3) {
      setContent(
        <>
          {downloadedTemplate === false ? (
            <></>
          ) : (
            <div>
              <Typography></Typography>
              <Typography>
                Select the excel file you downloaded and edited
              </Typography>
              <br />
              <input
                type={"file"}
                onChange={(e) => {
                  newPropertyDetails = e.target.files[0];
                }}
              />{" "}
              <br />
            </div>
          )}
        </>
      );
    }
  };
  const handleReset = () => {
    setActiveStep(0);
    setContent(
      <>
        <div>
          <TextField
            required
            id="newPropertyName"
            label={"property Name"}
            name={"newPropertyName"}
            type={"text"}
            variant="outlined"
            style={{ width: "100%" }}
          />
        </div>
        <br />
        <>
          <Typography variant="caption">Generate Download Link</Typography>

          <br />

          <LoadingButton
            style={{
              marginTop: 10,
            }}
            variant="contained"
            color="primary"
            onClick={getDownloadLink}
            endIcon={<SendIcon />}
            loading={linkLoading}
            loadingPosition="end"
          >
            Get Link
          </LoadingButton>
        </>
      </>
    );
  };

  const columns = [
    {
      field: "property_name",
      headerName: "Property Name",
      // width: 150,
      minWidth: 150,
      flex: 1,
      // headerAlign: "center",
      renderCell: (params) => {
        return <div className="userListUser">{params.row.property_name}</div>;
      },
    },
    {
      field: "location",
      // headerAlign: "center",
      headerName: "Location",
      // width: 200,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "total_property_units",
      headerName: "Total Units",
      // width: 130,
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "occupancy",
      headerName: "Occupancy",
      // width: 180,
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel
              value={parseInt(params.row.occupancy.replace("%", "").trim())}
            />
          </Box>
        );
      },
    },
    {
      field: "expected_revenue",
      headerName: "Expected Rent",
      // width: 200,
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return (
          <div className="userListUser">
            {params.row.expected_revenue.toLocaleString("en-US", {
              style: "currency",
              currency: "KSH",
            })}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      // width: 200,
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div
            className="userListUser"
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => {
                changePage(params.row.id);
              }}
              variant="outlined"
            >
              Details
            </Button>
            <DeleteOutline
              className="userListDelete"
              onClick={() => {
                setPropertyDetails({
                  id: params.row.id,
                  name: params.row.property_name,
                });
                setOpen(true);
              }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className="userList">
      {/*Stepper Implementation*/}
      {backDrop}
      {/*Add Property MODAL*/}
      <Dialog
        maxWidth={"md"}
        open={addPropertyModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            width: "90%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Add Property
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "10px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Property Added Successfully
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleClose}>Close</Button>
                  {/*<Button onClick={handleReset}>Reset</Button>*/}
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <br />
                {content}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )}

                  <Button disabled={next ? false : true} onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                  <Button onClick={handleClose}>CLose</Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {/*ADelete Property MODAL*/}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ color: "red" }}>
          Delete {propertyDetails.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography>
              Deleting a property will completely remove it and its tenants from
              the system
            </Typography>
            <Typography>
              Are you sure you want to delete this property ?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <span style={{ color: "red" }}>Disagree</span>
          </Button>
          <Button onClick={handleDelete}>
            <span style={{ color: "green" }} onClick={() => {}}>
              Agree
            </span>
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            padding: "10px 10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search properties  ... "
            variant="outlined"
            name="firstname"
            onChange={(e) => {
              searchProperty(e);
            }}
            style={{ paddingRight: "10px" }}
          />
        </div>
        {matches ? (
          <></>
        ) : (
          <Button
            onClick={(e) => {
              setAddPropertyModal(true);
            }}
            variant="outlined"
            style={{
              color: "white",
              text_transform: "uppercase",
              width: "200px",
              line_height: "50px",
              border_radius: "5px",
              background: "#00B000",
              text_align: "center",
              box_shadow: "inset 0 0 25px rgba(0,0,0,.25)",
              marginRight: "10px",
            }}
          >
            Add Property
          </Button>
        )}
      </div>

      <div className={"userListDataGrid"}>
        <DataGrid
          className={classes.root}
          rows={searchResults}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          // checkboxSelection
        />
      </div>
    </div>
  );
}
