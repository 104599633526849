import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./authentication.css";
import {ApiDetails, KEY, SITE_KEY, SKY_AUTH_ApiDetails} from "../../Constants";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import LoadingButton from "@mui/lab/LoadingButton";
import logo from "../../assets/react-logo-no-background.svg";
import wave from "../../assets/login_background_3.mp4";
import {TextField} from "@material-ui/core";
import {LockOutlined, Login, VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import {Autocomplete} from "@mui/material";
import CryptoJS from 'crypto-js'; // Import CryptoJS

export default function LoginPageV2(props) {
    const [userTypes, setUserTypes] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState(null);
    const [captcha, setCaptcha] = useState(<div></div>);
    const [loading, setLoading] = useState(false);
    const [loadingAuthentication, setLoadingAuthentication] = useState(false);
    const [proceed, setProceed] = useState(false);
    const [loginPage, setLoginPage] = useState(null);
    const [type, setType] = useState("password");
    const [icon, setIcon] = useState(<VisibilityOutlined/>);

    const navigate = useNavigate();
    let userData = null; // Initialize userData

    useEffect(() => {
        fetch(ApiDetails + "pegasus/visionary/extras/user_types", {
            method: "GET",
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setUserTypes(data);
                } else {
                    props.snackBar({
                        text: "Something went wrong.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });

        setCaptcha(<div className="g-recaptcha" data-sitekey={SITE_KEY}></div>);
    }, []);

    const confirmAuthCode = async (event, email, values) => {
        event.preventDefault();
        setLoadingAuthentication(true);
        let code = document.getElementById("auth_code").value;
        if (code === "") {
            setLoadingAuthentication(false);
            props.snackBar({
                text: "Code Can Not Be Empty.",
                type: "error",
            });
            return;
        }
        let body = {
            auth_code: code,
            identifier_type: "MAIL",
            identifier: email,
            program_id: parseInt("100009"),
        };
        fetch(SKY_AUTH_ApiDetails + "/sky-auth/confirmation", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    getUserDetails(userData);
                    setLoadingAuthentication(false);
                } else {
                    props.snackBar({
                        text: "Invalid Code Provided.",
                        type: "error",
                    });
                    setLoadingAuthentication(false);
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
                setLoadingAuthentication(false);
            });
    };

    const getUserDetails = (userDetails) => {
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/dashboard/get/details", {
            method: "POST",
            body: JSON.stringify(userDetails),
        })
            .then(async (response) => {
                // console.log("userDetails get user details");
                // console.log(userDetails);
                let data = await response.json();
                if (response.status === 200) {
                    setLoading(false);
                    // props.topBar();
                    const map1 = new Map(Object.entries(data));
                    const details = new Map(Object.entries(userDetails));
                    const subscription = new Map();

                    subscription.set("subscription_expired", details.get("subscription_expired"))
                    subscription.set("subscription_amount", details.get("subscription_amount"))

                    let myMap = CryptoJS.AES.encrypt(
                        JSON.stringify(Array.from(details.entries())),
                        KEY
                    ).toString();
                    let portfolioDetails = CryptoJS.AES.encrypt(
                        JSON.stringify(Array.from(map1.entries())),
                        KEY
                    ).toString();

                    let subscriptionDetails = CryptoJS.AES.encrypt(
                        JSON.stringify(Array.from(subscription.entries())),
                        KEY
                    ).toString();

                    localStorage.setItem("myMap", myMap);
                    localStorage.setItem("isNew", userDetails.new);
                    localStorage.portfolioDetails = portfolioDetails;
                    localStorage.subscriptionDetails = subscriptionDetails;

                    // return;
                    navigate("/home", {
                        state: {
                            userDetails: userDetails,
                        },
                    });
                    props.refresh(true);
                    props.snackBar({text: "Login Successful", type: "success"});
                    props.fn();
                } else {
                    setLoading(false);
                    props.snackBar({text: "Failed Fetching Details", type: "error"});
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };


    const onSubmit = (values) => {
        setLoading(true);
        if (selectedUserType === undefined || selectedUserType === null) {
            props.snackBar({
                text: "Kindly select user type",
                type: "error",
            });
            setLoading(false);
            return;
        }
        values = {...values, user_type: selectedUserType.id};
        fetch(ApiDetails + "pegasus/visionary/authorization/login", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                let data = await response.json();
                userData = data;
                if (response.status === 200) {
                    let two_fa = new Map(Object.entries(data)).get("two_factor_enabled");
                    if (two_fa === "YES") {
                        try {
                            await fetchEventSource(
                                SKY_AUTH_ApiDetails + "/sky-auth/confirmation/sse/100009",
                                {
                                    method: "GET",
                                    headers: {
                                        Accept: "text/event-stream",
                                    },
                                    onopen(res) {

                                        if (res.ok && res.status === 200) {
                                            const body = {
                                                identifier_type: "MAIL",
                                                identifier: values.email_address,
                                                program_id: parseInt("100009"),
                                            };
                                            fetch(
                                                SKY_AUTH_ApiDetails + "/sky-auth/authorization/biometric/add",
                                                {
                                                    method: "POST",
                                                    body: JSON.stringify(body),
                                                }
                                            )
                                                .then(async (response) => {
                                                    let data = await response.json();
                                                    if (response.status === 200) {
                                                        let base_64_image = new Map(Object.entries(data)).get("Base64 QR image");
                                                        setLoginPage(
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                textAlign: "center",
                                                                backgroundColor: "white",
                                                                margin: "0 auto"
                                                            }}>
                                                                <div style={{
                                                                    width: "100%",
                                                                    height: "70vh",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    flexDirection: "column"
                                                                }} className="col-1-auth">
                                                                    <form id="form" className="flex flex-col">
                                                                        <label htmlFor="login_email" style={{
                                                                            display: "flex",
                                                                            justifyContent: "start"
                                                                        }}>
                                                                            <h3> Sky Auth Code</h3>
                                                                        </label>
                                                                        <input type="number" id="auth_code"/>
                                                                        <LoadingButton
                                                                            endIcon={<LockOutlined/>}
                                                                            loading={loadingAuthentication}
                                                                            loadingPosition="end"
                                                                            variant="contained"
                                                                            onClick={(event) => {
                                                                                confirmAuthCode(event, values.email_address, data);
                                                                            }}
                                                                            className="btn"
                                                                        >
                                                                            Verify code
                                                                        </LoadingButton>
                                                                        <br/>
                                                                        <span style={{color: "red"}}>
                                                                    Kindly enter auth code or scan the QR code before the timer expires
                                                                </span>
                                                                    </form>
                                                                </div>
                                                                <div style={{
                                                                    width: "100%",
                                                                    height: "70vh",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    flexDirection: "column"
                                                                }} className="col-2-auth">
                                                                    <img src={"data:image/png;base64," + base_64_image}
                                                                         alt="QR Code"/>
                                                                </div>
                                                            </div>
                                                        );
                                                        setProceed(true);
                                                    } else {
                                                        props.snackBar({
                                                            text: "Invalid Code Provided.",
                                                            type: "error",
                                                        });
                                                        setLoading(false);
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    props.snackBar({
                                                        text: "Something Went Wrong. Try Again Later",
                                                        type: "error",
                                                    });
                                                    setLoading(false);
                                                });
                                        }

                                    },
                                    onmessage(event) {
                                        getUserDetails(data);
                                    }
                                }
                            );
                        } catch (err) {
                            console.error(err);
                            props.snackBar({
                                text: "Something Went Wrong. Try Again Later",
                                type: "error",
                            });
                            setLoading(false);
                        }
                    } else {
                        getUserDetails(data);
                    }
                } else {
                    props.snackBar({
                        text: "Invalid Credentials.",
                        type: "error",
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
                setLoading(false);
            });
    };

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1,
                overflow: 'hidden',
            }}
        >
            <video
                autoPlay
                loop
                muted
                preload={"auto"}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: "absolute",
                }}
            >
                <source src={wave} type="video/mp4"/>
            </video>
            {/* Your other content goes here */}
            <div style={{position: 'relative', zIndex: 1}}>
                <div className={"fullPage"}>
                    <div
                        style={{
                            height: "70vh",
                            width: "70vw",
                            borderRadius: "20px",
                            overflow: "hidden"
                        }}
                    >
                        {proceed ? (
                            <>{loginPage}</>
                        ) : (
                            <div className="register">
                                <div className="col-1-auth">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            position: "relative"
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "250px",
                                                height: "150px",
                                            }}
                                            src={logo}
                                            alt="Logo"
                                        />
                                        <div>
                                            <h2>Log In</h2>
                                            <span
                                                style={{
                                                    color: "#adb5bd",
                                                }}
                                            >
                                                Welcome back!
                                            </span>
                                        </div>
                                    </div>

                                    <Formik
                                        initialValues={{email_address: '', password: ''}}
                                        validationSchema={Yup.object({
                                            email_address: Yup.string().email("Invalid email address").required("Required"),
                                            password: Yup.string().required("Required"),
                                        })}
                                        onSubmit={onSubmit}
                                    >
                                        {(props) => (
                                            <Form noValidate>
                                                <Field
                                                    as={TextField}
                                                    type={"email"}
                                                    name="email_address"
                                                    label="Email address"
                                                    variant="outlined"
                                                    InputLabelProps={{shrink: true}}
                                                    error={
                                                        props.errors.email_address &&
                                                        props.touched.email_address
                                                    }
                                                    helperText={<ErrorMessage name="email_address"/>}
                                                    required
                                                    style={{width: "100%", marginTop: "10px"}}
                                                />
                                                <Field
                                                    as={TextField}
                                                    type={type}
                                                    name="password"
                                                    label="Password"
                                                    variant="outlined"
                                                    required
                                                    InputLabelProps={{shrink: true}}
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                onClick={() => {
                                                                    if (type === "password") {
                                                                        setType("text");
                                                                        setIcon(<VisibilityOffOutlined/>);
                                                                    } else {
                                                                        setType("password");
                                                                        setIcon(<VisibilityOutlined/>);
                                                                    }
                                                                }}
                                                                position="end"
                                                            >
                                                                {icon}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={userTypes.map((type) => type.description)}
                                                    style={{width: "100%", marginTop: "10px"}}
                                                    onChange={(e, value) => {
                                                        setSelectedUserType(
                                                            userTypes.find((type) => type.description === value)
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Account type"
                                                            variant="outlined"
                                                            name="property"
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                                {/*{captcha}*/}
                                                <LoadingButton
                                                    type={"submit"}
                                                    endIcon={<Login/>}
                                                    loading={loading}
                                                    loadingPosition="end"
                                                    variant="contained"
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "10px",
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    Log In
                                                </LoadingButton>
                                                <br/>
                                                <span>
                                                    Don't have an account? <a href="/signup">Signup</a>
                                                </span>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>

                                <div className="col-2-auth">
                                    <img src={require("../../assets/new_landlord_login_pic.jpg")} alt="Landlord"/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
