import React, {forwardRef, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import CryptoJS from "crypto-js";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Snackbar,
    Typography,
    useMediaQuery,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {TextField} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import * as yup from "yup";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import WidgetSm from "../../components/widgetSM/WidgetSm";
import WidgetLg from "../../components/widgetLG/WidgetLg";
import {ApiDetails, KEY, loginState} from "../../Constants";
import {LogoutOutlined} from "@mui/icons-material";
import "./home.css";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const decryptLocalStorageItem = (key, defaultValue = "") => {
    try {
        const decrypted = CryptoJS.AES.decrypt(localStorage.getItem(key), KEY);
        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (e) {
        console.log(`Error decrypting ${key}:`, e);
        return defaultValue;
    }
};

const Home = (props) => {
    const navigate = useNavigate();
    const {state} = useLocation();
    const matches = useMediaQuery("(max-width:700px)");

    const [subscription, setSubscription] = useState(decryptLocalStorageItem("subscriptionDetails"));
    const [originalText, setOriginalText] = useState(decryptLocalStorageItem("myMap"));
    const [originalText2, setOriginalText2] = useState(decryptLocalStorageItem("portfolioDetails"));
    const [backDrop, setBackDrop] = useState(true);
    const [set, updateSet] = useState(false);
    const [open, setOpen] = useState(loginState);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState();
    const [tenantLoading, setTenantLoading] = useState(false);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [status, setStatus] = useState(localStorage.getItem("isNew"));
    const [dateSubscriptionExpired, setDateSubscriptionExpired] = useState(null);
    const [amount, setAmount] = useState(0);
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [subscribeButtonDisabled, setSubscribeButtonDisabled] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [content, setContent] = useState();

    let initialPaymentValues = {
        phone_number: JSON.parse(originalText)[3][1],
    };
    let basicSchema = yup.object().shape({
        phone_number: yup.string().phone("KE", true).required("Required"),
        amount: yup.number().min(1, "Amount must be more than 1 KSH"),
    });

    const [, setDateSubscribed] = useState(null);
    let [, setEmailAddress] = useState(null);

    useEffect(() => {
        props.refreshSideBar();
    }, [props]);

    useEffect(() => {
        const userDetails = new Map(JSON.parse(originalText).map((object) => [object[0], object[1]]));
        getUserDetails(userDetails);

        let dateCreated;
        let date_subscribed;
        userDetails.forEach((value, key) => {
            if (key === "date_subscription_renewed") {
                date_subscribed = new Date(value);
            }
            if (key === "date_created") {
                dateCreated = new Date(value);
            }
        });

        let email_address;
        userDetails.forEach((value, key) => {
            if (key === "email_address") {
                email_address = value;
            }
        });

        setEmailAddress(email_address);
        setDateSubscribed(date_subscribed);

        let subscriptionExpired = JSON.parse(subscription)[0][1];

        if (!(date_subscribed === undefined || date_subscribed === null)) {
            date_subscribed.setMonth(date_subscribed.getMonth() + 1);
            setDateSubscriptionExpired(date_subscribed.getDate() + " " + date_subscribed.toLocaleString("en-US", {
                month: "long",
            }) + ", " + date_subscribed.getFullYear());
        } else {
            setDateSubscriptionExpired("First subscription");
        }

        if (subscriptionExpired && !(status === "yes")) {
            setSubscriptionAmount(
                Math.ceil(JSON.parse(subscription)[1][1])
            );
            setSubscribeButtonDisabled(false);
            setSubscriptionModal(true);
        }

    }, []);

    const getUserDetails = (userDetails) => {
        try {
            set(true);
            setBackDrop(false);
        } catch (e) {
            fetch(ApiDetails + "pegasus/visionary/dashboard/get/details", {
                method: "POST",
                body: JSON.stringify(Object.fromEntries(userDetails)),
            })
                .then(async (response) => {
                    const data = await response.json();
                    if (response.status === 200) {
                        const map1 = new Map(Object.entries(data));
                        const myMap = CryptoJS.AES.encrypt(JSON.stringify(Array.from(userDetails.entries())), KEY).toString();
                        const portfolioDetails = CryptoJS.AES.encrypt(JSON.stringify(Array.from(map1.entries())), KEY).toString();
                        localStorage.setItem("myMap", myMap);
                        localStorage.setItem("isNew", userDetails.get("new"));
                        localStorage.portfolioDetails = portfolioDetails;
                        setOriginalText(decryptLocalStorageItem("myMap"));
                        setOriginalText2(decryptLocalStorageItem("portfolioDetails"));
                        setStatus(localStorage.getItem("isNew"));
                        updateSet(true);
                        setBackDrop(false);
                    } else {
                        setBackDrop(false);
                        props.snackBar({text: "Failed Fetching Details", type: "error"});
                        navigate("/");
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const handleClick = (message) => {
        setMessage(message.text);
        setSeverity(message.type);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") return;
        setOpen(false);
        setPaymentModal(false);
    };

    const handleNext = () => {
        const sub = new Map(Object.entries(subscriptions[activeStep + 1]));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setContent(renderSubscriptionContent(sub));
    };

    const handleBack = () => {
        const sub = new Map(Object.entries(subscriptions[activeStep - 1]));
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setContent(renderSubscriptionContent(sub));
    };

    const renderSubscriptionContent = (sub) => (
        <div>
            <Typography>{sub.get("description")}</Typography>
            <br/>
            <small>
                Price: {sub.get("amount").toLocaleString("en-US", {style: "currency", currency: "KSH"})}
            </small>
            <br/>
            <div style={{width: "100%", display: "flex", justifyContent: "right"}}>
                <Button
                    style={{color: "green"}}
                    onClick={() => {
                        setAmount(parseInt(sub.get("amount")));
                        setPaymentModal(true);
                    }}
                    variant="outlined"
                >
                    Subscribe
                </Button>
            </div>
        </div>
    );

    const processPayment = async (values) => {
        setTenantLoading(true);

        let body = {
            BusinessShortCode: "6122464",
            Password: "",
            Timestamp: "",
            TransactionType: "CustomerBuyGoodsOnline",
            Amount: amount,
            PartyA: formatPhoneNumber(values.phone_number), // PartyB: "6122464",
            PartyB: "5726811",
            PhoneNumber: formatPhoneNumber(values.phone_number),
            CallBackURL: "https://api.pegasus-pms.co.ke/api/" + "pegasus/visionary/payments/subscription",
            AccountReference: "ITORAMA SOLUTIONS",
            TransactionDesc: "Subscription payment",
            landlord_id: JSON.parse(originalText)[0][1],
        };

        // console.log(body);
        fetch(ApiDetails + "pegasus/visionary/mpesa/landlord/StkPush", {
            method: "POST", body: JSON.stringify(body),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setTenantLoading(false);
                    props.snackBar({
                        text: "Kindly wait For mpesa prompt on your phone", type: "success",
                    });
                } else if (response.status === 403) {
                    // console.log(data);
                    setTenantLoading(false);
                    props.snackBar({
                        text: new Map(Object.entries(data)).get("Message"), type: "error",
                    });
                }
                handleClose();
            })
            .catch((err) => {
                console.log(err);
                handleClose();
                setTenantLoading(false);
                props.snackBar({
                    text: "Payment Not Successful", type: "error",
                });
            });

        // fetchTenantDetails();
    };

    const formatPhoneNumber = (phoneNumber) => phoneNumber.startsWith("0") ? "254" + phoneNumber.substring(1) : phoneNumber;

    const logout = (event) => {
        // 👇️ toggle class on click
        window.location.href = "/";
        localStorage.clear();
        props.fn();
    };

    return (
        <div className="home">
            <Backdrop open={backDrop} style={{zIndex: 1200}}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Dialog
                open={subscriptionModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogTitle
                    style={{
                        color: "#398DD2",
                    }}
                >
                    <h3
                        style={{
                            fontWeight: "bold", color: "#398DD2",
                        }}
                    >
                        Renew Subscription
                    </h3>
                </DialogTitle>
                <DialogContent>

                    <div>

                        <Box sx={{width: "100%", paddingBottom: "10px"}}>
                            <React.Fragment>
                                <br/>
                                <div
                                    style={{
                                        padding: "10px",
                                        color: "#959EAD",
                                        borderRadius: "5px",
                                        border: "2px solid blue",
                                    }}
                                >


                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}
                                    >
                                        <div
                                            style={{}}
                                        >
                                            Price:{" "}
                                            {subscriptionAmount.toLocaleString("en-US", {
                                                style: "currency", currency: "KSH",
                                            })}
                                        </div>
                                        <div
                                            style={{
                                                display: "flex", justifyContent: "right",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    color: "green",
                                                }}

                                                disabled={subscribeButtonDisabled}
                                                onClick={() => {
                                                    setAmount((parseInt(subscriptionAmount)));
                                                    setPaymentModal(true);
                                                }}
                                                variant="outlined"
                                            >
                                                Subscribe
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </React.Fragment>
                        </Box>

                    </div>

                    <em style={{color: "red", paddingTop: "20px"}}>Note</em>
                    <br/>
                    <div>Your subscription expired on : {dateSubscriptionExpired}</div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: matches ? "column" : "",
                            justifyContent: "space-between",
                            alignContent: "center",
                            alignItems: "center",
                            paddingTop: "10px",
                        }}
                    >
                        <small>
                            After subscribing you will receiving a confirmation message from
                            IT-orama, kindly login again so as to update your payment details.
                        </small>
                        <Button
                            disabled={subscribeButtonDisabled}
                            onClick={() => {
                                logout();
                            }}
                            variant="outlined"
                            style={{
                                // color: "black",
                                text_transform: "uppercase", // width: "200px",
                                // line_height: "50px",
                                paddingLeft: "10px", border_radius: "5px", text_align: "center",
                            }}
                        >
                            <div
                                style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                            >
                                {matches ? (<div>Logout</div>) : (<div
                                    style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <LogoutOutlined
                                        style={{
                                            paddingRight: "5px",
                                        }}
                                    />

                                    <div>Logout</div>
                                </div>)}
                            </div>
                        </Button>
                    </div>
                </DialogContent>

            </Dialog>

            <Dialog
                open={paymentModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setPaymentModal(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Payment"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Formik
                            initialValues={initialPaymentValues}
                            validationSchema={basicSchema}
                            onSubmit={(values) => processPayment(values)}
                        >
                            {({errors, touched, values}) => (
                                <Form>
                                    <Field
                                        as={TextField}
                                        name="phone_number"
                                        label="Phone Number"
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        helperText={<ErrorMessage name="phone_number"/>}
                                        error={touched.phone_number && !!errors.phone_number}
                                    />
                                    <Field
                                        as={TextField}
                                        name="amount"
                                        label="Amount"
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        type="number"
                                        helperText={<ErrorMessage name="amount"/>}
                                        error={touched.amount && !!errors.amount}
                                        value={amount}
                                        disabled={true}
                                    />
                                    <LoadingButton
                                        loading={tenantLoading}
                                        loadingPosition="start"
                                        startIcon={<SendIcon/>}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Pay
                                    </LoadingButton>
                                </Form>
                            )}
                        </Formik>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            {/* Blurred Overlay */}
            {subscriptionModal && (
                <div className="blurredOverlay"/>
            )}

            <div className="homeContainer">
                <FeaturedInfo/>
                <div className="homeWidgets">
                    <WidgetSm/>
                    <WidgetLg/>
                </div>
            </div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Home;
