import React, {useEffect, useState} from "react";
import "./events.css";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import {dateFnsLocalizer} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import {InputLabel, TextField} from "@material-ui/core";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Field, Form, Formik} from "formik";
import Button from "@mui/material/Button";
import {makeStyles} from "@material-ui/core/styles";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import {ApiDetails, KEY} from "../../Constants";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CryptoJS from "crypto-js";
import Switch from "@mui/material/Switch";
import {FormControl, MenuItem, Select} from "@mui/material";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import {Calendar as ModernCalendar, utils} from 'react-modern-calendar-datepicker';

import {Calendar} from 'react-native-big-calendar'


const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const locales = {
    "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const localeMap = {
    en: enLocale,
    de: deLocale,
};

export default function Events(props) {
    let myMapDetailsDecrypted = "";
    let portfolioDetailsDecrypted = "";

    try {
        myMapDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.myMap, KEY);

        portfolioDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.portfolioDetails, KEY);
    } catch (e) {
        console.log(e);
    }

    const [originalText, setOriginalText] = useState(myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8));
    const [originalText2, setOriginalText2] = useState(portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8));

    const [checked, setChecked] = useState(false)

    //Loading Screen
    let [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            onClick={null}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
        // <></>
    );

    const handleClose = () => {
        setModal(false);
    };

    useEffect(() => {
        props.refreshSideBar();
        fetch(ApiDetails + "pegasus/visionary/events/", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    //Fetch tenant arrears
                    setAllEvents(data.Events.map(event => ({
                        ...event,
                        start: new Date(event.start), // Convert to Date
                        end: new Date(event.end), // Convert to Date
                    })));
                    setTenants(data.Tenants);
                    setBackDrop(<></>);
                } else {
                    props.snackBar({
                        text: "Failed fetching tenant details.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    }, []);

    function convertToISODate(inputDate) {
        // Parse the input date string
        const [year, month, day] = inputDate.split('/').map(Number);

        // Create a new Date object
        const date = new Date(year, month - 1, day); // month - 1 because months are zero-indexed in JavaScript

        // Convert to ISO string
        const isoString = date.toISOString();

        return isoString;
    }

    const getEvents = () => {
        fetch(ApiDetails + "pegasus/visionary/events/", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setAllEvents(data.Events.map(event => ({
                        ...event,
                        start: new Date(event.start), // Convert to Date
                        end: new Date(event.end), // Convert to Date
                    })));
                    setTenants(data.Tenants);
                } else {
                    props.snackBar({
                        text: "Failed fetching events.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };

    const [newEvent, setNewEvent] = useState({title: "", start: "", end: ""});
    const [allEvents, setAllEvents] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState({tenant_id: "", names: ""});

    function handleAddEvent() {


        if (
            newEvent.title === "" ||
            newEvent.start === "" ||
            newEvent.end === ""

        ) {

            props.snackBar({
                text: "Kindly fill in all fields.",
                type: "warning",
            });
            return;
        } else if (
            checked &&
            selectedTenant.tenant_id === ""
        ) {

            props.snackBar({
                text: "Kindly select tenant or turn off the switch",
                type: "warning",
            });
            return;
        }

        for (let i = 0; i < allEvents.length; i++) {
            const d1 = new Date(allEvents[i].start);
            const d2 = new Date(newEvent.start);
            const d3 = new Date(allEvents[i].end);
            const d4 = new Date(newEvent.end);

            if ((d1 <= d2 && d2 <= d3) || (d1 <= d4 && d4 <= d3)) {
                alert("CLASH");
                break;
            }
        }

        let body = {
            landlord_id: JSON.parse(originalText)[0][1],
            title: newEvent.title,
            start_date: convertToISODate(newEvent.start.year + "/" + newEvent.start.month + "/" + newEvent.start.day),
            end_date: convertToISODate(newEvent.end.year + "/" + newEvent.end.month + "/" + newEvent.end.day),
            include_tenant: checked,
            tenant_id: selectedTenant.tenant_id
        };

        fetch(ApiDetails + "pegasus/visionary/events/add", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                setChecked(false);
                setSelectedTenant({tenant_id: "", names: ""})
                if (response.status === 200) {
                    getEvents();
                } else {
                    props.snackBar({
                        text: "Failed adding event.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
        handleClose();
        getEvents();
    }

    const [modal, setModal] = React.useState(false);

    let initialValues = {
        id: "",
        first_name: "",
        description: "",
        amount: "",
    };

    return (
        <>
            {backDrop}

            {/*Arrears MODAL*/}
            <Dialog
                open={modal}
                maxWidth="lg" // 'sm', 'md', 'lg', 'xl', false
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Add Event
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Formik initialValues={initialValues} onSubmit={handleAddEvent}>
                        {() => (
                            <Form noValidate>
                                <div>
                                    <Field
                                        as={TextField}
                                        name="event_title"
                                        label="Event Title"
                                        variant="outlined"
                                        fullwidth
                                        required
                                        value={newEvent.title}
                                        onChange={(e) =>
                                            setNewEvent({...newEvent, title: e.target.value})
                                        }
                                        style={{marginTop: "8px", width: "100%"}}
                                    />

                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            border: '1px solid #e0e0e0',
                                            marginTop: "10px",
                                            paddingTop: "8px",
                                            // paddingLeft: '8px',
                                            borderRadius: '8px',
                                            backgroundColor: '#f9f9f9',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: 'center',
                                                marginBottom: '8px',
                                                paddingLeft: '8px',

                                            }}
                                        >
                                            <div style={{paddingTop: "8px", fontWeight: 'bold', color: '#333'}}>
                                                Add Tenant
                                            </div>
                                            <div

                                                style={{
                                                    paddingRight: '8px',
                                                }}>
                                                <Switch
                                                    checked={checked}
                                                    onChange={() => setChecked(!checked)}
                                                    inputProps={{'aria-label': 'controlled'}}
                                                    color="primary"
                                                />
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                overflow: 'hidden',
                                                transition: 'max-height 0.3s ease-in-out',
                                                maxHeight: checked ? '200px' : '0',
                                                // padding: checked ? '8px 0' : '0',
                                                opacity: checked ? 1 : 0,
                                                paddingLeft: '8px',
                                                paddingRight: '8px',
                                                paddingBottom: '8px',
                                            }}
                                        >
                                            <FormControl fullWidth disabled={!checked} style={{
                                                transition: 'opacity 0.3s ease-in-out',
                                                opacity: checked ? 1 : 0
                                            }}>
                                                <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectedTenant.names}
                                                    label="Tenant"
                                                    onChange={(e) => {
                                                        const selected = tenants.find(tenant => tenant.tenant_id === e.target.value);
                                                        setSelectedTenant({
                                                            tenant_id: selected.tenant_id,
                                                            names: `${selected.first_name} ${selected.last_name}`,
                                                        });
                                                    }}
                                                >
                                                    {tenants.map((tenant) => (
                                                        <MenuItem key={tenant.tenant_id} value={tenant.tenant_id}>
                                                            {`${tenant.first_name} ${tenant.last_name}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            paddingTop: "10px",
                                        }}
                                    >

                                        <div
                                            style={{
                                                paddingRight: "10px",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: '10px 20px',
                                                    margin: '10px 0',
                                                    borderRadius: '8px',
                                                    backgroundColor: '#f5f5f5',
                                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                                    fontFamily: '"Roboto", sans-serif',
                                                    fontSize: '16px',
                                                    color: '#333',
                                                }}
                                            >
                                                Start date
                                            </div>
                                            <div>

                                                <ModernCalendar
                                                    value={newEvent.start}
                                                    onChange={(start) => {
                                                        console.log(start);
                                                        setNewEvent({...newEvent, start})
                                                    }
                                                    }
                                                    minimumDate={utils().getToday()}
                                                    shouldHighlightWeekends
                                                    showTimeSelect
                                                    className="custom-calendar"
                                                />
                                            </div>

                                        </div>


                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: '10px 20px',
                                                    margin: '10px 0',
                                                    borderRadius: '8px',
                                                    backgroundColor: '#f5f5f5',
                                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                                    fontFamily: '"Roboto", sans-serif',
                                                    fontSize: '16px',
                                                    color: '#333',
                                                }}
                                            >
                                                End date
                                            </div>

                                            <ModernCalendar
                                                value={newEvent.end}
                                                onChange={(end) => {
                                                    console.log(end);
                                                    setNewEvent({...newEvent, end})
                                                }
                                                }
                                                minimumDate={utils().getToday()}
                                                shouldHighlightWeekends
                                            />
                                        </div>

                                    </div>

                                </div>
                            </Form>
                        )}
                    </Formik>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <Button onClick={handleAddEvent}>
                        <span style={{color: "green"}} onClick={() => {
                        }}>
              Agree
            </span>
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="App">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "20px",
                    }}
                >
                    <h1>Calendar</h1>
                    <Button
                        onClick={() => {
                            setModal(true);
                        }}
                        variant="outlined"
                        style={{
                            color: "white",
                            text_transform: "uppercase",
                            width: "200px",
                            line_height: "50px",
                            border_radius: "5px",
                            background: "#00B000",
                            text_align: "center",
                            box_shadow: "inset 0 0 25px rgba(0,0,0,.25)",
                        }}
                    >
                        Add New Event
                    </Button>
                </div>


                <Calendar
                    events={allEvents}
                    height={500}
                    swipeEnabled={true}
                    mode='month'
                />
            </div>
        </>
    );
}
