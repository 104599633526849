import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import "yup-phone";
import "./user.css";
import "../../../node_modules/draft-js/dist/Draft.css";
import {
  BarChart,
  DeleteOutline,
  AccountBalanceRounded,
  PaidOutlined,
  PaymentsOutlined,
  RememberMeOutlined,
  WarningAmber,
  MessageOutlined,
  ReceiptLongOutlined,
  PlaylistAddCheckOutlined,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { InputLabel, TextField, Typography } from "@material-ui/core";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Tooltip from "@mui/material/Tooltip";
import {
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
  Select,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {
  AccountCircleOutlined,
  AddOutlined,
  BugReport,
  BusinessOutlined,
  EditOutlined,
  FilterListOutlined,
  FormatListBulletedOutlined,
  GavelRounded,
  HomeOutlined,
  LocalPhoneOutlined,
  PersonOutlineOutlined,
  Receipt,
} from "@material-ui/icons";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ApiDetails, KEY } from "../../Constants";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import draftToHtml from "draftjs-to-html";
import NonEditableRichTextEditor from "../../components/RichTextEditorNotEditable/NonEditableRichTextEditor";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import DialogContentText from "@mui/material/DialogContentText";
import RichTextEditor from "../../components/RichTextEditorEditable/RichTextEditor";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CryptoJS from "crypto-js";
import Textarea from "@mui/joy/Textarea";
import { PulseLoader } from "react-spinners";

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      fontSize: 16,
      color: "darkblue",
      backgroundColor: "rgb(245,245,245)",
    },
  },
});
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*
 let elem = document.getElementById("messages");
                      elem.scrollTop = elem.scrollHeight;
                      */
export default function UserManagement(props) {
  const matches = useMediaQuery("(max-width:700px)");

  let { state } = useLocation();

  let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
    localStorage.getItem("myMap"),
    KEY
  );

  let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

  //Loading Screen
  const [backDrop, setBackDrop] = useState()
  // (<Backdrop
  //     sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //     open={true}
  //     onClick={null}
  //   >
  //     <CircularProgress color="inherit" />
  //   </Backdrop>
  // );

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 90,
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
          `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
  ];

  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];


  return (
    <div >
      {backDrop}


      <div
        style={{
          // marginTop:"60px",
          height: "calc(100vh - 80px)",
          display: "flex",
          flexDirection: "column" ,
          justifyContent: "space-between",
          margin:"10px",
        }}
      >
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
        />
      </div>
    </div>
  );
}
