
export var loginState = false;
export const KEY = "MarkKieru@55";
export const GOOGLE_MAPS_API_KEY = "AIzaSyDlcuzPo-zZuXSsYA75gzGmRGMQWI0xwhg";

//Live API
// SERVER_IP_ADDRESS = 172.105.244.19;
// API_PORT_NUMBER = :8080
// export const ApiDetails = "/api/";
export const ApiDetails = "https://api.pegasus-pms.co.ke/api/";
export const RECAPTCHA = "6Lc_PkMkAAAAAAxgKyNYSKZVkGnwAVupNHfpsIgJ";
export const SITE_KEY = "6LegSqgkAAAAABBZAUqVzsDq-uNvkDsxtbEz-RRF";
export const SECRET_KEY = "6LegSqgkAAAAAD2vO2OR-cKLXl-Gc9j9flZzCvO-";
export const SKY_AUTH_ApiDetails = "https://api.pegasus-pms.co.ke/sky_api";

//Local API
// export const ApiDetails = "http://0.0.0.0:8080/";
// export const RECAPTCHA = "6Lc_PkMkAAAAAL1    GZw V4XJa3cttFXibzuz9Ega9R";
// export const SKY_AUTH_ApiDetails = "//0.0.0.0:8081";

//Callback Endpoint
// export const ApiDetails = "https://172.105.244.19:8080/";

// https://api.pegasus-pms.co.ke/api/pegasus/visionary/payments/