import "./userList.css";
import {makeStyles} from "@material-ui/core/styles";
import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect, useRef, useState} from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {
    Autocomplete,
    Avatar,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextareaAutosize,
    useMediaQuery,
} from "@mui/material";
import {InputLabel, TextField} from "@material-ui/core";
import {DeleteOutline, PaymentsOutlined,} from "@mui/icons-material";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import {ApiDetails, KEY} from "../../Constants";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import {DoneOutlined, MoreVert,} from "@material-ui/icons";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import CryptoJS from "crypto-js";
import Chip from '@mui/material/Chip';
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from '@mui/material/Tooltip';
import {ErrorIcon} from "react-hot-toast";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//create your forceUpdate hook WHICH DID NOT WORK

const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});

export default function UserList(props) {

    const matches = useMediaQuery("(max-width:700px)");

    const textRef = useRef();

    let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.getItem("myMap"),
        KEY
    );
    let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.portfolioDetails,
        KEY
    );
    let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);
    let originalText2 = portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8);
    //Navigator
    const navigate = useNavigate();

    //Loading Screen
    const [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            onClick={null}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );

    let [tenants, updateTenants] = React.useState([]);
    const [searchResults, setSearchResults] = useState(tenants);
    let [properties, updateProperties] = React.useState([]);
    const [dateFrom, setDateFrom] = React.useState(new Date());

    //Trying To Use UseEffect To Set Component Data
    useEffect(() => {
        props.refreshSideBar();
        var id = JSON.parse(originalText)[0][1];

        console.log("Running UserList() :: " + id)
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/get/all/tenants", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: id,
            }),
        })
            .then(async (response) => {
                let tenants = await response.json();
                console.log("Running UserList at useEffect()")

                if (response.status === 200) {
                    console.log("Tenants :: " + tenants);

                    //Get All Properties
                    fetch(ApiDetails + "pegasus/visionary/property/get/all/properties", {
                        method: "POST",
                        body: JSON.stringify({
                            landlord_id: JSON.parse(originalText)[0][1],
                        }),
                    })
                        .then(async (response) => {
                            let data = await response.json();

                            if (response.status === 200) {
                                // console.log(data);
                                console.log("Properties :: " + data);

                                updateProperties(data);
                                updateTenants(tenants);
                                setSearchResults(tenants);
                                setBackDrop(<></>);
                                // setDetailsFetched(true);
                            } else {
                                props.snackBar({
                                    text: "Failed Fetching Details",
                                    type: "error",
                                });
                            }
                        })
                        .catch((err) => console.log(err));
                } else {
                    props.snackBar({text: "Failed Fetching Details", type: "error"});
                }
            })
            .catch((err) => console.log(err));
    }, []);

    //Modal states
    const [deleteModal, setOpen] = useState(false);
    const [transactionModal, setTransactionModal] = useState(false);
    const [arrearsModal, setArrearsModal] = useState(false);
    const [addTenantModal, setAddTenantModal] = useState(false);
    const [depositModal, setDepositModal] = useState(false);

    const [refundableDeposit, setRefundableDeposit] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);

    const [depositSet, setDepositSet] = useState(false);

    const [inputs, setInputs] = useState(
        <div>
            <br/>
            <div
                style={{
                    display: "none",
                    justifyContent: "space-between",
                }}
            >
                <TextField
                    label="Description"
                    type={"text"}
                    variant="outlined"
                    required
                    style={{marginTop: "10px", width: "49%"}}
                />
                <TextField
                    label="Amount"
                    type={"number"}
                    variant="outlined"
                    style={{marginTop: "10px", width: "49%"}}
                />

            </div>
        </div>
    );

    const [expensesIncurred, setExpensesIncurred] = useState(1);
    let [depositList, setDepositList] = useState([new Map()]);
    const [expenseItem] = useState(new Map());


    const calculateRefundableDeposit = () => {

        try {
            expenseItem.forEach((value, key) => {
                let array = key.split(" ");
                let uniqueString = array[array.length - 1];

                if (expenseItem.has("refundable " + uniqueString)) {
                    let refundableValue = expenseItem.get("refundable " + uniqueString);

                    if (!(refundableValue.toString().trim().toUpperCase() === "YES" || refundableValue.toString().trim().toUpperCase() === "NO")) {
                        throw new Error("Fill in either YES OR NO for refundable")
                    }


                } else {
                    throw new Error("Fill in if refundable for all entries")
                }
            });
        } catch (e) {
            // console.log(e)
            props.snackBar({
                text: e.message,
                type: "error",
            });
            return;
        }

        let depositAmount = 0;

        expenseItem.forEach((value, key) => {
            if (key.toString().includes("value")) {
                depositAmount += parseFloat(value);
            }
        });

        setTotalDeposit(depositAmount)

        expenseItem.set("Total deposit", depositAmount)

        let refundableDepositAmount = 0;

        let handledKeys = [];

        expenseItem.forEach((value, key) => {
            let array = key.split(" ");
            let uniqueString = array[array.length - 1];


            if (handledKeys.includes(uniqueString)) {
                return
            }

            if (expenseItem.has("refundable " + uniqueString)) {
                let refundableValue = expenseItem.get("refundable " + uniqueString);

                if (refundableValue.toString().toUpperCase().trim() === "YES") {
                    refundableDepositAmount += parseFloat(expenseItem.get("value for description " + uniqueString))
                }

            }
            handledKeys.push(uniqueString)

        });

        setRefundableDeposit(refundableDepositAmount)
        expenseItem.set("Refundable deposit", refundableDepositAmount)

        // console.log(expenseItem)

        setDepositList(expenseItem)

        setDepositSet(true)
    };
    const handleChange = () => (event) => {
        // kkk;
        expenseItem.set(event.target.name, event.target.value);
        setDepositSet(false)

        // setDepositList(depositMap)
    };

    const [loading1, setLoading1] = React.useState(false);

    //Units State
    const [units, setUnits] = React.useState(null);
    const [selectedUnit, setSelectedUnit] = React.useState(null);

    const [isStudent, setIsStudent] = React.useState("NO");

    let [tenantInfo, setTenantInfo] = React.useState({
        tenant_id: "",
        name: "",
        last_name: "",
        unit: "",
        property_id: "",
    });
    const [propertyName, setPropertyName] = React.useState(null);
    const [searchPropertyID, setSearchPropertyID] = React.useState(null);

    const [addTenantPropertyName, setAddTenantPropertyName] = React.useState("");
    let [propertyID, setPropertyID] = React.useState(null);

    let initialValues = {
        id: "",
        first_name: "",
        description: "",
        amount: "",
    };
    const initialValues2 = {
        first_name: "",
        landlord_id: JSON.parse(originalText)[0][1],
        occupation_or_profession: "",
        last_name: "",
        phone_number: "",
        national_id: "",
        next_of_kin_relationship: "",
        next_of_kin_first_name: "",
        next_of_kin_last_name: "",
        next_of_kin_national_id: "",
        next_of_kin_contacts: "",
        institution_name: "",
        place_of_work: "",
        reason_for_relocating: "",
        previous_residence: "",
        email_address: "",
        rent: "",
    };
    let initialValues4 = {
        id: "",
        first_name: "",
        date: dateFrom,
        amount: "",
    };

    //Tenant Photo
    const [tenantPhoto, setTenantPhoto] = useState(null);
    const [photo, setPhoto] = useState(null);

    //Tenant National ID
    const [photoOfNationalID, setPhotoOfNationalID] = useState(null);
    const [nationalID, setNationalID] = useState(null);

    //Tenant Contract
    const [copyOfContract, setCopyOfContract] = useState(null);
    const [contract, setContract] = useState(null);

    //Tenant Checklist
    const [inventoryChecklist, setInventoryChecklist] = useState(null);
    const [checklist, setChecklist] = useState(null);

    function handleFile1(e) {
        let filename = getFileName(e);
        if (checkImageFile(filename)) {
            setPhoto(filename);
            setTenantPhoto(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload An Image File",
                type: "warning",
            });
        }
    }

    function handleFile2(e) {
        let filename = getFileName(e);
        if (checkImageFile(filename)) {
            setNationalID(filename);
            setPhotoOfNationalID(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload An Image File",
                type: "warning",
            });
        }
    }

    function handleFile3(e) {
        let filename = getFileName(e);
        if (checkTextFile(filename)) {
            setContract(filename);
            setCopyOfContract(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload A text,doc,docx or pdf File",
                type: "warning",
            });
        }
    }

    function handleFile4(e) {
        let filename = getFileName(e);
        if (checkTextFile(filename)) {
            setChecklist(filename);
            setInventoryChecklist(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload A text,doc,docx or pdf File",
                type: "warning",
            });
        }
    }

    const getFileName = (e) => {
        let startIndex =
            e.target.value.indexOf("\\") >= 0
                ? e.target.value.lastIndexOf("\\")
                : e.target.value.lastIndexOf("/");
        let filename = e.target.value.substring(startIndex);
        if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
            filename = filename.substring(1);
        }
        return filename;
    };

    function getExtension(filename) {
        let parts = filename.split(".");
        return parts[parts.length - 1];
    }

    function checkImageFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "jpg":
            case "jpeg":
            case "png":
            case "pdf":
                //etc
                return true;
        }
        return false;
    }

    function checkTextFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "txt":
            case "doc":
            case "docx":
            case "pdf":
                //etc
                return true;
        }
        return false;
    }

    const classes = useStyles();

    const getTenants = () => {
        fetch(ApiDetails + "pegasus/visionary/tenant/get/all/tenants", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let tenants = await response.json();

                if (response.status === 200) {
                    // console.log(data);
                    updateTenants(tenants);
                    setSearchResults(tenants);
                } else {
                    props.snackBar({text: "Failed Fetching Details", type: "error"});
                }
            })
            .catch((err) => console.log(err));
    };

    const handleClose = () => {
        setOpen(false);
        setArrearsModal(false);
        setDepositModal(false);
        setAddTenantModal(false);
        setTransactionModal(false);
    };
    const handleDelete = () => {
        setLoading(true);
        let tenantPropertyMap = properties.filter(
            (property) =>
                new Map(Object.entries(property)).get("property_id") ===
                tenantInfo.property_id
        );

        let tenantPropertyName = new Map(Object.entries(tenantPropertyMap[0])).get(
            "property_name"
        );

        //Make API call to delete tenant
        fetch(ApiDetails + "pegasus/visionary/tenant/delete", {
            method: "POST",
            body: JSON.stringify({
                ...tenantInfo,
                property_name: tenantPropertyName,
                landlord_email_address: JSON.parse(originalText)[5][1],
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    getTenants();
                    props.snackBar({
                        text: "Tenant Deleted Successful",
                        type: "success",
                    });
                    handleClose();
                    // window.location.href = "/tenantList";
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const searchUser = (e) => {
        if (propertyName === null) {
            setSearchResults(
                Array.from(tenants).filter((item) =>
                    (
                        item.first_name.toString().toLowerCase() +
                        " " +
                        item.last_name.toString().toLowerCase()
                    ).includes(e.target.value.toLowerCase())
                )
            );
        } else {
            setSearchResults(
                Array.from(tenants).filter((item) => {
                    return (
                        (
                            item.first_name.toString().toLowerCase() +
                            " " +
                            item.last_name.toString().toLowerCase()
                        )
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase()) &&
                        item.property_id.toString() === searchPropertyID.toString()
                    );
                })
            );
        }
    };
    const searchProperty = (e) => {
        if (e === null) {
            setPropertyName(null);
            setSearchResults(Array.from(tenants));
        } else {
            let propertySelected = new Map(JSON.parse(originalText2))
                .get("properties")
                .filter((property) => property.id.toString() === e.toString());

            let selectedPropertyName = new Map(
                Object.entries(new Map(Object.entries(propertySelected)).get("0"))
            ).get("property_name");
            setPropertyName(selectedPropertyName);
            setSearchResults(
                Array.from(tenants).filter(
                    (tenant) => tenant.property_id.toString() === e.toString()
                )
            );
        }
    };
    const addArrears = (values) => {
        //Add arrear to database
        let body = {
            tenant_id: tenantInfo.id,
            description: values.description,
            amount: values.amount,
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/add/arrears", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    getTenants();
                    handleClose();
                } else {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };
    const addTenant = (values) => {
        setTenantLoading(true);
        if (values.first_name === "") {
            setTenantLoading(false);
            props.snackBar({
                text: "Enter valid first name",
                type: "error",
            });
            return;
        }
        if (values.last_name === "") {
            setTenantLoading(false);
            props.snackBar({
                text: "Enter valid last name",
                type: "error",
            });
            return;
        }
        if (selectedUnit === null) {
            setTenantLoading(false);
            props.snackBar({
                text: "Please Select Property Unit",
                type: "error",
            });
            return;
        }
        if (values.rent === "") {
            setTenantLoading(false);
            props.snackBar({
                text: "Please Input Rent",
                type: "error",
            });
            return;
        }
        if (values.phone_number === "") {
            setTenantLoading(false);
            props.snackBar({
                text: "Please Input valid phone number",
                type: "error",
            });
            return;
        }
        values.is_student = isStudent;
        values.landlord_email_address = JSON.parse(originalText)[5][1];
        values.landlord_id = JSON.parse(originalText)[0][1];
        values.property_name = JSON.parse(originalText2)[1][1].filter(
            (item) => item.id === addTenantPropertyName
        )[0].property_name;
        values.property_id = addTenantPropertyName;
        values.house_number = selectedUnit;
        values.deposit = Array.from(depositList.entries());
        values.deposit_held = (depositList.get("Refundable deposit"));

        let formData = new FormData();
        //Add values to formData
        //formData.append("details", [detailsMap]);
        formData.append("body", JSON.stringify(values));
        formData.append("photo_of_national_id", photoOfNationalID);
        formData.append("copy_of_contract", copyOfContract);
        formData.append("inventory_checklist", inventoryChecklist);
        formData.append("tenant_photo", tenantPhoto);

        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/add/tenant", {
            method: "POST",
            body: formData,
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setTenantLoading(false);
                    setUnits(null);
                    setAddTenantPropertyName("");
                    getTenants();
                    props.snackBar({
                        text: "Tenant Added Successful",
                        type: "success",
                    });
                    handleClose();
                } else {
                    if (data["Message"].includes("duplicate key")) {
                        setTenantLoading(false);
                        props.snackBar({
                            text: "Phone Number Already Exists",
                            type: "error",
                        });

                    } else {
                        setTenantLoading(false);

                        props.snackBar({
                            text: "Something Went Wrong. Try Again Later",
                            type: "error",
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setTenantLoading(false);

                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };
    const getUnits = () => {
        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/property/getUnits", {
            method: "POST",
            body: JSON.stringify({property_id: propertyID}),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setUnits(data);
                    props.snackBar({
                        text: "Property Units Retrieved Successful",
                        type: "success",
                    });
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached",
                        type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                } else if (response.status === 500) {
                    if (data["Message"].includes("duplicate key")) {
                        if (data["Message"].includes("owners_email_address_uindex")) {
                            props.snackBar({
                                text: "Email Address Already Exists",
                                type: "error",
                            });
                            return;
                        }
                    }
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };
    const addTransaction = (values) => {
        setTransactionLoading(true);
        // return;
        //Add arrear to database
        /*
                    "TransactionType": "Pay Bill",
                            "TransID": "QIA3PD6BCB",
                            "TransTime": "20220910102100",
                            "TransAmount": "50.00",
                            "BusinessShortCode": "4103275",
                            "BillRefNumber": "M1",
                            "InvoiceNumber": "",
                            "OrgAccountBalance": "170.00",
                            "ThirdPartyTransID": "",
                            "MSISDN": "2547 ***** 249",
                            "FirstName": "DEREK"
                    */
        let body = {
            TransactionType: "Pay Bill",
            TransID: "**********",
            // TransTime: "20220910102100",
            TransTime: dateFrom,
            TransAmount: values.amount,
            BusinessShortCode: "4103275",
            BillRefNumber: tenantInfo.account_number,
            InvoiceNumber: "",
            OrgAccountBalance: "170.00",
            ThirdPartyTransID: "",
            MSISDN: "2547 ***** 249",
            FirstName: tenantInfo.name,
            tenant_id: tenantInfo.id,
            landlord_id: JSON.parse(originalText)[0][1],
        };
        if (values.amount === "") {
            props.snackBar({text: "Amount Can't Be Empty", type: "error"});
            return;
        }

        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/payments/", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    props.snackBar({
                        text: "Transaction Added",
                        type: "success",
                    });
                    getTenants();
                    handleClose();
                    // props.snackBar({ text: "RESPONSE 200", type: "success" });
                } else {
                    handleClose();
                    props.snackBar({
                        text: "Something Went Wrong, Please Try Again Later ",
                        type: "error",
                    });
                }
                setTransactionLoading(false);
            })
            .catch((err) => {
                console.log(err);
                handleClose();
                props.snackBar({
                    text: "Something Went Wrong, Please Try Again Later ",
                    type: "error",
                });
            });
    };

    const [loading, setLoading] = React.useState(false);
    const [transactionLoading, setTransactionLoading] = React.useState(false);
    const [tenantLoading, setTenantLoading] = React.useState(false);

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    let columns;

    columns = [
        {
            field: "user",
            headerName: "Name",
            // width: 200,
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: 'flex',
                            borderRadius: "3px",
                            backgroundColor: params.row.state === 'NOTICE' ? '#f1ebcd' : '',
                        }}
                        className="userListUser"
                    >
                        <Tooltip
                            title={params.row.state === 'NOTICE' ? 'Notice' : 'Active'}>
                            <div style={{margin: '0 5px'}}>
                                <Avatar
                                    {...stringAvatar(params.row.first_name.toString() + ' ' + params.row.last_name.toString())}
                                    style={{width: 35, height: 35, fontSize: "15px", fontWeight: "bold"}}
                                />
                            </div>
                        </Tooltip>

                        <div>
                            {params.row.first_name} {params.row.last_name}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "house_number",
            align: "center",
            headerName: "HSE NO",
            minWidth: 100,
            flex: 1,
            // width: 100,
            sortOrder: "ASC",
        },
        {
            field: "phone_number",
            align: "center",
            headerName: "Phone Number",
            minWidth: 150,
            flex: 1,
            // width: 150,
        },
        {
            field: "rent",
            headerName: "Rent",
            align: "center",
            headerAlign: "center",
            minWidth: 120,
            flex: 1,
            width: 120,
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        {parseInt(
                            params.row.rent.toString().replace(",", "")
                        ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        // {
        //   field: "arrears",
        //   headerName: "Arrears",
        //   width: 100,
        //   align: "right",
        //   renderCell: (params) => {
        //     // console.log(params.row.arrears);
        //     if (params.row.arrears === 0) {
        //       return (
        //         <div
        //           style={{
        //             width: "100px",
        //             display: "flex",
        //             justifyContent: "space-between",
        //           }}
        //         >
        //           <Add
        //             style={{
        //               fontSize: "17px",
        //               color: "red",
        //             }}
        //             onClick={() => {
        //               setTenantInfo({
        //                 id: params.row.tenant_id,
        //                 name: params.row.first_name,
        //                 last_name: params.row.last_name,
        //               });
        //               setArrearsModal(true);
        //             }}
        //           />
        //           <div style={{ color: "green" }}>{params.row.arrears}</div>
        //         </div>
        //       );
        //     } else {
        //       return (
        //         <div
        //           style={{
        //             width: "100px",
        //             display: "flex",
        //             justifyContent: "space-between",
        //           }}
        //         >
        //           <Add
        //             style={{
        //               fontSize: "17px",
        //               color: "red",
        //             }}
        //             onClick={() => {
        //               setTenantInfo({
        //                 id: params.row.tenant_id,
        //                 name: params.row.first_name,
        //                 last_name: params.row.last_name,
        //               });
        //               setArrearsModal(true);
        //             }}
        //           />
        //           <div style={{ color: "red" }}>{params.row.arrears}</div>
        //         </div>
        //       );
        //     }
        //   },
        // },
        {
            field: "bill",
            headerName: "Bill",
            minWidth: 120,
            flex: 1,
            // width: 180,
            headerAlign: "center",
            align: "right",
            renderCell: (params) => {
                return (
                    <div>
                        <div style={{color: "red"}}>
                            {params.row.status === "NOT PAID" ? (
                                <>
                                    {params.row.bill_details ? (
                                        <>
                                            {parseFloat(params.row.bill) === 0.0 ? (
                                                <div style={{color: "green"}}>
                                                    {" "}
                                                    {params.row.bill.toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "KSH",
                                                    })}
                                                </div>
                                            ) : (
                                                <div style={{color: "red"}}>
                                                    {params.row.bill.toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "KSH",
                                                    })}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {parseFloat(params.row.bill) +
                                            parseFloat(params.row.arrears) ===
                                            0.0 ? (
                                                <div style={{color: "green"}}>
                                                    {(
                                                        parseFloat(params.row.bill) +
                                                        parseFloat(params.row.arrears)
                                                    ).toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "KSH",
                                                    })}
                                                </div>
                                            ) : (
                                                <div style={{color: "red"}}>
                                                    {(
                                                        parseFloat(params.row.bill) +
                                                        parseFloat(params.row.arrears)
                                                    ).toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "KSH",
                                                    })}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {params.row.bill_details ? (
                                        <>
                                            {parseFloat(params.row.bill) === 0.0 ? (
                                                <div style={{color: "green"}}>
                                                    {params.row.bill.toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "KSH",
                                                    })}
                                                </div>
                                            ) : (
                                                <div style={{color: "red"}}>
                                                    {params.row.bill.toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "KSH",
                                                    })}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {parseFloat(params.row.bill) +
                                            parseFloat(params.row.arrears) ===
                                            0.0 ? (
                                                <div style={{color: "green"}}>
                                                    <>
                                                        {(
                                                            parseFloat(params.row.bill) +
                                                            parseFloat(params.row.arrears)
                                                        ).toLocaleString("en-US", {
                                                            style: "currency",
                                                            currency: "KSH",
                                                        })}
                                                    </>
                                                </div>
                                            ) : (
                                                <div style={{color: "red"}}>
                                                    <>
                                                        {(
                                                            parseFloat(params.row.bill) +
                                                            parseFloat(params.row.arrears)
                                                        ).toLocaleString("en-US", {
                                                            style: "currency",
                                                            currency: "KSH",
                                                        })}
                                                    </>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "",
            headerName: "Pay",
            minWidth: 80,
            flex: 1,
            // width: 80,
            headerAlign: "center",
            align: "right",
            renderCell: (params) => {
                return (
                    <>
                        {params.row.agency_id !== 0 &&
                        params.row.agency_id !== JSON.parse(originalText)[0][1] ? (
                            <></>
                        ) : (
                            <div
                                style={{
                                    width: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <PaymentsOutlined
                                    style={{
                                        scale: "1.5",
                                        color: "green",
                                    }}
                                    onClick={() => {
                                        setTenantInfo({
                                            id: params.row.tenant_id,
                                            name: params.row.first_name,
                                            last_name: params.row.last_name,
                                            account_number: params.row.account_number,
                                        });
                                        setTransactionModal(true);
                                    }}
                                />
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 120,
            flex: 1,
            // width: 150,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                let state = params.row.status;
                if (state === "NOT PAID") {
                    return (
                        <div className="userListAction">

                            <Chip label="not paid" color="error" variant="outlined"/>
                            {/*<Button*/}
                            {/*  variant="outlined"*/}
                            {/*  style={{*/}
                            {/*    color: "black",*/}
                            {/*    width: "100px",*/}
                            {/*    border: "none",*/}
                            {/*    padding: "5px 7px",*/}
                            {/*    borderRadius: "5px",*/}
                            {/*    backgroundColor: "#ff5362",*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  {params.row.status}*/}
                            {/*</Button>*/}
                        </div>
                    );
                } else if (state === "PAID") {
                    return (
                        <div className="userListAction">

                            <Chip label="paid" color="success" variant="outlined"/>

                            {/*<Button*/}
                            {/*  variant="outlined"*/}
                            {/*  style={{*/}
                            {/*    color: "black",*/}
                            {/*    width: "100px",*/}
                            {/*    border: "none",*/}
                            {/*    padding: "5px 7px",*/}
                            {/*    borderRadius: "5px",*/}
                            {/*    backgroundColor: "#00B000",*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  {params.row.status}*/}
                            {/*</Button>*/}
                        </div>
                    );
                } else {
                    return (
                        <div className="userListAction">

                            <Chip label="partial" color="warning" variant="outlined"/>


                            {/*<Button*/}
                            {/*  variant="outlined"*/}
                            {/*  style={{*/}
                            {/*    color: "black",*/}
                            {/*    width: "100px",*/}
                            {/*    border: "none",*/}
                            {/*    padding: "5px 7px",*/}
                            {/*    borderRadius: "5px",*/}
                            {/*    backgroundColor: "#ffd87c",*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  {params.row.status}*/}
                            {/*</Button>*/}
                        </div>
                    );
                }
            },
        },
        {
            field: "login_successful",
            headerName: "Logged in",
            flex: 1,
            minWidth: 80,
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        {params.row.login_successful ? (
                            <Chip
                                label="Yes"
                                color="success"
                                variant="outlined"
                                icon={<DoneOutlined/>}
                            />
                        ) : (
                            <Chip
                                label="No"
                                color="error"
                                variant="outlined"
                                icon={<ErrorIcon/>}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Actions",
            minWidth: 230,
            flex: 1,
            // width: 180,
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        {params.row.agency_id !== 0 &&
                        params.row.agency_id !== JSON.parse(originalText)[0][1] ? (
                            <></>
                        ) : (
                            <div className="userListAction">

                                <Chip
                                    label="Details"
                                    color={"info"}
                                    onClick={() => {
                                        // navigate("/tenant/" + params.row.tenant_id, {
                                        //   state: {
                                        //     tenant: data,
                                        //     arrears: arrears,
                                        //     transactions: transactions,
                                        //     propertyUnits: units,
                                        //   },
                                        // });

                                        navigate("/tenant/" + params.row.tenant_id, {
                                            state: {
                                                tenant_id: params.row.tenant_id,
                                            },
                                        });
                                    }}
                                    onDelete={() => {
                                        // navigate("/tenant/" + params.row.tenant_id, {
                                        //   state: {
                                        //     tenant: data,
                                        //     arrears: arrears,
                                        //     transactions: transactions,
                                        //     propertyUnits: units,
                                        //   },
                                        // });

                                        navigate("/tenant/" + params.row.tenant_id, {
                                            state: {
                                                tenant_id: params.row.tenant_id,
                                            },
                                        });
                                    }}
                                    deleteIcon={<MoreVert/>}
                                    variant="outlined"
                                />

                                <Chip
                                    label="Delete"
                                    color={"error"}
                                    onClick={() => {
                                        setTenantInfo({
                                            tenant_id: params.row.tenant_id,
                                            name: params.row.first_name,
                                            last_name: params.row.last_name,
                                            unit: params.row.house_number,
                                            property_id: params.row.property_id,
                                        });
                                        setOpen(true);
                                    }}
                                    onDelete={() => {
                                        setTenantInfo({
                                            tenant_id: params.row.tenant_id,
                                            name: params.row.first_name,
                                            last_name: params.row.last_name,
                                            unit: params.row.house_number,
                                            property_id: params.row.property_id,
                                        });
                                        setOpen(true);
                                    }}
                                    deleteIcon={<DeleteIcon/>}
                                    variant="outlined"
                                />
                            </div>
                        )}
                    </>
                );
            },
        },
    ];
    const getRowStyle = (params) => {

        // Customize the background color for a specific row based on the 'highlighted' property
        return {backgroundColor: params.row.state === 'NOTICE' ? '#CE3416' : 'black'};
    };

    return (
        <>
            {backDrop}
            <Dialog
                maxWidth={"md"}
                open={addTenantModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    // style={{
                    //   width: "90%",
                    // }}
                >
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Add Tenant
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                        zoom: matches ? "65%" : "100%",
                    }}
                >
                    <Formik
                        initialValues={initialValues2}
                        // validationSchema={validationSchema}
                        onSubmit={addTenant}
                    >
                        {(props) => (
                            <Form noValidate>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Field
                                        as={TextField}
                                        name="first_name"
                                        label="First Name"
                                        variant="outlined"
                                        error={props.errors.first_name && props.touched.first_name}
                                        helperText={<ErrorMessage name="first_name"/>}
                                        required
                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="last_name"
                                        label="Last Name"
                                        error={props.errors.last_name && props.touched.last_name}
                                        helperText={<ErrorMessage name="last_name"/>}
                                        required
                                        variant="outlined"
                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Field
                                        as={TextField}
                                        name="phone_number"
                                        label="Phone Number"
                                        error={
                                            props.errors.phoneNumber && props.touched.phoneNumber
                                        }
                                        helperText={<ErrorMessage name="phoneNumber"/>}
                                        required

                                        variant="outlined"
                                        style={{marginTop: "10px", width: "32%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="secondary_phone_number"
                                        label="Secondary Number"
                                        error={
                                            props.errors.secondary_phone_number &&
                                            props.touched.secondary_phone_number
                                        }
                                        helperText={<ErrorMessage name="secondary_phone_number"/>}

                                        variant="outlined"
                                        style={{marginTop: "10px", width: "32%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="national_id"
                                        label="National ID"
                                        type="number"
                                        required
                                        variant="outlined"
                                        style={{marginTop: "10px", width: "32%"}}
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormControl
                                        required
                                        style={{
                                            marginTop: "10px",
                                            width: "49%",
                                        }}
                                        label="Property *"
                                        name="is_student"
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Student
                                        </InputLabel>
                                        <Select
                                            name="is_student"
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            value={isStudent}
                                            label="Property *"
                                            onChange={(e) => {
                                                setIsStudent(e.target.value);
                                            }}
                                        >
                                            <MenuItem value="YES">
                                                <em>YES</em>
                                            </MenuItem>
                                            <MenuItem value="NO">
                                                <em>NO</em>
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText>Required</FormHelperText>
                                    </FormControl>
                                    {isStudent === "YES" ? (
                                        <>
                                            <Field
                                                as={TextField}
                                                name="institution_name"
                                                label="Institution Name"
                                                variant="outlined"
                                                error={
                                                    props.errors.first_name && props.touched.first_name
                                                }
                                                helperText={<ErrorMessage name="first_name"/>}

                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Field
                                                as={TextField}
                                                name="institution_name"
                                                label="Institution Name"
                                                variant="outlined"
                                                disabled={true}
                                                error={
                                                    props.errors.first_name && props.touched.first_name
                                                }
                                                helperText={<ErrorMessage name="first_name"/>}

                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                        </>
                                    )}
                                    <br/>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Field
                                        as={TextField}
                                        name="occupation_or_profession"
                                        label="Occupation"
                                        variant="outlined"
                                        error={props.errors.first_name && props.touched.first_name}
                                        helperText={<ErrorMessage name="first_name"/>}

                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="place_of_work"
                                        label="Place Of Work"
                                        error={props.errors.last_name && props.touched.last_name}
                                        helperText={<ErrorMessage name="last_name"/>}

                                        variant="outlined"
                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Field
                                        as={TextField}
                                        name="reason_for_relocating"
                                        label="Reason For Relocation"
                                        variant="outlined"
                                        error={props.errors.first_name && props.touched.first_name}
                                        helperText={<ErrorMessage name="first_name"/>}

                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="previous_residence"
                                        label="Previous Residence"
                                        error={props.errors.last_name && props.touched.last_name}
                                        helperText={<ErrorMessage name="last_name"/>}

                                        variant="outlined"
                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <FormControl
                                        name="property_name"
                                        required
                                        style={{
                                            marginTop: "10px",
                                            width: "49%",
                                        }}
                                        label="Property *"
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Select Property
                                        </InputLabel>
                                        <Select
                                            name="property_name"
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            value={addTenantPropertyName}
                                            label="Property *"
                                            onChange={(e) => {
                                                setAddTenantPropertyName(e.target.value);
                                                propertyID = e.target.value;
                                                setPropertyID(e.target.value);
                                                getUnits();
                                            }}
                                        >

                                            {new Map(JSON.parse(originalText2))
                                                .get("properties")
                                                .map((property, index) => (
                                                    <MenuItem key={index} value={property.id}>
                                                        {property.property_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        <FormHelperText>Required</FormHelperText>
                                    </FormControl>
                                    <br/>

                                    {units !== null ? (
                                        <>
                                            <FormControl
                                                name="house_number"
                                                required
                                                style={{
                                                    marginTop: "10px",
                                                    width: "49%",
                                                }}
                                                label="Property *"
                                            >
                                                <InputLabel id="demo-simple-select-required-label">
                                                    Select House NO
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-required-label"
                                                    id="demo-simple-select-required"
                                                    value={selectedUnit}
                                                    label="Unit *"
                                                    onChange={(e) => {
                                                        setSelectedUnit(e.target.value);
                                                    }}
                                                >

                                                    {units.map((unit, index) => {
                                                        let hse = unit.state;
                                                        if (hse === "OCCUPIED") {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={unit.unit}
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                    disabled={true}
                                                                >
                                                                    <span>{unit.unit}</span>
                                                                    <small>{unit.state.toLowerCase()}</small>
                                                                </MenuItem>
                                                            );
                                                        } else {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={unit.unit}
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <span>{unit.unit}</span>
                                                                    <small>{unit.state.toLowerCase()}</small>
                                                                </MenuItem>
                                                            );
                                                        }
                                                    })}
                                                </Select>
                                                <FormHelperText>Required</FormHelperText>
                                            </FormControl>
                                            <br/>
                                        </>
                                    ) : (
                                        <>
                                            <FormControl
                                                name="house_number"
                                                required
                                                style={{
                                                    marginTop: "10px",
                                                    width: "49%",
                                                }}
                                                label="Property *"
                                                disabled={true}
                                            >
                                                <InputLabel id="demo-simple-select-required-label">
                                                    Select House NO
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-required-label"
                                                    id="demo-simple-select-required"
                                                    value={selectedUnit}
                                                    label="Unit *"
                                                    onChange={(e) => {
                                                        setSelectedUnit(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>Required</FormHelperText>
                                            </FormControl>
                                            <br/>
                                        </>
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Field
                                        as={TextField}
                                        name="rent"
                                        label="Rent"
                                        type="number"
                                        required
                                        variant="outlined"
                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="deposit_held"
                                        label="Refundable Deposit"
                                        type="number"
                                        required
                                        value={refundableDeposit}
                                        disabled={true}
                                        variant="outlined"
                                        style={{marginTop: "10px", width: "49%"}}
                                    />

                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >

                                    <Button onClick={() => {
                                        setAddTenantModal(false)
                                        setDepositModal(true)
                                        // console.log("Adding deposit")
                                    }}>
                                        <span style={{color: "green"}}>Add deposit</span>
                                    </Button>

                                </div>

                                <br/>
                                <Typography style={{textAlign: "center"}}>
                                    NEXT OF KIN DETAILS
                                </Typography>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Field
                                        as={TextField}
                                        name="next_of_kin_first_name"
                                        label="First Name"
                                        error={props.errors.first_name && props.touched.first_name}
                                        helperText={<ErrorMessage name="first_name"/>}

                                        variant="outlined"
                                        style={{marginTop: "10px", width: "32%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="next_of_kin_last_name"
                                        label="Last Name"
                                        error={props.errors.first_name && props.touched.first_name}
                                        helperText={<ErrorMessage name="first_name"/>}

                                        variant="outlined"
                                        style={{marginTop: "10px", width: "32%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="next_of_kin_relationship"
                                        label="Relationship"
                                        error={props.errors.first_name && props.touched.first_name}
                                        helperText={<ErrorMessage name="first_name"/>}

                                        variant="outlined"
                                        style={{marginTop: "10px", width: "32%"}}
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Field
                                        as={TextField}
                                        name="next_of_kin_contacts"
                                        label="Phone Number"
                                        error={
                                            props.errors.phoneNumber && props.touched.phoneNumber
                                        }
                                        helperText={<ErrorMessage name="phoneNumber"/>}

                                        variant="outlined"
                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="next_of_kin_national_id"
                                        label="National ID"
                                        type="number"

                                        variant="outlined"
                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                </div>

                                <br/>
                                <Typography style={{textAlign: "center"}}>
                                    TENANT FILES
                                </Typography>
                                <br/>
                                <div
                                    style={{display: "flex", justifyContent: "space-between"}}
                                >
                  <span
                      style={{
                          width: "49%",
                          display: "flex",
                          justifyContent: "space-between",
                      }}
                  >
                    {tenantPhoto === null ? (
                        <>
                            <span>Tenant Photo </span>
                            <Button variant="outlined" component="label">
                                Upload File
                                <input
                                    type="file"
                                    onChange={(e) => handleFile1(e)}
                                    hidden
                                />
                            </Button>
                        </>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <span>{photo}</span>
                                <DeleteOutline
                                    className="userListDelete"
                                    onClick={() => {
                                        setTenantPhoto(null);
                                        setPhoto(null);
                                    }}
                                />
                            </div>
                        </>
                    )}
                  </span>
                                    <span
                                        style={{
                                            width: "49%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                    {photoOfNationalID === null ? (
                        <>
                            <span>Photo Of National ID </span>
                            <Button variant="outlined" component="label">
                                Upload File
                                <input
                                    type="file"
                                    onChange={(e) => handleFile2(e)}
                                    hidden
                                />
                            </Button>
                        </>
                    ) : (
                        <>
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <span>{nationalID}</span>
                                    <DeleteOutline
                                        className="userListDelete"
                                        onClick={() => {
                                            setPhotoOfNationalID(null);
                                            setNationalID(null);
                                        }}
                                    />
                                </div>
                            </>
                        </>
                    )}
                  </span>
                                </div>
                                <br/>
                                <div
                                    style={{display: "flex", justifyContent: "space-between"}}
                                >
                  <span
                      style={{
                          width: "49%",
                          display: "flex",
                          justifyContent: "space-between",
                      }}
                  >
                    {copyOfContract === null ? (
                        <>
                            {" "}
                            <span>Copy Of Contract</span>
                            <Button variant="outlined" component="label">
                                Upload File
                                <input
                                    type="file"
                                    onChange={(e) => handleFile3(e)}
                                    hidden
                                />
                            </Button>
                        </>
                    ) : (
                        <>
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <span>{contract}</span>
                                    <DeleteOutline
                                        className="userListDelete"
                                        onClick={() => {
                                            setCopyOfContract(null);
                                            setContract(null);
                                        }}
                                    />
                                </div>
                            </>
                        </>
                    )}
                  </span>
                                    <span
                                        style={{
                                            width: "49%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                    {inventoryChecklist === null ? (
                        <>
                            <span>Inventory Checklist</span>
                            <Button variant="outlined" component="label">
                                Upload File
                                <input
                                    type="file"
                                    onChange={(e) => handleFile4(e)}
                                    hidden
                                />
                            </Button>
                        </>
                    ) : (
                        <>
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <span>{checklist}</span>
                                    <DeleteOutline
                                        className="userListDelete"
                                        onClick={() => {
                                            setInventoryChecklist(null);
                                            setChecklist(null);
                                        }}
                                    />
                                </div>
                            </>
                        </>
                    )}
                  </span>
                                </div>

                                <br/>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button onClick={handleClose}>
                                        <span style={{color: "red"}}>Cancel</span>
                                    </Button>
                                    <LoadingButton
                                        type={"submit"}
                                        size="small"
                                        // onClick={addTenant}
                                        loading={tenantLoading}
                                        startIcon={<SendIcon/>}
                                        style={{color: "green"}}
                                        loadingPosition="start"
                                    >
                                        Submit
                                    </LoadingButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <Dialog
                open={depositModal}
                fullWidth
                maxWidth="sm"
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Add deposit
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography>Deposit details</Typography>
                            <Button
                                onClick={() => {
                                    setExpensesIncurred(expensesIncurred + 1);
                                    setInputs(
                                        [...Array(expensesIncurred)].map(
                                            (elementInArray, index) => (
                                                <div key={index}>
                                                    <br/>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <TextField
                                                            label="Description"
                                                            type={"text"}
                                                            variant="outlined"
                                                            name={"description " + (index + 1)}
                                                            required
                                                            inputRef={textRef}
                                                            style={{marginTop: "10px", width: "35%"}}
                                                            onChange={handleChange()}
                                                        />
                                                        <TextField
                                                            label="Amount"
                                                            type={"number"}
                                                            name={"value for description " + (index + 1)}
                                                            variant="outlined"
                                                            style={{marginTop: "10px", width: "35%"}}
                                                            onChange={handleChange()}
                                                        />

                                                        <TextField
                                                            label="refundable"
                                                            type={"text"}
                                                            name={"refundable " + (index + 1)}
                                                            variant="outlined"
                                                            style={{marginTop: "10px", width: "20%"}}
                                                            onChange={handleChange()}
                                                        />

                                                    </div>
                                                </div>
                                            )
                                        )
                                    );
                                }}
                            >
                                <span style={{color: "green"}}>Add Deposit</span>
                            </Button>
                        </div>
                        <div>{inputs}</div>
                        <br/>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    calculateRefundableDeposit();
                                    // console.log("Adding deposit")
                                }}
                                variant="outlined"
                            >
                                Calculate
                            </Button>
                        </div>

                        <br/>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green",
                                    flex: "2",
                                }}
                            >
                                Total deposit :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                    display: "flex",
                                    justifyContent: "right",
                                    fontSize: "30sp",
                                    fontWeight: "bold"
                                }}
                            >
                                {totalDeposit.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>

                        <div
                            style={{
                                marginTop: "10px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green",
                                    flex: "2",
                                }}
                            >
                                Refundable deposit :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                    display: "flex",
                                    justifyContent: "right",
                                    fontSize: "30sp",
                                    fontWeight: "bold"
                                }}
                            >
                                {refundableDeposit.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            // window.location.reload(false);

                            handleClose();
                            setAddTenantModal(true)
                        }}
                    >
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={() => {
                            // console.log("Adding expenses")
                            handleClose();
                            setAddTenantModal(true)
                        }}
                        disabled={!depositSet}
                        loading={loading1}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Agree
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            {/*Transaction MODAL*/}
            <Dialog
                open={transactionModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Payment Details
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <Formik initialValues={initialValues4} onSubmit={addTransaction}>
                        {() => (
                            <Form noValidate>
                                <Field
                                    as={TextField}
                                    name="first_name"
                                    label="Tenant Name"
                                    value={tenantInfo.name + " " + tenantInfo.last_name}
                                    variant="outlined"
                                    fullwidth
                                    required
                                    disabled={true}
                                    style={{marginTop: "8px", width: "100%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    fullwidth
                                    required
                                    variant="outlined"
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}
                                />
                                <div
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disableFuture
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Date "
                                            openTo="year"
                                            views={["year", "month", "day"]}
                                            value={dateFrom}
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button onClick={handleClose}>
                                        <span style={{color: "red"}}>Cancel</span>
                                    </Button>
                                    <LoadingButton
                                        size="small"
                                        type={"submit"}
                                        // onClick={sendBroadcastMessage}
                                        loading={transactionLoading}
                                        startIcon={<SendIcon/>}
                                        style={{color: "green"}}
                                        loadingPosition="start"
                                    >
                                        Agree
                                    </LoadingButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>

            </Dialog>
            <Dialog
                open={deleteModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogTitle style={{color: "red"}}>
                    Delete {tenantInfo.name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography>
                            Deleting a Tenant will completely remove them and their respective
                            documents from the system and render a house vacant.
                        </Typography>
                        <br/>
                        <Typography>
                            Are you sure you want to delete this tenant ?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={handleDelete}
                        loading={loading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Agree
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            {/*Arrears MODAL*/}
            <Dialog
                open={arrearsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Add Arrear
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <Formik initialValues={initialValues} onSubmit={addArrears}>
                        {(props) => (
                            <Form noValidate>
                                <Field
                                    as={TextField}
                                    name="first_name"
                                    label="Tenant Name"
                                    value={tenantInfo.name + " " + tenantInfo.last_name}
                                    variant="outlined"
                                    fullwidth
                                    required
                                    disabled={true}
                                    style={{marginTop: "8px", width: "100%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    fullwidth
                                    required
                                    variant="outlined"
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}
                                />
                                <Field
                                    as={TextareaAutosize}
                                    name="description"
                                    required
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="Arrear description ... "
                                    style={{width: "100%"}}
                                />
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button onClick={handleClose}>
                                        <span style={{color: "red"}}>Cancel</span>
                                    </Button>
                                    <Button type="submit">
                                        <span style={{color: "green"}}>Submit</span>
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <div className="userList">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    {matches ? (
                        <></>
                    ) : (
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={new Map(JSON.parse(originalText2))
                                .get("properties")
                                .map((property) => {
                                    return property.property_name;
                                })}
                            style={{width: 300, padding: "10px 10px"}}
                            onChange={(e, value) => {
                                if (value !== null) {
                                    let propertySelected = new Map(JSON.parse(originalText2))
                                        .get("properties")
                                        .filter(
                                            (property, index) => property.property_name === value
                                        );
                                    let selectedPropertyId = new Map(
                                        Object.entries(
                                            new Map(Object.entries(propertySelected)).get("0")
                                        )
                                    ).get("id");

                                    setSearchPropertyID(selectedPropertyId);

                                    searchProperty(selectedPropertyId);
                                } else {
                                    setPropertyName(null);
                                    setSearchResults(Array.from(tenants));
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    id="outlined-basic"
                                    label="Filter Properties ... "
                                    variant="outlined"
                                    name="property"
                                    {...params}
                                />
                            )}
                        />
                    )}
                    <div
                        style={{
                            padding: "10px 10px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            label="Search all tenants ... "
                            variant="outlined"
                            name="firstname"
                            onChange={(e) => {
                                searchUser(e);
                            }}
                            style={{paddingRight: "10px"}}
                        />
                    </div>
                </div>
                <div className={"userListDataGrid"}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{field: "house_number", sort: "asc"}],
                            },
                        }}
                        className={classes.root}
                        rows={searchResults}
                        disableSelectionOnClick
                        rowHeight={50}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        getRowStyle={getRowStyle}
                    />
                </div>
            </div>
        </>
    );
}
