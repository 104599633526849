import "./transactions.css";
import {makeStyles} from "@material-ui/core/styles";
import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import {InputLabel, TextField} from "@material-ui/core";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {ApiDetails, KEY} from "../../Constants";
import {Avatar, FormControl, MenuItem, Select, useMediaQuery} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CryptoJS from "crypto-js";
import {Field, Form, Formik} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//create your forceUpdate hook WHICH DID NOT WORK

const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});

export default function Transactions(props) {
    const matches = useMediaQuery("(max-width:700px)");

    let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.getItem("myMap"),
        KEY
    );
    let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.portfolioDetails,
        KEY
    );
    let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);
    let originalText2 = portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    //Modal states
    const [transactionDetailsModal, setOpen] = useState(false);
    const [confirmTransactionModal, setConfirmTransactionModal] = useState(false);

    //Modal Details
    const [details, setDetails] = useState({
        id: "",
        message: "",
        date: "",
        amount: "",
    });

    //Date values
    const [dateFrom, setDateFrom] = React.useState(new Date("1999-08-04"));
    const [dateTo, setDateTo] = React.useState(new Date("1999-08-04"));

    const [propertyName] = React.useState(null);

    const [properties, setProperties] = useState([])

    const classes = useStyles();

    //Loading Screen
    const [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            onClick={null}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );

    //Trying To Use UseEffect To Set Component Data
    useEffect(() => {
        props.refreshSideBar();
        fetch(ApiDetails + "pegasus/visionary/transactions/", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                // console.log(data);
                if (response.status === 200) {
                    setTransactions(data);
                    setSearchResults(data);
                    setBackDrop(null);
                } else {
                    props.snackBar({
                        text: "Failed fetching transactions.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });


        //Fetch properties
        fetch(ApiDetails + "pegasus/visionary/property/get/all/properties", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                // console.log(data);
                if (response.status === 200) {
                    setProperties(data);
                    setBackDrop(null);
                } else {
                    props.snackBar({
                        text: "Failed fetching properties.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    }, []);

    let [transactions, setTransactions] = React.useState([]);

    const [searchResults, setSearchResults] = useState(transactions);

    const handleClose = () => {
        setOpen(false);
        setConfirmTransactionModal(false)
    };

    const searchTransactions = (e) => {
        if (propertyName === null) {
            setSearchResults(
                Array.from(transactions).filter((item) =>
                    item.transaction_message
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
        } else if (propertyName !== null) {
            setSearchResults(
                Array.from(transactions).filter((item) => {
                    if (
                        item.first_name
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase()) &&
                        item.property.toLowerCase().includes(propertyName.toLowerCase())
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                })
            );
        }
    };

    const searchTransactionsByDate = () => {
        setSearchResults(
            Array.from(transactions).filter((item) => {
                if (
                    new Date(item.date_created) >= dateFrom &&
                    new Date(item.date_created) <= dateTo
                ) {
                    return true;
                } else {
                    return false;
                }
            })
        );
    };

    const columns = [
        {
            field: "transaction_id",
            headerName: "Transaction ID",
            headerAlign: "center",
            align: "center",
            width: 180,
        },
        {
            field: "phone_number",
            headerName: "Phone NO",
            width: 150,
            align: "center",
            headerAlign: "center",
        },
        // {
        //   field: "transaction_message",
        //   headerName: "Message",
        //   width: 200,
        //   align: "left",
        //
        //   headerAlign: "center",
        // },
        {
            field: "amount",
            headerName: "Amount",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "date_created",
            headerName: "Date",
            width: 200,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "action",
            headerName: "Actions",
            width: 200,
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        <Button
                            onClick={() => {
                                setDetails({
                                    id: params.row.transaction_id,
                                    message: params.row.transaction_message,
                                    date: params.row.date_created,
                                    amount: params.row.amount,
                                });
                                setOpen(true);
                            }}
                            variant="outlined"
                        >
                            Details
                        </Button>
                    </div>
                );
            },
        },
    ];

    const [selectedProperty, setSelectedProperty] = useState()

    let initialPaymentValues = {
        transaction_code: "",
    };

    const [transactionLoading, setTransactionLoading] = useState(false)

    const confirmTransaction = async (values) => {
        setTransactionLoading(true);

        // console.log(values)

        let body = {
            transaction_code: values.transaction_code,
            property_id: selectedProperty
        };

        // console.log(body);
        fetch(ApiDetails + "pegasus/visionary/mpesa/Confirm/Payment", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setTransactionLoading(false);
                    props.snackBar({
                        text: "Kindly wait For mpesa prompt on your phone",
                        type: "success",
                    });
                } else if (response.status === 403) {
                    // console.log(data);
                    setTransactionLoading(false);
                    props.snackBar({
                        text: new Map(Object.entries(data)).get("Message"),
                        type: "error",
                    });
                } else {
                    // console.log(data);
                    setTransactionLoading(false);
                    props.snackBar({
                        text: new Map(Object.entries(data)).get("Message"),
                        type: "error",
                    });
                }
                handleClose();
            })
            .catch((err) => {
                console.log(err);
                handleClose();
                setTransactionLoading(false);
                props.snackBar({
                    text: "Payment Not Successful",
                    type: "error",
                });
            });

        // fetchTenantDetails();
    };

    const handleChange = (event) => {
        setSelectedProperty(event.target.value);
    };

    return (
        <>
            {backDrop}
            {/*DELETE MODAL*/}
            <Dialog
                open={transactionDetailsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    Transaction ID :<span style={{color: "blue"}}> {details.id}</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography>{details.message}</Typography>
                        <br/>
                        <Typography>
                            {details.amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "KSH",
                            })}
                        </Typography>
                        <br/>
                        <small>{details.date}</small>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "green"}}>Cancel</span>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={confirmTransactionModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{
                        color: "#398DD2",
                    }}
                >
                    <h3
                        style={{
                            fontWeight: "bold",
                            color: "#398DD2",
                        }}
                    >
                        Confirm MPESA transaction
                    </h3>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialPaymentValues}
                        onSubmit={confirmTransaction}
                    >
                        {(props) => (
                            <Form noValidate>
                                <Field
                                    as={TextField}
                                    name="transaction_code"
                                    label="MPESA code"

                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", marginBottom: "10px", width: "100%"}}
                                />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Property</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedProperty}
                                        label="Property"
                                        onChange={handleChange}
                                    >

                                        {properties.map((property) => {
                                            return (
                                                <MenuItem
                                                    value={property.property_id}>{property.property_name}</MenuItem>
                                            )
                                        })}

                                    </Select>
                                </FormControl>

                                <br/>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button onClick={handleClose}>
                                        <span style={{color: "red"}}>Cancel</span>
                                    </Button>
                                    <LoadingButton
                                        type={"submit"}
                                        size="small"
                                        // onClick={addTenant}
                                        loading={transactionLoading}
                                        startIcon={<SendIcon/>}
                                        style={{color: "green"}}
                                        loadingPosition="start"
                                    >
                                        Submit
                                    </LoadingButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <div className="userList">
                <div>
                    <div
                        style={{
                            padding: "10px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disableFuture
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Date From ... "
                                            openTo="year"
                                            views={["year", "month", "day"]}
                                            value={dateFrom}
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                                searchTransactionsByDate();
                                            }}
                                            renderInput={(params) => (
                                                <TextField variant={"outlined"} {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disableFuture
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Date To ..."
                                            openTo="year"
                                            views={["year", "month", "day"]}
                                            value={dateTo}
                                            onChange={(newValue) => {
                                                setDateTo(newValue);
                                                searchTransactionsByDate();
                                            }}
                                            renderInput={(params) => (
                                                <TextField variant={"outlined"} {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <Tooltip title="Feature is still in development">
                                        <Button
                                            // disabled={true}

                                            onClick={() => {

                                                // return
                                                setConfirmTransactionModal(true);
                                            }}
                                            variant="outlined"
                                            style={{
                                                color: "white",
                                                text_transform: "uppercase",
                                                width: "200px",
                                                line_height: "50px",
                                                border_radius: "5px",
                                                background: "#00B000",
                                                text_align: "center",
                                                box_shadow: "inset 0 0 25px rgba(0,0,0,.25)",
                                            }}
                                        >
                                            Confirm transaction
                                        </Button>
                                    </Tooltip>

                                </Box>


                            </div>
                        </div>

                        {/*<div*/}
                        {/*  style={{*/}
                        {/*    display: "flex",*/}
                        {/*    justifyContent: "space-between",*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  <TextField*/}
                        {/*    id="outlined-basic"*/}
                        {/*    label="Search Messages ... "*/}
                        {/*    variant="outlined"*/}
                        {/*    name="firstname"*/}
                        {/*    onChange={(e) => {*/}
                        {/*      searchTransactions(e);*/}
                        {/*    }}*/}
                        {/*    style={{ width: "fit-content" }}*/}
                        {/*  />*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={"userListDataGrid"}>
                    <DataGrid
                        className={classes.root}
                        rows={searchResults}
                        disableSelectionOnClick
                        rowHeight={45}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </div>
            </div>
        </>
    );
}
