import React, {useState} from 'react';
import './FloatingButton.css'; // CSS file for styling

const FloatingButton = (props) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className={`floating-button ${isHovered ? 'hovered' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={()=>{
                // console.log(props)
                props.onClick()
            }}
        >
            <div className="icon-container">
                <span className="plus-icon">
                    +
                </span>
            </div>
        </div>
    );
};

export default FloatingButton;
