import React from "react";
import "./widgetLg.css";
import CryptoJS from "crypto-js";
import { KEY } from "../../Constants";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      fontSize: 16,
      color: "darkblue",
      backgroundColor: "rgb(245,245,245)",
    },
  },
});
export default function WidgetLg() {
  let decrypted = CryptoJS.AES.decrypt(localStorage.portfolioDetails, KEY);
  let originalText = decrypted.toString(CryptoJS.enc.Utf8);
  let [transactions] = React.useState(
    Array.from(new Map(JSON.parse(originalText)).get("transactions"))
  );
  const [status] = React.useState(localStorage.getItem("isNew"));
  if (status === "no") {
    transactions = Array.from(
      new Map(JSON.parse(originalText)).get("transactions")
    );
  }

  const classes = useStyles();
  let columns;

  columns = [
    {
      field: "transaction_id",
      headerName: "ID",
      // width: 180,
      minWidth: 120,
      flex: 1,
      // sortOrder: "asc",
    },
    {
      field: "amount",
      align: "left",
      headerName: "Amount",
      // width: 120,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.amount.toLocaleString("en-US", {
              style: "currency",
              currency: "KSH",
            })}
          </div>
        );
      },
    },
    {
      field: "Name",
      // align: "center",
      headerName: "Name",
      // width: 200,
      minWidth: 150,
      flex: 1,
    },{
      field: "date_created",
      align: "center",
      headerName: "Date",
      // width: 200,
      minWidth: 200,
      flex: 1,
    },
  ];
  return (
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Latest Transactions</h3>
      <div
        style={{
          height: "350px",
        }}
      >
        <DataGrid
          className={classes.root}
          rows={transactions}
          disableSelectionOnClick
          // rowHeight={45}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </div>
  );
}
