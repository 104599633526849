import React, { useEffect, useRef, useState } from "react";
import "./message.css";
import { makeStyles } from "@material-ui/core/styles";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {
  AttachFile,
  MoreVert,
  Search,
  SendOutlined,
  SentimentSatisfiedAlt,
  Sms,
} from "@material-ui/icons";
import { ApiDetails, KEY } from "../../Constants";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import { TextField } from "@material-ui/core";
import EmojiPicker from "emoji-picker-react";
import CryptoJS from "crypto-js";

export default function Message(props) {
  let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
    localStorage.getItem("myMap"),
    KEY
  );
  let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

  const [backDrop, setBackDrop] = useState(
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={null}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
  let [tenants, updateTenants] = React.useState([]);
  let [messages, updateMessages] = React.useState([]);
  const [searchResults, setSearchResults] = useState(tenants);
  const [activePage, setActivePage] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  //Trying To Use UseEffect To Set Component Data
  useEffect(() => {
    props.refreshSideBar();
    //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
    fetch(ApiDetails + "pegasus/visionary/tenant/get/all/tenants", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
      }),
    })
      .then(async (response) => {
        let tenants = await response.json();

        if (response.status === 200) {
          // console.log(data);

          //Get All Messages
          fetch(ApiDetails + "pegasus/visionary/messages/", {
            method: "POST",
            body: JSON.stringify({
              id: JSON.parse(originalText)[0][1],
            }),
          })
            .then(async (response) => {
              let messages = await response.json();
              if (response.status === 200) {
                // console.log(messages);
                updateTenants(tenants);
                setSearchResults(tenants);
                updateMessages(messages);
                setBackDrop(<></>);
              } else {
                props.snackBar({
                  text: "Failed Fetching Details",
                  type: "error",
                });
              }
            })
            .catch((err) => console.log(err));
        } else {
          props.snackBar({ text: "Failed Fetching Details", type: "error" });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    try {
      let elem = document.getElementById("messages");
      elem.scrollTop = elem.scrollHeight;
    } catch (e) {}
  }, [selectedUser]);

  const [message, setMessage] = useState();

  const getTenantLastMessage = (tenant_id) => {
    let tenantMessages = messages.filter(
      (message, index) =>
        message.sender_id === tenant_id || message.recipient_id === tenant_id
    );
    let lastMessage;
    try {
      lastMessage = new Map(
        Object.entries(tenantMessages[tenantMessages.length - 1])
      ).get("message");
    } catch (e) {
      lastMessage = "";
    }

    return lastMessage;
  };

  const searchUser = (e) => {
    setSearchResults(
      Array.from(tenants).filter((item) =>
        (
          item.first_name.toString().toLowerCase() +
          " " +
          item.last_name.toString().toLowerCase()
        ).includes(e.target.value.toLowerCase())
      )
    );
  };

  const onSubmit = (message, tenant_id) => {
    // console.log(message, tenant_id);

    //Send Message To tenant
    fetch(ApiDetails + "pegasus/visionary/messages/send", {
      method: "POST",
      body: JSON.stringify({
        sender: "landlord",
        sender_id: JSON.parse(originalText)[0][1],
        recipient_id: tenant_id,
        message: message,
      }),
    })
      .then(async (response) => {
        if (response.status === 200) {
          fetch(ApiDetails + "pegasus/visionary/messages/", {
            method: "POST",
            body: JSON.stringify({
              id: JSON.parse(originalText)[0][1],
            }),
          })
            .then(async (response) => {
              let messages = await response.json();
              if (response.status === 200) {
                // console.log(messages);
                updateMessages(messages);
              } else {
                props.snackBar({
                  text: "Failed Fetching Details",
                  type: "error",
                });
              }
            })
            .catch((err) => console.log(err));
        } else {
          props.snackBar({
            text: "Failed Sending Message",
            type: "error",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const getInitials = function (string) {
    let names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <>
      {backDrop}
      <div className="messages">
        <div className={"messageContainer"}>
          <div className="leftSide">
            <div className="search_chat">
              <div>
                <input
                  type="text"
                  onChange={(event) => {
                    searchUser(event);
                  }}
                  placeholder={"Search or start new chat"}
                />
                <Search
                  style={{
                    position: "absolute",
                    left: "25px",
                    top: "19px",
                  }}
                />
              </div>
            </div>

            {/*Chat List*/}
            <div className="chatlist">
              {/*Loop Through All Tenants*/}

              {searchResults.map((tenant, index) => {
                return (
                  <div
                    key={index}
                    className="chat_box"
                    style={{
                      cursor: "pointer",
                      padding: "10px 10px",
                      width: "calc(100% - 20px)",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={(event) => {
                      setSelectedUser({
                        id: tenant.tenant_id,
                        name: tenant.first_name + " " + tenant.last_name,
                        agency_id: tenant.agency_id,
                      });

                      if (activePage !== null) {
                        activePage.className = "chat_box";
                      }

                      //Set New Active user ClassName
                      event.target.className = "chat_box_active";
                      setActivePage(event.target);
                    }}
                  >
                    <div
                      style={{
                        paddingRight: "10px",
                        pointerEvents: "none",
                      }}
                    >
                      <Avatar sx={{ width: 45, height: 45 }}>
                        {getInitials(
                          tenant.first_name + " " + tenant.last_name
                        )}
                      </Avatar>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pointerEvents: "none",
                      }}
                      className="chat_details"
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.1em",
                            fontWeight: 600,
                            color: "#111",
                          }}
                          className="tenant_name"
                        >
                          {tenant.first_name} {tenant.last_name}
                        </div>
                        <span
                          style={{
                            fontSize: "0.75em",
                            color: "#06d755",
                          }}
                        >
                          {/*10:30*/}
                        </span>
                      </div>

                      <div
                        className={"last_message"}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "block" /* or inline-block */,
                            color: "#aaa",
                            fontSize: "0.9em",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            maxHeight: "3.0em",
                            lineHeight: "1.5em",
                            width: "30ch",
                          }}
                        >
                          {getTenantLastMessage(tenant.tenant_id)}
                        </div>

                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="rightSide">
            {selectedUser === null ? (
              <></>
            ) : (
              <>
                {/*Header*/}
                <div className="header">
                  <div className="imgText">
                    <div className="userimg">
                      <Avatar sx={{ width: 45, height: 45 }}>
                        {getInitials(selectedUser.name)}
                      </Avatar>
                    </div>
                    <h4>
                      {selectedUser.name} <br />
                      {/*<span>online</span>*/}
                    </h4>
                    {selectedUser.agency_id !== 0 &&
                    selectedUser.agency_id !==
                      JSON.parse(originalText)[0][1] ? (
                      <div
                        style={{
                          color: "red",
                          marginLeft: "20px",
                          fontSize: "18px",
                        }}
                      >
                        Only the property manager can send a direct message
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/*Chat Box*/}
                <div id="messages" className="chatBox">
                  {messages.map((message, index) => {
                    if (
                      message.sender_id === selectedUser.id ||
                      message.recipient_id === selectedUser.id
                    ) {
                      if (
                        message.sender_id === JSON.parse(originalText)[0][1]
                      ) {
                        return (
                          <div className="message my_message">
                            <p>
                              <div
                                style={{
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {message.message}
                              </div>
                              <br />{" "}
                              <span className={"time"}>
                                {message.date_created}
                              </span>
                            </p>
                          </div>
                        );
                      } else {
                        return (
                          <div className="message frnd_message">
                            <p>
                              <div
                                style={{
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {message.message}
                              </div>
                              <br />{" "}
                              <span className={"time"}>
                                {message.date_created}
                              </span>
                            </p>
                          </div>
                        );
                      }
                    }
                  })}
                  {/*<div ref={divRef}></div>*/}
                </div>

                {/*Chat Input*/}
                <div
                  style={{
                    pointerEvents:
                      selectedUser.agency_id !== 0 &&
                      selectedUser.agency_id !== JSON.parse(originalText)[0][1]
                        ? "none"
                        : "",
                  }}
                  className="chatbox_input"
                >
                  <div
                    style={{
                      paddingLeft: "5px",
                      width: "100%",
                    }}
                  >
                    <TextField
                      id="filled-textarea"
                      value={message}
                      // label="Multiline Placeholder"
                      placeholder={"Type a message"}
                      onChange={(event) => {
                        setMessage(event.target.value);
                      }}
                      multiline
                      maxRows={1}
                      variant="outlined"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>

                  <SendOutlined
                    onClick={() => {
                      // let message = document.getElementById("message").value;
                      if (
                        message === null ||
                        message.toString().trim() === ""
                      ) {
                        props.snackBar({
                          text: "Cannot Send A Blank message",
                          type: "error",
                        });
                        return;
                      }
                      // document.getElementById("message").value = "";
                      onSubmit(message, selectedUser.id);
                      setMessage("");
                    }}
                    className={"ion-icon"}
                    style={{
                      marginRight: "15px",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
