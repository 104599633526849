import "./userList.css";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { TextField } from "@material-ui/core";
import { DeleteOutline } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowDropDown } from "@material-ui/icons";
import { ApiDetails, KEY } from "../../Constants";
import CryptoJS from "crypto-js";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//create your forceUpdate hook WHICH DID NOT WORK

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      fontSize: 16,
      color: "darkblue",
      backgroundColor: "rgb(245,245,245)",
    },
  },
});

export default function MailList(props) {
  let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
    localStorage.getItem("myMap"),
    KEY
  );
  let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

  const { state } = useLocation();
  let { mails } = state;

  //Navigator
  const navigate = useNavigate();

  //Modal states
  const [editModal, setEditModal] = useState(false);
  const [mailModal, setMailModal] = useState(false);

  //MAIL DETAILS
  let [mailDetails, setMailDetails] = React.useState({
    subject: "",
    sender: "",
    message: "",
  });

  const classes = useStyles();

  const refresh = () => {
    props.backrop("open");
    fetch(ApiDetails + "pegasus/visionary/mails/download", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
      }),
    })
      .then(async (response) => {
        if (response.status === 200) {
          props.snackBar({
            text: "Mails Retrieved Successfully.",
            type: "success",
          });
          props.backrop("");
        } else if (response.status === 401) {
          props.snackBar({
            text: "Email Password Authentication Failed",
            type: "error",
          });
          props.backrop("");
        }
      })
      .catch((err) => {
        props.backrop("");
        console.log(err);
      });
  };

  const [searchResults, setSearchResults] = useState(mails);

  const handleClose = () => {
    setEditModal(false);
    setMailModal(false);
  };
  const searchMails = (e) => {
    // console.log(new Map(Object.entries(mails[0])).get("subject"));
    // console.log(mails.filter((mail) => new Map(Object.entries(mail)).get("subject").toString()));

    setSearchResults(
      Array.from(mails).filter((item) => {
        if (!(new Map(Object.entries(item)).get("subject") === undefined)) {
          return new Map(Object.entries(item))
            .get("subject")
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        } else {
          return false;
        }
      })
    );
  };
  const columns = [
    {
      field: "sender",
      headerName: "Sender",
      headerAlign: "center",
      align: "left",
      width: 200,
      renderCell: (params) => {
        return (
          <>{new Map(Object.entries(params.row.from)).get("encodedPersonal")}</>
        );
      },
    },
    {
      field: "message",
      headerName: "Message",
      headerAlign: "center",
      align: "Left",
      width: 750,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                marginRight: "10px",
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              {params.row.subject}
            </div>
            <div
              style={{
                fontSize: "12px",
              }}
            >
              {params.row.message}
            </div>
          </>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "right",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            style={{
              fontSize: "10px",
            }}
          >
            {params.row.date}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      width: 50,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div
            className="userListUser"
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DeleteOutline
              style={{
                color: "red",
                cursor: "pointer",
                fontSize: "20px",
              }}
              onClick={() => {
                // console.log("Delete Mail ...");
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/*EDIT MODAL*/}
      <Dialog
        open={editModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>New Message </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography></Typography>
            <Typography>{/*tenant details*/}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <span style={{ color: "red" }}>Cancel</span>
          </Button>
          <Button onClick={handleClose}>
            <span style={{ color: "green" }}>Submit</span>
          </Button>
        </DialogActions>
      </Dialog>

      {/*VIEW MAIL MODAL*/}
      <Dialog
        open={mailModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            display: "flex",
          }}
        >
          {mailDetails.subject}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <ArrowDropDown />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography>
              <span
                style={{
                  marginRight: "10px",
                }}
              >
                {new Map(Object.entries(mailDetails.sender)).get(
                  "encodedPersonal"
                )}
              </span>
              {new Map(Object.entries(mailDetails.sender)).get("address")}{" "}
            </Typography>
            <br />
            <p>{mailDetails.message}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <span style={{ color: "red" }}>Cancel</span>
          </Button>
          <Button onClick={handleClose}>
            <span style={{ color: "green" }}>Submit</span>
          </Button>
        </DialogActions>
      </Dialog>

      <div className="userList">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              padding: "10px 10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Search Subjects ... "
              variant="outlined"
              name="firstname"
              onChange={(e) => {
                searchMails(e);
              }}
              style={{ paddingRight: "10px" }}
            />
            <Button
              onClick={(e) => {
                searchMails(e);
              }}
              variant="outlined"
            >
              Search
            </Button>
          </div>
          <Button
            onClick={(e) => {
              setEditModal(true);
            }}
            variant="outlined"
            style={{
              color: "white",
              text_transform: "uppercase",
              width: "200px",
              line_height: "50px",
              border_radius: "5px",
              background: "#00B000",
              text_align: "center",
              box_shadow: "inset 0 0 25px rgba(0,0,0,.25)",
              marginRight: "10px",
            }}
          >
            Compose
          </Button>
        </div>
        <div className={"userListDataGrid"}>
          <DataGrid
            className={classes.root}
            rows={searchResults}
            disableSelectionOnClick
            rowHeight={45}
            onRowClick={(params, event) => {
              mailDetails = {
                subject: params.row.subject,
                sender: params.row.from,
                message: params.row.message,
              };
              setMailDetails(mailDetails);
              setMailModal(true);
            }}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </div>
      </div>
    </>
  );
}
