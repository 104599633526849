import {ChonkyActions, FileBrowser, FileContextMenu, FileList, FileNavbar, FileToolbar,} from "chonky";
import FolderUpload from "../../components/Folder upload/FolderUpload";
import React, {useEffect, useState} from "react";
import {ApiDetails, KEY} from "../../Constants";
import Backdrop from "@mui/material/Backdrop";
import {BarWave} from "react-cssfx-loading";
import CryptoJS from "crypto-js";

export const MyFileBrowser = (props) => {
    const folderChain = [{id: "xcv", name: "Backup", isDir: true}];

    const [apiResponse, setApiResponse] = useState([]);

    const [folderViews, setFolderViews] = useState([]);

    // Map over the API response to create the Chonky files array
    const [chonkyFiles, setChonkyFiles] = useState([]);

    const [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            onClick={null}
        >
            <BarWave color="inherit"/>
        </Backdrop>
    );

    let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.getItem("myMap"),
        KEY
    );
    let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.portfolioDetails,
        KEY
    );
    let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);
    portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    const [landlordId,] = useState(JSON.parse(originalText)[0][1],);

    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        fetch(ApiDetails + "pegasus/visionary/files/retrieve/backup", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: landlordId.toString(),
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    //Get backup folder
                    setApiResponse(data.files);

                    setFolderViews(data.files);

                    setBackDrop(<></>);
                } else if (response.status === 404) {
                    setBackDrop(<></>);

                    props.snackBar({
                        text: "Backup not found.",
                        type: "error",
                    });
                } else {
                    setBackDrop(<></>);

                    props.snackBar({
                        text: "Failed fetching backup.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                setBackDrop(<></>);

                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    }, [refresh]);

    const [folderState, setFolderState] = useState({});

    const handleFolderOpen = (folderId) => {
        setFolderState((prev) => ({
            ...prev,
            [folderId]: !prev[folderId],
        }));
    };
    const getFolderState = (folderId) => folderState[folderId] || false;

    const myFileActions = [
        ChonkyActions.UploadFiles,
        ChonkyActions.DownloadFiles,
        ChonkyActions.DeleteFiles,
    ];

    // eslint-disable-next-line no-undef
    const handleAction = (data) => {
        let action = data.action.id;
        // console.log(action)

        let file = data.state.selectedFiles[0];

        if (file === undefined) {
            return;
        }

        let fileId = file.id;

        if (action === "open_files") {
            let currentFolder = apiResponse.filter((file) => {
                if (file.id === fileId) {
                    return file;
                }
            });
            if (currentFolder[0].isDir !== false)
                retrieveBackup(currentFolder[0].path);
        } else if (action === "delete_files") {
            let currentFolder = apiResponse.filter((file) => {
                if (file.id === fileId) {
                    return file;
                }
            });
            deleteFile(currentFolder[0].path);
        } else if (action === "download_files") {
            let currentFolder = apiResponse.filter((file) => {
                if (file.id === fileId) {
                    return file;
                }
            });
            downloadFile(currentFolder[0].path);
        }
    };

    const retrieveBackup = (path) => {
        console.log(path);

        setBackDrop(
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
                onClick={null}
            >
                <BarWave color="inherit"/>
            </Backdrop>
        );

        fetch(ApiDetails + "pegasus/visionary/files/retrieve/backup", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: landlordId,
                path: path,
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    //Get backup folder
                    setApiResponse(data.files);

                    setFolderViews(data.files);

                    setBackDrop(<></>);
                } else {
                    props.snackBar({
                        text: "Failed fetching backup.",
                        type: "error",
                    });
                    setBackDrop(<></>);
                }
            })
            .catch((err) => {
                setBackDrop(<></>);

                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };

    const deleteFile = (path) => {
        console.log(path);

        setBackDrop(
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
                onClick={null}
            >
                <BarWave color="inherit"/>
            </Backdrop>
        );

        fetch(ApiDetails + "pegasus/visionary/files/delete/file", {
            method: "POST",
            body: JSON.stringify({
                path: path,
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    //Get backup folder
                    props.snackBar({
                        text: "File deleted successfully",
                        type: "success",
                    });
                    setRefresh(!refresh);
                } else {
                    props.snackBar({
                        text: "Failed fetching backup.",
                        type: "error",
                    });
                    setBackDrop(<></>);
                }
            })
            .catch((err) => {
                setBackDrop(<></>);

                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };

    const downloadFile = (path) => {
        setBackDrop(
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
                onClick={null}
            >
                <BarWave color="inherit"/>
            </Backdrop>
        );

        let fileName;

        fetch(ApiDetails + "pegasus/visionary/files/download/file", {
            method: "POST",
            body: JSON.stringify({
                path: path,
            }),
        })
            .then(async (res) => {
                if (res.status === 200) {
                    //Get backup folder
                    for (let pair of res.headers.entries()) {
                        // accessing the entries
                        if (pair[0] === "content-disposition") {
                            // key I'm looking for in this instance
                            fileName = pair[1]; // saving that value where I can use it
                        }
                    }

                    props.snackBar({
                        text: "File Download Has Started",
                        type: "success",
                    });

                    let blob = await res.blob();

                    let href = window.URL.createObjectURL(blob);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    setRefresh(!refresh);
                } else {
                    props.snackBar({
                        text: "Failed downloading file.",
                        type: "error",
                    });
                    setBackDrop(<></>);
                }
            })
            .catch((err) => {
                setBackDrop(<></>);

                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };

    return (
        <div
            style={{
                padding: "20px",
                height: "calc(100vh - 110px)",
                overflow: "auto",
            }}
        >
            {backDrop}
            <FolderUpload
                refresh={() => {
                    setRefresh(!refresh);
                }}
                snackBar={props.snackBar}
                topBar={props.topBar}
            />

            <div
                style={{
                    height: "65%",
                }}
            >
                <FileBrowser
                    files={folderViews}
                    folderChain={folderChain}
                    onFolderOpen={handleFolderOpen}
                    getFolderIsOpen={getFolderState}
                    fileActions={myFileActions}
                    onFileAction={handleAction}
                >
                    <FileNavbar/>
                    <FileToolbar/>
                    <FileList/>
                    <FileContextMenu/>
                </FileBrowser>
            </div>
        </div>
    );
};
