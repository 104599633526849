import React, {useEffect, useRef, useState} from "react";
import "../settings.css";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {TextField} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import {ContentCopy, DeleteOutline, Refresh, UploadOutlined,} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import {advancedSchema} from "../../../components/schemas/Schemas";
import {Avatar} from "@mui/material";
import {ApiDetails, KEY} from "../../../Constants";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import CryptoJS from "crypto-js";
import Tooltip from "@mui/material/Tooltip";
import {confirmAlert} from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AccountDetails(props) {
    let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.getItem("myMap"),
        KEY
    );
    let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    // console.log(JSON.parse(originalText))

    const [agency_id, setAgency_id] = useState(JSON.parse(originalText));

    useEffect(() => {
        try {
            setAgency_id(JSON.parse(originalText)[20][1])
        } catch (e) {
            setAgency_id(JSON.parse(originalText)[21][1])

        }
    }, []);

    const [loadingUpload, setLoadingUpload] = React.useState(false);
    const [loadingRemove, setLoadingRemove] = React.useState(false);
    const [loadingFormSubmit, setLoadingFormSubmit] = React.useState(false);
    const [loadingDeleteAccount, setLoadingDeleteAccount] = React.useState(false);
    const [loadingGenerateToken, setLoadingGenerateToken] = React.useState(false);
    const [landlordPhoto, setLandlordPhoto] = React.useState("");
    const [landlordImage, setLandlordImage] = React.useState("");
    const [fileImage, setFileImage] = React.useState("");
    const [deleteModal, setOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [emailAddress, setEmailAddress] = useState("");

    const [landlordDetails] = useState(JSON.parse(originalText));

    useEffect(() => {
        let pic;
        fetch(ApiDetails + "pegasus/visionary/authorization/photo", {
            method: "POST",
            body: JSON.stringify({
                email_address: JSON.parse(originalText)[5][1],
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    pic = data.landlord_photo;
                    setLandlordImage(pic);

                    if (pic.base64 !== "No File Found" && pic.base64.toString().trim() !== "") {
                        setLandlordImage("data:image/jpeg;base64," + pic.base64);
                    }
                    // console.log(pic);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleClose = () => {
        setOpen(false);
    };
    const deleteAccount = () => {
        setLoadingDeleteAccount(true);

        if (password.trim() === "") {
            props.snackBar({
                text: "Password can not be empty.",
                type: "error",
            });
            return;
        }
        if (emailAddress.trim() === "") {
            props.snackBar({
                text: "Email address can not be empty.",
                type: "error",
            });
            return;
        }

        // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/authorization/delete/account", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: JSON.parse(originalText)[0][1],
                password: password,
                email_address: emailAddress,
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    props.snackBar({
                        text: "Account Deleted Successfully.",
                        type: "success",
                    });
                    setLoadingDeleteAccount(false);
                    window.location.href = "/";
                    localStorage.clear();
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Incorrect Credentials Provided.",
                        type: "error",
                    });
                    setLoadingDeleteAccount(false);
                } else {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                    setLoadingDeleteAccount(false);
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
                setLoadingDeleteAccount(false);
            });
    };
    const initialValues = {
        first_name: landlordDetails[1][1],
        last_name: landlordDetails[2][1],
        email_address: landlordDetails[5][1],
        phone_number: landlordDetails[3][1],
    };
    const updateDetails = (values) => {
        setLoadingFormSubmit(true);
        let formData = new FormData();

        if (fileImage === "") {
            formData.append(
                "details",
                JSON.stringify({
                    first_name: values.first_name,
                    last_name: values.last_name,
                    phone_number: values.phone_number,
                    email_address: values.email_address,
                    landlord_id: landlordDetails[0][1],
                    inventory_checklist_template: "",
                    tenant_contract_template: "",
                    caretaker_contract_template: "",
                    landlord_photo: fileImage,
                })
            );
        } else {
            formData.append(
                "details",
                JSON.stringify({
                    first_name: values.first_name,
                    last_name: values.last_name,
                    phone_number: values.phone_number,
                    email_address: values.email_address,
                    landlord_id: landlordDetails[0][1],
                    inventory_checklist_template: "",
                    tenant_contract_template: "",
                    caretaker_contract_template: "",
                })
            );
            formData.append("landlord_photo", fileImage);
        }

        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/authorization/settings/update", {
            method: "POST",
            body: formData,
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    getUserDetails({
                        landlord_id: JSON.parse(originalText)[0][1],
                    });

                    props.snackBar({
                        text: "Details Updated Successful",
                        type: "success",
                    });
                    props.refresh(true);
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                } else if (response.status === 500) {
                    console.log(data);
                    if (data["Message"].includes("duplicate key")) {
                        if (data["Message"].includes("owners_email_address_uindex")) {
                            props.snackBar({
                                text: "Email Address Already Exists",
                                type: "error",
                            });
                            setLoadingFormSubmit(false);
                            return;
                        }
                    }
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
                setLoadingFormSubmit(false);
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
                setLoadingFormSubmit(true);
            });
        setLoadingFormSubmit(true);
    };

    const ref = useRef();
    const handleUpload = (e) => {
        ref.current.click();
    };

    function uploadProfileImage(e) {
        setLoadingUpload(true);
        let filename = getFileName(e);
        if (checkImageFile(filename)) {
            getBase64(e.target.files[0]);
            setFileImage(e.target.files[0]);
            setLandlordImage(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload An Image File",
                type: "warning",
            });
        }
        setLoadingUpload(false);
    }

    function getBase64(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setLandlordPhoto(reader.result);
            // console.log(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }

    function getExtension(filename) {
        let parts = filename.split(".");
        return parts[parts.length - 1];
    }

    const getFileName = (e) => {
        let startIndex =
            e.target.value.indexOf("\\") >= 0
                ? e.target.value.lastIndexOf("\\")
                : e.target.value.lastIndexOf("/");
        let filename = e.target.value.substring(startIndex);
        if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
            filename = filename.substring(1);
        }
        return filename;
    };

    function checkImageFile(filename) {
        let ext = getExtension(filename);
        // eslint-disable-next-line default-case
        switch (ext.toLowerCase()) {
            case "jpg":
            case "png":
            case "jpeg":
                //etc
                return true;
        }
        return false;
    }

    const getUserDetails = (userDetails) => {
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/dashboard/get/landlord/details", {
            method: "POST",
            body: JSON.stringify(userDetails),
        })
            .then(async (response) => {
                // console.log(userDetails);
                let data = await response.json();
                if (response.status === 200) {
                    const details = new Map(Object.entries(data));
                    let myMap = CryptoJS.AES.encrypt(
                        JSON.stringify(Array.from(details.entries())),
                        KEY
                    ).toString();

                    localStorage.setItem("myMap", myMap);
                    setAgency_id(details.get("agency_id"));
                    props.refresh(true);
                    props.snackBar({
                        text: "Details Updated Successful",
                        type: "success",
                    });
                } else {
                    props.snackBar({text: "Failed Fetching Details", type: "error"});
                }
            })
            .catch((err) => console.log(err));
    };

    const confirmAlertPopup = (copyText) => {
        confirmAlert({
            title: `Copy`,
            message: `Are you sure you want to copy ${agency_id}.`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => navigator.clipboard.writeText(copyText),
                },
                {
                    label: "No",
                    onClick: () => {
                    },
                },
            ],
        });
    };

    return (
        <div>
            <Dialog
                open={deleteModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>Delete Account</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography>
                            Deleting your account will completely remove all your properties
                            and tenants together with all their related data. Kindly note that
                            this process is irreversible
                        </Typography>
                        <br/>
                        <Typography>Enter password to confirm account deletion.</Typography>
                        <div>
                            <TextField
                                type={"email"}
                                label="Email Address"
                                variant="outlined"
                                fullwidth
                                required
                                autoComplete={"new-password"}
                                style={{marginTop: "10px", width: "100%"}}
                                onChange={(event) => {
                                    setEmailAddress(event.target.value);
                                }}
                            />
                            <TextField
                                type={"password"}
                                label="Password"
                                variant="outlined"
                                fullwidth
                                required
                                autoComplete={"new-password"}
                                style={{marginTop: "10px", width: "100%"}}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                            />
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "green"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={deleteAccount}
                        loading={loadingDeleteAccount}
                        startIcon={<DeleteOutline/>}
                        style={{color: "red"}}
                        loadingPosition="start"
                    >
                        Delete Account
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <h2
                style={{
                    fontWeight: "bold",
                    color: "#398DD2",
                }}
            >
                Account Details
            </h2>
            <div
                style={{
                    display: "flex",
                    margin: "20px 0",
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",
                    width: "80%",
                }}
            >
                <img
                    style={{
                        display: "none",
                        position: "absolute",
                        zIndex: "9999",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundColor: "black",
                    }}
                    src={`${landlordImage}`}
                />
                {landlordPhoto === "" ? (
                    <Avatar
                        style={{
                            width: "100px",
                            height: "100px",
                        }}
                        alt=""
                        src={`${landlordImage}`}
                    />
                ) : (
                    <Avatar
                        style={{
                            width: "100px",
                            height: "100px",
                        }}
                        alt=""
                        src={`${landlordPhoto}`}
                    />
                )}
                <input
                    style={{
                        display: "none",
                    }}
                    ref={ref}
                    onChange={(e) => {
                        uploadProfileImage(e);
                    }}
                    type="file"
                    accept="image/*"
                />

                <LoadingButton
                    size="small"
                    loading={loadingUpload}
                    onClick={handleUpload}
                    startIcon={<UploadOutlined/>}
                    style={{color: "#398DD2"}}
                    loadingPosition="start"
                >
                    Upload
                </LoadingButton>

                <LoadingButton
                    size="small"
                    loading={loadingRemove}
                    onClick={() => {
                        setFileImage("");
                        setLandlordImage("");
                        setLandlordPhoto("");
                        setLoadingRemove(true);
                        setLoadingRemove(false);
                    }}
                    startIcon={<DeleteOutline/>}
                    style={{color: "red"}}
                    loadingPosition="start"
                >
                    Remove
                </LoadingButton>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={advancedSchema}
                onSubmit={updateDetails}
            >
                {(props) => (
                    <Form noValidate>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Field
                                as={TextField}
                                name="first_name"
                                label="First Name"
                                variant="outlined"
                                error={props.errors.first_name && props.touched.first_name}
                                helperText={<ErrorMessage name="first_name"/>}
                                required
                                style={{marginTop: "10px", width: "49%"}}
                            />
                            <Field
                                as={TextField}
                                name="last_name"
                                label="Last Name"
                                error={props.errors.last_name && props.touched.last_name}
                                helperText={<ErrorMessage name="last_name"/>}
                                required
                                variant="outlined"
                                style={{marginTop: "10px", width: "49%"}}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Field
                                as={TextField}
                                name="email_address"
                                label="Email"
                                disabled={true}
                                error={
                                    props.errors.email_address && props.touched.email_address
                                }
                                helperText={<ErrorMessage name="email_address"/>}
                                required
                                variant="outlined"
                                style={{marginTop: "10px", width: "49%"}}
                            />
                            <Field
                                as={TextField}
                                name="phone_number"
                                label="Phone Number"
                                error={props.errors.phone_number && props.touched.phone_number}
                                helperText={<ErrorMessage name="phone_number"/>}
                                required
                                variant="outlined"
                                style={{marginTop: "10px", width: "49%"}}
                            />
                        </div>

                        <br/>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "end",
                                marginTop: "20px",
                            }}
                        >
                            <LoadingButton
                                size="small"
                                type="submit"
                                loading={loadingFormSubmit}
                                startIcon={<SendIcon/>}
                                style={{color: "green"}}
                                loadingPosition="start"
                            >
                                Submit
                            </LoadingButton>
                        </div>
                    </Form>
                )}
            </Formik>

            <hr
                style={{
                    margin: "10px 0 10px 0",
                }}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                }}
            >
                <div>
          <span
              style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#398DD2",
              }}
          >
            Agency token
          </span>
                    <br/>
                    <Typography
                        style={{
                            color: "#959EAD",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <TextField
                            style={{
                                width: "100%",
                                marginTop: "15px",
                            }}
                            id="outlined-basic"
                            label="Unique token"
                            value={agency_id}
                            variant="outlined"
                        />

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "center",
                                marginLeft: "10px",
                            }}
                        >
                            <Tooltip title="Copy token">
                                <ContentCopy
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        let copyText = agency_id;
                                        navigator.clipboard.writeText(copyText);
                                        confirmAlertPopup(copyText);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </Typography>
                </div>
                <LoadingButton
                    size="small"
                    loading={loadingGenerateToken}
                    onClick={() => {
                        setLoadingGenerateToken(true);

                        let body = {
                            id: JSON.parse(originalText)[21][1],
                            landlord_id: JSON.parse(originalText)[0][1],
                        };
                        fetch(
                            ApiDetails +
                            "pegasus/visionary/authorization/generate_agency_token",
                            {
                                method: "POST",
                                body: JSON.stringify(body),
                            }
                        )
                            .then(async (response) => {
                                if (response.status === 200) {
                                    setLoadingGenerateToken(false);
                                    getUserDetails({
                                        landlord_id: JSON.parse(originalText)[0][1],
                                    });
                                } else if (response.status === 401) {
                                    setLoadingGenerateToken(false);
                                    props.snackBar({
                                        text: "Function is only available to agencies",
                                        type: "error",
                                    });
                                } else {
                                    setLoadingGenerateToken(false);

                                    props.snackBar({
                                        text: "Could not generate token",
                                        type: "error",
                                    });
                                }
                            })
                            .catch((err) => {
                                setLoadingGenerateToken(false);

                                console.log(err);
                                props.snackBar({
                                    text: "Something Went Wrong. Try Again Later",
                                    type: "error",
                                });
                            });
                    }}
                    startIcon={<Refresh/>}
                    style={{color: "#398DD2"}}
                    loadingPosition="start"
                >
                    Generate token
                </LoadingButton>
            </div>
            <hr
                style={{
                    margin: "10px 0 10px 0",
                }}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                }}
            >
                <div>
          <span
              style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "red",
              }}
          >
            Delete Account
          </span>
                    <br/>
                    <Typography
                        style={{
                            color: "#959EAD",
                        }}
                    >
                        By Deleting your account you will loose all data
                    </Typography>
                </div>
                <LoadingButton
                    size="small"
                    loading={loadingDeleteAccount}
                    onClick={() => {
                        setOpen(true);
                    }}
                    startIcon={<DeleteOutline/>}
                    style={{color: "red"}}
                    loadingPosition="start"
                >
                    Delete Account
                </LoadingButton>
            </div>
            <br/>
        </div>
    )
        ;
}
