import React, {useEffect, useState} from "react";
import "./settings.css";
import {AccountCircleOutlined, LockOutlined,} from "@material-ui/icons";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AccountDetails from "./settingsTabs/AccountDetails";
import Security from "./settingsTabs/Security";

export default function Settings(props) {
    const [activePage, setActivePage] = useState(
        document.getElementsByClassName("sidebarListItemSettings").item(0)
    );
    
        useEffect(() => {
            props.refreshSideBar();
            let activeHtml = document
                .getElementsByClassName("sidebarListItemSettings")
                .item(0);
            setActivePage(
                document.getElementsByClassName("sidebarListItemSettings").item(0)
            );
            activeHtml.className = "sidebarListItemSettings active";
            window.addEventListener("load", function (event) {
                // your code ....
                setActivePage(
                    document.getElementsByClassName("sidebarListItemSettings").item(0)
                );
            });
        }, []);
    //Loading Screen
    const [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            onClick={null}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );

    const handleClick = (event) => {
        //Remove Previous Active Page ClassName
        activePage.className = "sidebarListItemSettings";

        //Set New Active Page
        setActivePage(event.target);

        //Set New Active Page ClassName
        event.target.className = "sidebarListItemSettings active";
    };

    const [page, setPage] = useState(
        <AccountDetails
            refresh={(value) => {
                props.refresh(value);
            }}
            snackBar={props.snackBar}
        />
    );
    return (
        <div
            className="settings"
            style={{
                height: "calc(100vh - 100px)",
                padding: "20px",
                display: "flex",
            }}
        >
            <div
                className="left"
                style={{
                    flex: "20%",
                    padding: "20px",
                }}
            >
                <h2
                    style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                    }}
                >
                    Settings
                </h2>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        onClick={(event) => {
                            setPage(<AccountDetails snackBar={props.snackBar}/>);
                            handleClick(event);
                        }}
                        className="sidebarListItemSettings"
                    >
                        <AccountCircleOutlined className="sidebarIconSettings"/>
                        <div className={"sidebarPageNameSettings"}>Account Details</div>
                    </div>
                    <div
                        onClick={(event) => {
                            setPage(<Security snackBar={props.snackBar}/>);
                            handleClick(event);
                        }}
                        className="sidebarListItemSettings"
                    >
                        <LockOutlined className="sidebarIconSettings"/>
                        <div className={"sidebarPageNameSettings"}>Security</div>
                    </div>
                </div>
            </div>
            <div
                className="right"
                style={{
                    flex: "80%",
                    padding: "20px",
                    WebkitBoxShadow: "5px 5px 9px 2px rgba(0,0,0,0.2)",
                    boxShadow: "5px 5px 9px 2px rgba(0,0,0,0.2)",
                }}
            >
                {page}
            </div>
        </div>
    );
}
