import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { useNavigate } from "react-router-dom";
import "./authentication.css";
import { ApiDetails, KEY, SITE_KEY } from "../../Constants";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import { basicSchema } from "../schemas/Schemas";
import CryptoJS from "crypto-js";
import logo from "../../assets/react-logo-no-background.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { Login } from "@mui/icons-material";
import { TextField } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import { Autocomplete } from "@mui/material";

export default function SignupPage(props) {
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<VisibilityOutlined />);
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const [backdrop, setBackdrop] = React.useState(<></>);

  const [userTypes, setUserTypes] = React.useState([]);
  const [selectedUserType, setSelectedUserType] = React.useState();

  useEffect(() => {
    //Get user types
    fetch(ApiDetails + "pegasus/visionary/extras/user_types", {
      method: "GET",
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.status === 200) {
          setUserTypes(data);
        } else {
          props.snackBar({
            text: "Invalid Credentials Provided.",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Try Again Later",
          type: "error",
        });
      });
  }, []);

  const onSubmit = (values) => {
    // event.preventDefault();
    setLoading(true);
    if (selectedUserType === undefined || selectedUserType === null) {
      props.snackBar({
        text: "Kindly select user type",
        type: "error",
      });
      setLoading(false);
      return;
    }
    let body = {
      first_name: values.signupFirstName,
      last_name: values.signupLastName,
      email_address: values.signupEmail,
      password: values.signupPassword,
      phone_number: values.phoneNumber,
    };

    body = { ...body, user_type: selectedUserType.id };

    fetch(ApiDetails + "pegasus/visionary/authorization/createAccount", {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.status === 200) {
          setBackdrop(<></>);
          props.snackBar({ text: "Signup Successful", type: "success" });
          const map1 = new Map(Object.entries(data));

          let myMap = CryptoJS.AES.encrypt(
            JSON.stringify(Array.from(map1.entries())),
            KEY
          ).toString();

          localStorage.setItem("myMap", myMap);
          localStorage.setItem("isNew", data.new);

          handleClose();
          setLoading(false);

          navigate("/home", {
            state: {
              userDetails: data,
            },
          });
        } else if (response.status === 400) {
          setBackdrop(<></>);
          props.snackBar({
            text: "User with provided email address already exists.",
            type: "error",
          });
          setLoading(false);
        } else {
          setBackdrop(<></>);
          props.snackBar({
            text: "Signup Failed.",
            type: "error",
          });
          setLoading(false);
        }
      })
      .then((json) => console.log(json))
      .catch((err) => {
        setLoading(false);
        props.snackBar({
          text: "Something went wrong. Try again later.",
          type: "error",
        });
        console.log(err);
      });
  };

  const initialValues = {
    signupFirstName: "",
    signupLastName: "",
    signupEmail: "",
    signupPassword: "",
    signupConfirmPassword: "",
    phoneNumber: "254",
  };

  return (
    <div className={"fullPage"}>
      <div
        style={{
          height: "80vh",
          width: "80vw",
        }}
      >
        <div className="register">
          <div className="col-1-auth">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "250px",
                  height: "150px",
                }}
                src={logo}
                alt=""
              />
              <div>
                <h2>Sign Up</h2>
                <span>register and enjoy the service</span>
              </div>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={basicSchema}
              onSubmit={onSubmit}
            >
              {(props) => (
                <Form noValidate>
                  <Field
                    as={TextField}
                    type={"text"}
                    name="signupFirstName"
                    label="First name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={
                      props.errors.signupFirstName &&
                      props.touched.signupFirstName
                    }
                    helperText={<ErrorMessage name="signupFirstName" />}
                    required
                    style={{ width: "100%", marginTop: "10px" }}
                  />
                  <Field
                    as={TextField}
                    type={"text"}
                    name="signupLastName"
                    label="Last name "
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={
                      props.errors.signupLastName &&
                      props.touched.signupLastName
                    }
                    helperText={<ErrorMessage name="signupLastName" />}
                    required
                    style={{ width: "100%", marginTop: "10px" }}
                  />
                  <Field
                    as={TextField}
                    type={"number"}
                    name="phoneNumber"
                    label="Phone number"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={
                      props.errors.phoneNumber && props.touched.phoneNumber
                    }
                    helperText={<ErrorMessage name="phoneNumber" />}
                    required
                    style={{ width: "100%", marginTop: "10px" }}
                  />
                  <Field
                    as={TextField}
                    type={"email"}
                    name="signupEmail"
                    label="Email address"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={
                      props.errors.signupEmail && props.touched.signupEmail
                    }
                    helperText={<ErrorMessage name="signupEmail" />}
                    required
                    style={{ width: "100%", marginTop: "10px" }}
                  />
                  <Field
                    as={TextField}
                    type={type}
                    name="signupPassword"
                    label="Password"
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      props.errors.signupPassword &&
                      props.touched.signupPassword
                    }
                    helperText={<ErrorMessage name="signupPassword" />}
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          onClick={() => {
                            if (type === "password") {
                              setType("text");
                              setIcon(<VisibilityOffOutlined />);
                            } else {
                              setType("password");
                              setIcon(<VisibilityOutlined />);
                            }
                          }}
                          position="end"
                        >
                          {icon}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    as={TextField}
                    type={type}
                    name="signupConfirmPassword"
                    label="Confirm password"
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      props.errors.signupConfirmPassword &&
                      props.touched.signupConfirmPassword
                    }
                    helperText={<ErrorMessage name="signupConfirmPassword" />}
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          onClick={() => {
                            if (type === "password") {
                              setType("text");
                              setIcon(<VisibilityOffOutlined />);
                            } else {
                              setType("password");
                              setIcon(<VisibilityOutlined />);
                            }
                          }}
                          position="end"
                        >
                          {icon}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={userTypes.map((type, index) => {
                      return type.description;
                    })}
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={(e, value) => {
                      setSelectedUserType(
                        userTypes.filter((type) => {
                          if (type.description === value) {
                            return type.id;
                          }
                        })[0]
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="outlined-basic"
                        label="Account type"
                        variant="outlined"
                        name="property"
                        {...params}
                      />
                    )}
                  />

                  {/*{captcha}*/}
                  <LoadingButton
                    type={"submit"}
                    endIcon={<Login />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Signup
                  </LoadingButton>
                  <br />

                  <span>
                    Don't have an account? <a href="/">Login </a>
                  </span>
                </Form>
              )}
            </Formik>
          </div>
          <div className="col-2-auth">
            <img src={require("../../assets/img1.jpg")} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
