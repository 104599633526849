import React, {useEffect, useState} from "react";
import "./topbar.css";
import {
    NotificationsNone,
    Settings,
    Logout,
} from "@mui/icons-material";
import {Avatar, Menu, MenuItem, useMediaQuery} from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Link} from "react-router-dom";
import "react-internet-meter/dist/index.css";
import CryptoJS from "crypto-js";
import {ApiDetails, KEY, SKY_AUTH_ApiDetails} from "../../Constants";
import {fetchEventSource} from "@microsoft/fetch-event-source";

export default function Topbar(props) {
    const [forceRefresh, setForceRefresh] = useState(false);
    const matches = useMediaQuery("(max-width:700px)");


    const [isAgent, setIsAgent] = useState(false);

    useEffect(() => {
        try {
            let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
                localStorage.getItem("myMap"),
                KEY
            );
            let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

            setInitialName(
                JSON.parse(originalText)[1][1] + " " + JSON.parse(originalText)[2][1]
            );
            setName(
                JSON.parse(originalText)[1][1] + " " + JSON.parse(originalText)[2][1]
            );
            // let pic = JSON.parse(originalText)[16][1];
            let pic;

            fetch(ApiDetails + "pegasus/visionary/authorization/photo", {
                method: "POST",
                body: JSON.stringify({
                    email_address: JSON.parse(originalText)[5][1],
                }),
            })
                .then(async (response) => {
                    let data = await response.json();
                    if (response.status === 200) {
                        pic = data.landlord_photo;
                        setLandlordImage(pic);

                        if (pic.base64 !== "No File Found") {
                            setLandlordImage("data:image/jpeg;base64," + pic.base64);
                        }
                        // console.log(pic);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            let AGENT_ID;

            //Get user types
            fetch(ApiDetails + "pegasus/visionary/extras/user_types", {
                method: "GET",
            })
                .then(async (response) => {
                    let data = await response.json();
                    // console.log("User types : " + data);
                    if (response.status === 200) {
                        // console.log("Found user types");

                        AGENT_ID = (new Map(Object.entries(data.filter((userType) => {
                            let type = new Map(Object.entries(userType))
                            if (type.get("description") === 'AGENT') {
                                return true
                            }
                        })[0])).get("id"));

                        if (JSON.parse(originalText)[21][1] === AGENT_ID) {
                            setIsAgent(true)
                        }
                    } else {
                        // props.snackBar({
                        //   text: "Invalid Credentials Provided.",
                        //   type: "error",
                        // });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                });

        } catch (e) {
            // console.log(e);
        }
    }, [forceRefresh, matches]);

    const [landlordImage, setLandlordImage] = useState("");
    const [initialName, setInitialName] = useState("new user");

    const [name, setName] = React.useState(initialName);
    const [wifiSpeed, setWifiSpeed] = React.useState();

    const getInitials = function (string) {
        let names = string.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = (event) => {
        // 👇️ toggle class on click
        window.location.href = "/";
        localStorage.clear();
    };

    return {
        setForceRefresh,
        render: (
            <div className="topbar">
                <div className="topbarWrapper">
                    <div className="topLeft">
                        <div>
              <span
                  style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "white",
                  }}
              >
                Welcome, {name.toUpperCase()}
              </span>
                        </div>
                    </div>
                    <div className="topRight">
                        <div
                            className="topbarIconContainer"
                            style={{
                                display: "none",
                            }}
                        >
                            <NotificationsNone style={{color: "white", fontSize: "25px"}}/>
                            {/*<span className="topIconBadge">{notificationsNumber}</span>*/}
                        </div>

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    aria-controls={open ? "account-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                >
                                    {landlordImage === "" ? (
                                        <Avatar sx={{width: 45, height: 45}}>
                                            {getInitials(name)}
                                        </Avatar>
                                    ) : (
                                        <Avatar
                                            style={{
                                                width: 45,
                                                height: 45,
                                            }}
                                            alt=""
                                            src={`${landlordImage}`}
                                        />
                                    )}
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Menu
                            anchorEl={anchorEl}
                            // id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{horizontal: "right", vertical: "top"}}
                            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                        >
                            {isAgent ? (<MenuItem>
                                <Link
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    className="linkItem"
                                    to={"/userManagement"}
                                >
                                    <ListItemIcon
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        <Avatar
                                            style={{
                                                marginRight: "10px",
                                            }}
                                        />
                                    </ListItemIcon>
                                    User management
                                </Link>
                            </MenuItem>) : (
                                <MenuItem
                                    style={{
                                        display: "none"
                                    }}
                                >

                                    <ListItemIcon
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        <Avatar
                                            style={{
                                                marginRight: "10px",
                                            }}
                                        />
                                    </ListItemIcon>
                                    User management

                                </MenuItem>
                            )}
                            <MenuItem>
                                <Link
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    className="linkItem"
                                    to={"/settings"}
                                >
                                    <ListItemIcon
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        <Avatar
                                            style={{
                                                marginRight: "10px",
                                            }}
                                        />
                                    </ListItemIcon>
                                    My account
                                </Link>
                            </MenuItem>
                            <Divider/>
                            <MenuItem>
                                <Link className="linkItem" to={"/settings"}>
                                    <ListItemIcon
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        <Settings fontSize="small"/>
                                    </ListItemIcon>
                                    Settings
                                </Link>
                            </MenuItem>

                            <MenuItem>
                                {/*<Link onClick={logout} className="linkItem" to={"/"}>*/}
                                <div onClick={logout}>
                                    <ListItemIcon
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        <Logout fontSize="small"/>
                                    </ListItemIcon>
                                    Logout
                                </div>
                                {/*</Link>*/}
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        ),
    };
}
