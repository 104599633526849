import * as yup from "yup";

import "yup-phone";
import * as Yup from "yup";
// require("yup-phone");

// validate any phone number (defaults to India for country)
const phoneSchema = yup.string().phone().required();

// const phoneRegExp = /^[2-9]{2}[0-9]{8}/;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const basicSchema = yup.object().shape({
  signupEmail: yup
    .string()
    .email("Please enter a valid email")
    .required("Required"),
  signupFirstName: yup
    .string()
    .min(3, "Please enter a valid name")
    .required("Required"),
  signupLastName: yup
    .string()
    .min(3, "Please enter a valid name")
    .required("Required"),
  signupPassword: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: "Please create a stronger password" })
    .required("Required"),
  signupConfirmPassword: yup
    .string()
    .oneOf([yup.ref("signupPassword"), null], "Passwords must match")
    .required("Required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number has to start with 254")
    .min(12)
    .required("Required"),
});

export const advancedSchema = yup.object().shape({
  first_name: yup
    .string()
    .min(3, "Username must be at least 3 characters long")
    .required("Required"),
  email_address: yup
    .string()
    .email("Please enter a valid email")
    .required("Required"),
  last_name: yup
    .string()
    .min(3, "Username must be at least 3 characters long")
    .required("Required"),
  phone_number: yup.string().phone("KE", true).required("Required"),
});
export const updatePasswordSchema = yup.object().shape({
  old_password: yup.string().required("Required"),
  new_password: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: "Please create a stronger password" })
    .required("Required"),
  confirm_new_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match")
    .required("Required"),
});

export const updatePropertySchema = yup.object().shape({
  property_name: yup
    .string()
    .min(3, "Name must be at least 3 characters long")
    .required("Required"),
  location: yup
    .string()
    .min(3, "Location must be at least 3 characters long")
    .required("Required"),
  payment_details: yup
    .string()
    .min(3, "Location must be at least 3 characters long")
    .required("Required"),
  water_bill: yup.number().required("Required"),
  electricity_bill: yup.number().required("Required"),
  minimum_water_bill: yup.number().required("Required"),
  minimum_electricity_bill: yup.number().required("Required"),
  other_charges: yup.number().required("Required"),
  query_number: yup.string().required("Required"),
  minimum_days_to_vacate: yup.number().required("Required"),
});

export const updatePropertyPaymentDetailsSchema = yup.object().shape({
  ho_number: yup
      .string()
      .min(5, "Must be at least 5 characters long")
      .required("Required"),
  till_number: yup
      .string()
      .min(5, "Must be at least 5 characters long")
      .required("Required"),
  short_code: yup
      .string()
      .min(3, "Must be at least 5 characters long")
      .required("Required"),
  pass_key: yup.string().required("Required"),
  consumer_secret: yup.string().required("Required"),
});

export const tenantDetailsValidationSchema = Yup.object().shape({
  first_name: Yup.string()
      .required('First Name is required'),
  last_name: Yup.string()
      .required('Last Name is required'),
  phone_number: Yup.string()
      .required('Phone Number is required')
      .matches(/^[0-9]+$/, 'Phone Number must be a number'),
  secondary_phone_number: Yup.string()
      .matches(/^[0-9]*$/, 'Secondary Phone Number must be a number'),
  national_id: Yup.number()
      .required('National ID is required')
      .typeError('National ID must be a number'),
  institution_name: Yup.string()
      .when('is_student', {
        is: 'YES',
        then: Yup.string().required('Institution Name is required'),
        otherwise: Yup.string().notRequired()
      }),
  occupation_or_profession: Yup.string()
      .required('Occupation is required'),
  place_of_work: Yup.string()
      .required('Place of Work is required'),
  reason_for_relocating: Yup.string()
      .required('Reason for Relocating is required'),
  previous_residence: Yup.string()
      .required('Previous Residence is required'),
  rent: Yup.number()
      .required('Rent is required')
      .typeError('Rent must be a number'),
  deposit_held: Yup.number()
      .required('Refundable Deposit is required')
      .typeError('Refundable Deposit must be a number'),
  next_of_kin_first_name: Yup.string()
      .required('Next of Kin First Name is required'),
  next_of_kin_last_name: Yup.string()
      .required('Next of Kin Last Name is required'),
  next_of_kin_relationship: Yup.string()
      .required('Next of Kin Relationship is required'),
  next_of_kin_contacts: Yup.string()
      .required('Next of Kin Phone Number is required')
      .matches(/^[0-9]+$/, 'Next of Kin Phone Number must be a number'),
  next_of_kin_national_id: Yup.number()
      .required('Next of Kin National ID is required')
      .typeError('Next of Kin National ID must be a number'),
});

/*  jobType: yup
    .string()
    .oneOf(["designer", "developer", "manager", "other"], "Invalid Job Type")
    .required("Required"),
  acceptedTos: yup
    .boolean()
    .oneOf([true], "Please accept the terms of service"),*/
