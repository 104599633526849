import "./analytics.css";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import { ApiDetails, KEY } from "../../Constants";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers";
import CryptoJS from "crypto-js";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
export default function Analytics(props) {
  let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
    localStorage.getItem("myMap"),
    KEY
  );
  let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

  const [year, setYear] = useState(new Date());
  const [data, setData] = useState();
  //Trying To Use UseEffect To Set Component Data
  useEffect(() => {
    props.refreshSideBar();
    //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
    fetch(ApiDetails + "pegasus/visionary/analytics/", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
        year: year.getFullYear(),
      }),
    })
      .then(async (response) => {
        let expenses = await response.json();
        if (response.status === 200) {
          // console.log(expenses);
          setData(expenses);
          //Fetch Expense Types
          props.snackBar({
            text: "Data Retrieved Successfully",
            type: "success",
          });
        } else {
          props.snackBar({
            text: "Something Went Wrong. Try Again Later",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
  }, [year]);

  return (
    <div
      style={{
        flex: 4,
        margin: "10px",
        padding: "20px",
        WebkitBoxShadow: "5px 5px 9px 2px rgba(0,0,0,0.2)",
        boxShadow: "5px 5px 9px 2px rgba(0,0,0,0.2)",
      }}
    >
      <div
        style={{
          width: "100%",
          // fontSize: "20px",
          marginBottom: "10px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <div>GENERAL OVERVIEW</div>
        <br />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            id="outlined-basic"
            variant="contained"
            label="Year"
            openTo="year"
            views={["year"]}
            value={year}
            onChange={(newValue) => {
              setYear(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>

      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 4,
            height: "300px",
          }}
        >
          {data === undefined ? (
            <></>
          ) : (
            <ResponsiveContainer width="95%">
              <AreaChart
                // width={730}
                height={500}
                data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#FF0000" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="transactions"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorPv)"
                />
                <Area
                  type="monotone"
                  dataKey="expenses"
                  stroke="#82ca9d"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <small>
            This is a visual representation of all property monthly incomes and
            expenses
          </small>
          <br />
          <br />
          <div>
            <small>KEY:</small>
            <ul style={{ listStyle: "none" }}>
              <li
                style={{
                  display: "flex",
                  // justifyContent: "left",
                }}
              >
                <section
                  style={{
                    height: "20px",
                    width: "20px",
                    textAlign: "center",
                    marginRight: "10px",
                    backgroundColor: "#07f32e",
                  }}
                ></section>
                - Income
              </li>
              <br />
              <li
                style={{
                  display: "flex",
                  height: "20px",
                  textAlign: "center",
                  marginBottom: "10px",
                  // justifyContent: "left",
                }}
              >
                <section
                  style={{
                    height: "20px",
                    width: "20px",
                    textAlign: "center",
                    marginRight: "10px",
                    backgroundColor: "#9d1616",
                  }}
                ></section>
                - Expenses
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
