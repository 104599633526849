import React, {useState} from "react";
import "./featuredInfo.css";
import {DownloadForOfflineOutlined,} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {Button, TextField} from "@material-ui/core";
import {ArrowDropDown, ArrowDropUp, FilterListOutlined,} from "@material-ui/icons";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {ApiDetails, KEY} from "../../Constants";
import {Autocomplete, Menu, MenuItem} from "@mui/material";
import CryptoJS from "crypto-js";
import {Form, Formik} from "formik";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
) {
    return (
        <Box sx={{display: "flex", alignItems: "center"}}>
            <Box sx={{width: "100%", mr: 1}}>
                <LinearProgress
                    sx={{height: "20px"}}
                    style={{
                        color: "green ",
                    }}
                    variant="determinate"
                    {...props}
                />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HtmlTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

export default function FeaturedInfo(props) {
    const [state, setStatus] = React.useState(localStorage.getItem("isNew"));

    const handleClose = () => {
        setAnchorEl(null);
        setInfoModal(false);
        setReportModal(false);
        setDocumentsModal(false);
    };

    const [dateFrom, setDateFrom] = React.useState(new Date());
    const [propertyId, setPropertyId] = React.useState(null);

    const [documentID, setDocumentID] = React.useState(null);

    let initialValues = {
        id: "",
        first_name: "",
        description: "",
        amount: "",
    };

    let documentInitialValues = {
        id: "",
        first_name: "",
        description: "",
        amount: "",
    };

    //Modal states
    const [infoModal, setInfoModal] = useState(false);
    const [reportModal, setReportModal] = useState(false);
    const [documentsModal, setDocumentsModal] = useState(false);

    const [methodOneIcon, setMethodOneIcon] = useState(<ArrowDropDown/>);
    const [methodOne, setMethodOne] = useState("none");

    const [methodTwoIcon, setMethodTwoIcon] = useState(<ArrowDropDown/>);
    const [methodTwo, setMethodTwo] = useState("none");

    const [methodThreeIcon, setMethodThreeIcon] = useState(<ArrowDropDown/>);
    const [methodThree, setMethodThree] = useState("none");

    let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.getItem("myMap"),
        KEY
    );
    let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.portfolioDetails,
        KEY
    );

    let originalText = portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8);
    let originalText2 = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    const [properties] = useState(JSON.parse(originalText)[1][1]);

    //Tax
    const [tax, setTax] = useState(
        new Map(Object.entries(JSON.parse(originalText)[16][1])).get(
            "Total tax"
        ) === undefined
            ? 0
            : new Map(Object.entries(JSON.parse(originalText)[16][1])).get(
                "Total tax"
            )
    );
    const [taxDescription, setTaxDescription] = useState("all");

    //Income
    //received income
    const [income, setIncome] = useState(
        new Map(Object.entries(JSON.parse(originalText)[9][1])).get(
            "Total income"
        ) === undefined
            ? 0
            : new Map(Object.entries(JSON.parse(originalText)[9][1])).get(
                "Total income"
            )
    );
    //expected income
    const [incomeExpected, setIncomeExpected] = useState(
        new Map(Object.entries(JSON.parse(originalText)[8][1])).get(
            "Total income"
        ) === undefined
            ? 0
            : new Map(Object.entries(JSON.parse(originalText)[8][1])).get(
                "Total income"
            )
    );
    //income card description
    const [incomeCardDescription, setIncomeCardDescription] =
        useState("expected total");

    //Deposit
    const [deposit, setDeposit] = useState(
        new Map(Object.entries(JSON.parse(originalText)[17][1])).get(
            "Total deposit"
        ) === undefined
            ? 0
            : new Map(Object.entries(JSON.parse(originalText)[17][1])).get(
                "Total deposit"
            )
    );
    const [depositDescription, setDepositDescription] =
        useState("All properties");

    //Expense
    const [expenses, setExpenses] = useState(
        new Map(Object.entries(JSON.parse(originalText)[12][1])).get(
            "Total expenses"
        ) === undefined
            ? 0
            : new Map(Object.entries(JSON.parse(originalText)[12][1])).get(
                "Total expenses"
            )
    );
    const [expenseDescription, setExpensesDescription] =
        useState("All properties");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        // console.log(properties);
        setAnchorEl(event.currentTarget);
    };
    const handleFiltering = (id, name) => {
        //Set expected income
        let expectedIncome;
        new Map(Object.entries(JSON.parse(originalText)[8][1])).forEach(
            (value, key) => {
                if (key.toString() === id.toString()) {
                    expectedIncome = value;
                }
            }
        );
        setIncomeExpected(
            isNaN(parseInt(expectedIncome)) ? 0 : parseInt(expectedIncome)
        );

        //Set received income
        let receivedIncome;
        new Map(Object.entries(JSON.parse(originalText)[9][1])).forEach(
            (value, key) => {
                if (key.toString() === id.toString()) {
                    receivedIncome = value;
                }
            }
        );
        setIncome(isNaN(parseInt(receivedIncome)) ? 0 : parseInt(receivedIncome));

        //Set expense value
        let expenseValue;
        new Map(Object.entries(JSON.parse(originalText)[12][1])).forEach(
            (value, key) => {
                if (key.toString() === id.toString()) {
                    expenseValue = value;
                }
            }
        );
        setExpenses(isNaN(parseInt(expenseValue)) ? 0 : parseInt(expenseValue));

        //Set tax
        let taxValue;
        new Map(Object.entries(JSON.parse(originalText)[16][1])).forEach(
            (value, key) => {
                if (key.toString() === id.toString()) {
                    taxValue = value;
                }
            }
        );
        setTax(isNaN(parseInt(taxValue)) ? 0 : parseInt(taxValue));

        //Set deposit
        let depositValue;
        new Map(Object.entries(JSON.parse(originalText)[17][1])).forEach(
            (value, key) => {
                if (key.toString() === id.toString()) {
                    depositValue = value;
                }
            }
        );
        setDeposit(isNaN(parseInt(depositValue)) ? 0 : parseInt(depositValue));

        //Set property name
        setTaxDescription(name);
        setDepositDescription(name);
        setExpensesDescription(name);
        setIncomeCardDescription("expected from " + name);
    };

    const downloadPnL = () => {
        let data = {
            landlord_id: JSON.parse(originalText2)[0][1],
            year: dateFrom.getFullYear().toString(),
            month: parseInt(dateFrom.getMonth()) + parseInt(1),
            property_id: propertyId,
        };

        console.log(data);

        // props.snackBar({
        //   text: "Feature will be available shortly ",
        //   type: "warning",
        // });
        // return;
        let fileName;
        // console.log(JSON.parse(originalText2));

        // //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/authorization/p&l", {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then(async (res) => {
                if (res.status === 200) {
                    for (let pair of res.headers.entries()) {
                        // accessing the entries
                        if (pair[0] === "content-disposition") {
                            // key I'm looking for in this instance
                            fileName = pair[1]; // saving that value where I can use it
                        }
                    }

                    props.snackBar({
                        text: "File Download Will Start In A Few",
                        type: "success",
                    });

                    let blob = await res.blob();

                    let href = window.URL.createObjectURL(blob);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } else if (res.status === 404) {
                    props.snackBar({
                        text: "File Not Found",
                        type: "error",
                    });
                } else if (res.status === 500) {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const downloadDocument = () => {

        if (documentID === null) {
            props.snackBar({
                text: "Kindly select a file ",
                type: "error",
            });
            return;
        }
        let data = {
            landlord_id: JSON.parse(originalText2)[0][1],
            id: documentID
        };

        let fileName;

        fetch(ApiDetails + "pegasus/visionary/files/download/specific_file", {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then(async (res) => {
                if (res.status === 200) {
                    for (let pair of res.headers.entries()) {
                        // accessing the entries
                        if (pair[0] === "content-disposition") {
                            // key I'm looking for in this instance
                            fileName = pair[1]; // saving that value where I can use it
                        }
                    }

                    props.snackBar({
                        text: "File Download Will Start In A Few",
                        type: "success",
                    });

                    let blob = await res.blob();

                    let href = window.URL.createObjectURL(blob);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } else if (res.status === 404) {
                    props.snackBar({
                        text: "File Not Found",
                        type: "error",
                    });
                } else if (res.status === 500) {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => console.log(err));
    };
    const resetDashboardValues = () => {
        //Income
        //received income
        setIncome(
            new Map(Object.entries(JSON.parse(originalText)[9][1])).get(
                "Total income"
            ) === undefined
                ? 0
                : new Map(Object.entries(JSON.parse(originalText)[9][1])).get(
                    "Total income"
                )
        );
        //expected income
        setIncomeExpected(
            new Map(Object.entries(JSON.parse(originalText)[8][1])).get(
                "Total income"
            ) === undefined
                ? 0
                : new Map(Object.entries(JSON.parse(originalText)[8][1])).get(
                    "Total income"
                )
        );
        //income card description
        setIncomeCardDescription("expected total");

        //tax
        setTax(
            new Map(Object.entries(JSON.parse(originalText)[16][1])).get(
                "Total tax"
            ) === undefined
                ? 0
                : new Map(Object.entries(JSON.parse(originalText)[16][1])).get(
                    "Total tax"
                )
        );
        setTaxDescription("all");

        //Deposit
        setDeposit(
            new Map(Object.entries(JSON.parse(originalText)[17][1])).get(
                "Total deposit"
            ) === undefined
                ? 0
                : new Map(Object.entries(JSON.parse(originalText)[17][1])).get(
                    "Total deposit"
                )
        );
        setDepositDescription("All properties");

        //Expense
        setExpenses(
            new Map(Object.entries(JSON.parse(originalText)[12][1])).get(
                "Total expenses"
            ) === undefined
                ? 0
                : new Map(Object.entries(JSON.parse(originalText)[12][1])).get(
                    "Total expenses"
                )
        );
        setExpensesDescription("All properties");
    };

    return (
        <>

            <Dialog
                open={documentsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Select document
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <Formik initialValues={documentInitialValues} onSubmit={downloadDocument}>
                        {() => (
                            <Form noValidate>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                >

                                    <div>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={new Map(JSON.parse(originalText))
                                                .get("documents")
                                                .map((document, index) => {
                                                    return document.document_name;
                                                })}
                                            style={{width: 300, padding: "10px 10px"}}
                                            onChange={(e, value) => {
                                                console.log('Selected value : ' + value)
                                                if (value === null) {
                                                    setDocumentID(null);
                                                } else {
                                                    let documentSelected = new Map(
                                                        JSON.parse(originalText)
                                                    )
                                                        .get("documents")
                                                        .filter(
                                                            (document, index) =>
                                                                document.document_name === value
                                                        );
                                                    setDocumentID(
                                                        new Map(
                                                            Object.entries(
                                                                new Map(Object.entries(documentSelected)).get(
                                                                    "0"
                                                                )
                                                            )
                                                        ).get("id")
                                                    );
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    id="outlined-basic"
                                                    label="Select document"
                                                    variant="outlined"
                                                    name="Document"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button onClick={handleClose}>
                                        <span style={{color: "red"}}>Cancel</span>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            downloadDocument();
                                        }}
                                    >
                                        <span style={{color: "green"}}>Download</span>
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <Dialog
                open={reportModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Profit and loss statement
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <Formik initialValues={initialValues} onSubmit={downloadPnL}>
                        {() => (
                            <Form noValidate>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disableFuture
                                                id="outlined-basic"
                                                variant="outlined"
                                                label="Date"
                                                openTo="year"
                                                views={["year", "month"]}
                                                value={dateFrom}
                                                onChange={(newValue) => {
                                                    setDateFrom(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined"/>
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <div>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={new Map(JSON.parse(originalText))
                                                .get("properties")
                                                .map((property, index) => {
                                                    return property.property_name;
                                                })}
                                            style={{width: 300, padding: "10px 10px"}}
                                            onChange={(e, value) => {
                                                if (value === null) {
                                                    setPropertyId(null);
                                                } else {
                                                    let propertySelected = new Map(
                                                        JSON.parse(originalText)
                                                    )
                                                        .get("properties")
                                                        .filter(
                                                            (property, index) =>
                                                                property.property_name === value
                                                        );
                                                    setPropertyId(
                                                        new Map(
                                                            Object.entries(
                                                                new Map(Object.entries(propertySelected)).get(
                                                                    "0"
                                                                )
                                                            )
                                                        ).get("id")
                                                    );
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    id="outlined-basic"
                                                    label="Select property"
                                                    variant="outlined"
                                                    name="property"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button onClick={handleClose}>
                                        <span style={{color: "red"}}>Cancel</span>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            downloadPnL();
                                        }}
                                    >
                                        <span style={{color: "green"}}>Generate</span>
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            {/*DELETE MODAL*/}
            <Dialog
                open={infoModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>How To Estimate Property Value</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <ul>
                            <li>
                                There are three primary methods used to evaluate a property's
                                value.
                            </li>
                        </ul>
                        <br/>
                        <h3
                            style={{
                                display: "flex",
                            }}
                        >
                            1. Sales Comparable Approach (Comps){" "}
                            <div
                                style={{
                                    marginLeft: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (methodOne !== "") {
                                        setMethodOne("");
                                        setMethodOneIcon(<ArrowDropUp/>);
                                    } else {
                                        setMethodOne("none");
                                        setMethodOneIcon(<ArrowDropDown/>);
                                    }
                                }}
                            >
                                {methodOneIcon}
                            </div>
                        </h3>
                        <Typography>
                            <p
                                style={{
                                    margin: "10px",
                                    padding: "15px",
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                    display: methodOne,
                                }}
                            >
                                <ul>
                                    <li>
                                        This method looks at properties within your area that have
                                        recently sold and uses the property that is the most similar
                                        to yours to determine the value.
                                    </li>
                                    <li>
                                        {" "}
                                        This is the primary method of determining property value as
                                        it uses the market to make an estimate and ulitmately it is
                                        the market that determines the value.
                                    </li>
                                </ul>
                            </p>
                        </Typography>
                        <br/>

                        <h3
                            style={{
                                display: "flex",
                            }}
                        >
                            2. Cost Approach
                            <div
                                style={{
                                    marginLeft: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (methodTwo !== "") {
                                        setMethodTwo("");
                                        setMethodTwoIcon(<ArrowDropUp/>);
                                    } else {
                                        setMethodTwo("none");
                                        setMethodTwoIcon(<ArrowDropDown/>);
                                    }
                                }}
                            >
                                {methodTwoIcon}
                            </div>
                        </h3>
                        <Typography>
                            <p
                                style={{
                                    margin: "10px",
                                    padding: "15px",
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                    display: methodTwo,
                                }}
                            >
                                <ul>
                                    <li>
                                        If you had to build the property from the ground up, how
                                        much would the materials plus labour cost to achieve the
                                        same result.
                                    </li>
                                    <li>
                                        This method mostly comes to play when determining the
                                        property insurance as in some markets it would cost more to
                                        build than to purchase while in other markets the vice versa
                                        applies.
                                    </li>
                                </ul>
                            </p>
                        </Typography>

                        <br/>

                        <h3
                            style={{
                                display: "flex",
                            }}
                        >
                            3. Income Approach
                            <div
                                style={{
                                    marginLeft: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (methodThree !== "") {
                                        setMethodThree("");
                                        setMethodThreeIcon(<ArrowDropUp/>);
                                    } else {
                                        setMethodThree("none");
                                        setMethodThreeIcon(<ArrowDropDown/>);
                                    }
                                }}
                            >
                                {methodThreeIcon}
                            </div>
                        </h3>
                        <Typography>
                            <p
                                style={{
                                    margin: "10px",
                                    padding: "15px",
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                    display: methodThree,
                                }}
                            >
                                <ul>
                                    <li>
                                        This method is applicable where no comparable sales can be
                                        found.It uses the Gross Rent Multiplier (GRM) and the Annual
                                        Gross Rent to estimate the property value.
                                        <br/>
                                        <em>
                                            (<b>GRM * Annual Gross Income</b>)
                                        </em>
                                    </li>
                                    <li>
                                        This is the method that we use with a GRM of <b>10</b>.
                                    </li>
                                </ul>
                            </p>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "green"}}>Close</span>
                    </Button>
                </DialogActions>
            </Dialog>
            <Menu
                anchorEl={anchorEl}
                // id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{horizontal: "right", vertical: "top"}}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
            >
                {properties.map((property, index) => {
                    return (
                        <MenuItem
                            onClick={(event) => {
                                handleFiltering(property.id, property.property_name);
                            }}
                            key={index}
                        >
                            <small>{property.property_name}</small>
                        </MenuItem>
                    );
                })}
                <MenuItem onClick={resetDashboardValues}>
                    <small>All properties</small>
                </MenuItem>
            </Menu>
            {state === "yes" ? (
                <></>
            ) : (
                <div className="featured">
                    <div className="featuredTop">
                        <div className="featuredItem">
                            <span className="featuredTitle">MPESA DOCUMENTS</span>
                            <div className="featuredMoneyContainer">
                <span
                    className="featuredMoney"
                    style={{
                        width: "100%",
                    }}
                >
                  <div
                      style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                      }}
                  >
                    <div>Download Documents</div>
                    <DownloadForOfflineOutlined
                        style={{color: "#398DD2"}}
                        onClick={() => {
                            setDocumentsModal(true);
                        }}
                    />
                  </div>
                </span>
                            </div>
                            <div className="featuredSub">Download Till/Paybill application documents</div>
                        </div>

                        <div className="featuredItem">
              <span
                  className="featuredTitle"
                  style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                  }}
              >
                Tax due
                <FilterListOutlined
                    style={{
                        color: "#398DD2",
                    }}
                    onClick={handleClick}
                />
              </span>
                            <div className="featuredMoneyContainer">
                <span
                    className="featuredMoney"
                    style={{color: "rgb(255,70,70)"}}
                >
                  {tax.toLocaleString("en-US", {
                      style: "currency",
                      currency: "KSH",
                  })}
                </span>
                            </div>
                            <div className="featuredSub">
                                10% of {taxDescription} rent collected
                            </div>
                        </div>

                        <div
                            className="featuredItem"
                            style={{
                                backgroundColor:
                                    new Map(JSON.parse(originalText)).get("expectedIncome") >
                                    new Map(JSON.parse(originalText)).get("receivedMoney")
                                        ? "rgb(250,170,170)"
                                        : "rgb(170,250,170)",
                            }}
                        >
              <span
                  className="featuredTitle"
                  style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                  }}
              >
                Income
                <FilterListOutlined
                    style={{
                        color: "#398DD2",
                    }}
                    onClick={handleClick}
                />
              </span>
                            <div className="featuredMoneyContainer">
                                <div style={{display: "flex"}}>
                                    {" "}
                                    <span className="featuredMoney">
                    {parseFloat(income).toLocaleString("en-US", {
                        style: "currency",
                        currency: "KSH",
                    })}
                  </span>
                                    {/*Positive Span*/}
                                    <span className="featuredMoneyRate">
                    {
                        ((parseFloat(income) / parseFloat(incomeExpected)) * 100)
                            .toString()
                            .split(".")[0]
                    }
                                        %{/*<ArrowDownwardOutlined className="featuredIcon" />*/}
                  </span>
                                </div>
                            </div>

                            <div className="featuredSub" style={{color: "black"}}>
                                {parseFloat(incomeExpected).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                                <small> {incomeCardDescription}</small>
                            </div>
                        </div>

                        <div className="featuredItem">
                            <Link className="linkItem" to={"/propertyList"}>
                                <span className="featuredTitle">Occupancy</span>
                                <div className="featuredMoneyContainer">
                                    <Box sx={{width: "100%"}}>
                                        <LinearProgressWithLabel
                                            value={new Map(JSON.parse(originalText)).get("occupancy")}
                                        />
                                    </Box>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="featuredBottom">
                        {/*  <div*/}
                        {/*    className="featuredItem"*/}
                        {/*    style={{ backgroundColor: new Map(JSON.parse(originalText)).get("debt") > 0 ?  "rgb(250,170,170)":"rgb(170,250,170)" }}*/}
                        {/*  >*/}
                        {/*    <span className="featuredTitle">Total Debt</span>*/}
                        {/*    <div className="featuredMoneyContainer">*/}
                        {/*      <span className="featuredMoney">*/}
                        {/*        {new Map(JSON.parse(originalText))*/}
                        {/*          .get("debt")*/}
                        {/*          .toLocaleString("en-US", {*/}
                        {/*            style: "currency",*/}
                        {/*            currency: "KSH",*/}
                        {/*          })}*/}
                        {/*      </span>*/}
                        {/*    </div>*/}

                        {/*    <div className="featuredSub">Properties' Debt</div>*/}
                        {/*  </div>*/}
                        {/*</>*/}

                        <div className="featuredItem">
              <span
                  className="featuredTitle"
                  style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                  }}
              >
                Total deposit held
                <FilterListOutlined
                    style={{
                        color: "#398DD2",
                    }}
                    onClick={handleClick}
                />
              </span>
                            <div className="featuredMoneyContainer">
                <span className="featuredMoney">
                  {deposit.toLocaleString("en-US", {
                      style: "currency",
                      currency: "KSH",
                  })}
                </span>
                            </div>
                            <div className="featuredSub">{depositDescription}</div>
                        </div>

                        <div className="featuredItem">
                            {/*<Link className="linkItem" to={"/expenses"}>*/}
                            <span
                                className="featuredTitle"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                Expenses
                <FilterListOutlined
                    style={{
                        color: "#398DD2",
                    }}
                    onClick={handleClick}
                />
              </span>
                            <div className="featuredMoneyContainer">
                <span
                    className="featuredMoney"
                    style={{color: "rgb(255,70,70)"}}
                >
                  {expenses.toLocaleString("en-US", {
                      style: "currency",
                      currency: "KSH",
                  })}
                </span>
                            </div>
                            <div className="featuredSub">{expenseDescription}</div>
                            {/*</Link>*/}
                        </div>

                        <div className="featuredItem">
                            {/*<Link className="linkItem" to={"/expenses"}>*/}
                            <span
                                className="featuredTitle"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                Profit
                <FilterListOutlined
                    style={{
                        color: "#398DD2",
                    }}
                    onClick={handleClick}
                />
              </span>
                            <div className="featuredMoneyContainer">
                <span
                    className="featuredMoney"
                    style={{
                        // color: "rgb(81,164,40)"
                        color: (
                            parseFloat(income) -
                            parseFloat(expenses) -
                            parseFloat(tax)
                        ) < 0 ? "rgb(255,70,70)" : "rgb(81,164,40)"

                    }}
                >
                  {(
                      parseFloat(income) -
                      parseFloat(expenses) -
                      parseFloat(tax)
                  ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "KSH",
                  })}
                </span>
                            </div>
                            <div className="featuredSub">{expenseDescription}</div>
                            {/*</Link>*/}
                        </div>

                        <div className="featuredItem">
                            <span className="featuredTitle">P&L</span>
                            <div className="featuredMoneyContainer">
                <span
                    className="featuredMoney"
                    style={{
                        width: "100%",
                    }}
                >
                  <div
                      style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                      }}
                  >
                    <div>Download P&L</div>
                    <DownloadForOfflineOutlined
                        style={{color: "#398DD2"}}
                        onClick={() => {
                            setReportModal(true);
                        }}
                    />
                  </div>
                </span>
                            </div>
                            <div className="featuredSub">Profit And Loss Statement</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
