import "./userList.css";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {
  createTheme,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { InputLabel, TextField, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { ApiDetails, KEY } from "../../Constants";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import "react-edit-text/dist/index.css";
import CryptoJS from "crypto-js";
import {AddCircleOutlined, Delete} from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//create your forceUpdate hook WHICH DID NOT WORK

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      fontSize: 16,
      color: "darkblue",
      backgroundColor: "rgb(245,245,245)",
    },
  },
});

export default function Expenses(props) {
  const matches = useMediaQuery("(max-width:700px)");

  let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
    localStorage.getItem("myMap"),
    KEY
  );
  let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(
    localStorage.portfolioDetails,
    KEY
  );
  let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);
  let originalText2 = portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8);

  const [expenseDate, setExpenseDate] = React.useState(new Date());

  const [dateFrom, setDateFrom] = React.useState(new Date("1999-08-04"));
  const [dateTo, setDateTo] = React.useState(new Date());

  let dateFromVar = new Date("1999-08-04");
  let dateToVar = new Date();

  const [detailsFetched, setDetailsFetched] = React.useState(false);
  let [expenses, setExpenses] = React.useState([]);

  const [expenseDetails, setExpenseDetails] = useState();

  //Loading Screen
  const [backDrop, setBackDrop] = useState(
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={null}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  //Trying To Use UseEffect To Set Component Data
  useEffect(() => {
    props.refreshSideBar();
    //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
    fetch(ApiDetails + "pegasus/visionary/expenses/", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
      }),
    })
      .then(async (response) => {
        let expenses = await response.json();
        if (response.status === 200) {
          //Fetch Expense Types
          fetch(ApiDetails + "pegasus/visionary/expenseTypes/", {
            method: "GET",
          })
            .then(async (response) => {
              let expenseTypes = await response.json();
              if (response.status === 200) {
                // console.log(expenses);
                setExpenseTypes(expenseTypes);
                setExpenses(expenses);
                setSearchResults(expenses);
                setDetailsFetched(true);
                setBackDrop(<></>);
              } else {
                props.snackBar({
                  text: "Something Went Wrong. Try Again Later",
                  type: "error",
                });
              }
            })
            .catch((err) => {
              console.log(err);
              props.snackBar({
                text: "Something Went Wrong. Call Customer Care For Assistance.",
                type: "error",
              });
            });
          // props.snackBar({
          //   text: "Expenses Retrieved Successfully",
          //   type: "success",
          // });
        } else {
          props.snackBar({
            text: "Something Went Wrong. Try Again Later",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
  }, []);

  //Modal states
  const [addExpenseModal, setAddExpenseModal] = useState(false);
  const [expenseDetailsModal, setExpenseDetailsModal] = useState(false);

  //Units State
  const [units, setUnits] = React.useState(null);
  const [selectedUnit, setSelectedUnit] = React.useState(null);
  const [expenseTypes, setExpenseTypes] = useState([
    "Maintenance",
    "Luxury",
    "Caretaker Payment",
  ]);

  const [addTenantPropertyName, setAddTenantPropertyName] = React.useState();
  const [selectedExpenseType, setSelectedExpenseType] = React.useState();
  let [propertyID, setPropertyID] = React.useState();
  const [description, setDescription] = useState();

  let initialValues = {
    id: "",
    first_name: "",
    description: "",
    amount: "",
  };

  const classes = useStyles();

  const searchExpensesByDate = () => {
    setSearchResults(
      Array.from(expenses).filter((item) => {
        return (
          new Date(item.date_created) >= dateFromVar &&
          new Date(item.date_created) <= dateToVar
        );
      })
    );
  };

  let [searchResults, setSearchResults] = useState([]);
  const getUnits = () => {
    //Make API call
    //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
    fetch(ApiDetails + "pegasus/visionary/property/getUnits", {
      method: "POST",
      body: JSON.stringify({ property_id: propertyID }),
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.status === 200) {
          setUnits(data);
          props.snackBar({
            text: "Property Units Retreived Successful",
            type: "success",
          });
        } else if (response.status === 401) {
          props.snackBar({
            text: "Maximum concurrent logins reached",
            type: "warning",
          });
        } else if (response.status === 400) {
          props.snackBar({
            text: "Invalid Credentials Provided.",
            type: "error",
          });
        } else if (response.status === 500) {
          if (data["Message"].includes("duplicate key")) {
            if (data["Message"].includes("owners_email_address_uindex")) {
              props.snackBar({
                text: "Email Address Already Exists",
                type: "error",
              });
              return;
            }
          }
          props.snackBar({
            text: "Something went wrong. Please try again later.",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Try Again Later",
          type: "error",
        });
      });
  };
  const handleClose = () => {
    setAddExpenseModal(false);
    setExpenseDetailsModal(false);
  };
  const searchExpense = (e) => {
    setSearchResults(
      expenses.filter((item) =>
        item.description
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };
  const getAllExpenses = () => {
    fetch(ApiDetails + "pegasus/visionary/expenses/", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
      }),
    })
      .then(async (response) => {
        let expenses = await response.json();
        if (response.status === 200) {
          //Fetch Expense Types
          setExpenses(expenses);
          setSearchResults(expenses);
        } else {
          props.snackBar({
            text: "Something Went Wrong. Try Again Later",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
  };
  const addExpense = (values) => {
    if (description === undefined || description.toString().trim() === "") {
      props.snackBar({
        text: "Description can not be empty",
        type: "error",
      });
      return;
    }
    if (propertyID === undefined || propertyID.toString().trim() === "") {
      props.snackBar({
        text: "Kindly select the property",
        type: "error",
      });
      return;
    }
    if (
      selectedExpenseType === undefined ||
      selectedExpenseType.toString().trim() === ""
    ) {
      props.snackBar({
        text: "Kindly select the expense type",
        type: "error",
      });
      return;
    }

    //Add expense to database
    let body = {
      description: description,
      property_id: propertyID,
      expense_type: selectedExpenseType,
      house_number: selectedUnit === null ? "" : selectedUnit,
      cost: values.amount,
      landlord_id: JSON.parse(originalText)[0][1],
      year:expenseDate.getFullYear(),
      month:expenseDate.getMonth(),
      day:expenseDate.getDate(),
    };

    fetch(ApiDetails + "pegasus/visionary/expenses/add", {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (response.status === 200) {
          props.snackBar({
            text: "Expense Added Successfully",
            type: "success",
          });
          getAllExpenses();
          handleClose();
        } else {
          props.snackBar({
            text: "Something Went Wrong. Try Again Later",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
    setSelectedUnit(null);
  };
  const DeleteExpense = (id) => {
    //Add expense to database
    let body = {
      expense_id:id
    };

    fetch(ApiDetails + "pegasus/visionary/expenses/delete", {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (response.status === 200) {
          props.snackBar({
            text: "Expense Deleted Successfully",
            type: "success",
          });
          getAllExpenses();
          handleClose();
        } else {
          props.snackBar({
            text: "Something Went Wrong. Try Again Later",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
    setSelectedUnit(null);
  };

  const columns = [
    {
      field: "description",
      headerName: "Description",
      headerAlign: "center",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "house_number",
      headerName: "HSE NO",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        let demo = params.row.house_number;
        return <div>{demo === "" ? <>-</> : <>{demo}</>}</div>;
      },
    },
    {
      field: "expense_type",
      headerName: "Expense Type",
      headerAlign: "center",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        let typeOfExpense;
        // eslint-disable-next-line array-callback-return
        expenseTypes.map((expenseType, index) => {
          if (expenseType.id === params.row.expense_type) {
            typeOfExpense = expenseType.type;
          }
        });
        return <div>{typeOfExpense}</div>;
      },
    },
    {
      field: "Property Name",
      headerName: "Property",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        let demo;
        try {
          demo = new Map(
            Object.entries(
              new Map(JSON.parse(originalText2))
                .get("properties")
                .filter((property) => property.id === params.row.property_id)[0]
            )
          ).get("property_name");
        } catch (e) {
          demo = "-";
        }
        return <div>{demo}</div>;
      },
    },
    {
      field: "cost",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {parseFloat(params.row.cost).toLocaleString("en-US", {
              style: "currency",
              currency: "KSH",
            })}
          </div>
        );
      },
    },
    {
      field: "date_created",
      headerName: "Date",
      headerAlign: "center",
      minWidth: 200,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              width: "100px",
              display: "flex",
              fontSize: "13px",
              justifyContent: "center",
            }}
          >
            {params.row.date_created}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "Actions",
      headerAlign: "center",
      minWidth: 150,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              width: "150px",
              display: "flex",
              fontSize: "13px",
              justifyContent: "space-between",
              alignContent:"center",
              alignItems:"center"
            }}
          >
            <Button
              onClick={() => {
                let property_name;
                try {
                  property_name = new Map(
                    Object.entries(
                      new Map(JSON.parse(originalText2))
                        .get("properties")
                        .filter(
                          (property) => property.id === params.row.property_id
                        )[0]
                    )
                  ).get("property_name");
                } catch (e) {
                  property_name = "**NONE**";
                }

                let typeOfExpense;
                expenseTypes.map((expenseType, index) => {
                  if (expenseType.id === params.row.expense_type) {
                    typeOfExpense = expenseType.type;
                  }
                });

                setExpenseDetails({
                  house_number: params.row.house_number,
                  description: params.row.description,
                  property_name: property_name,
                  expense_type: typeOfExpense,
                  date_created: params.row.date_created,
                  amount: params.row.cost,
                });
                setExpenseDetailsModal(true);
              }}
              variant="outlined"
            >
              Details
            </Button>

            <Delete
            style={{
              color:"red",
            }}
            onClick={() => {
             DeleteExpense(params.row.id)
            }}

            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {backDrop}
      {/*Expense Details MODAL*/}
      <Dialog
        open={expenseDetailsModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Expense Details
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "10px",
          }}
        >
          {expenseDetails === undefined ? (
            <></>
          ) : (
            <>
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                id="outlined-basic"
                label={"Property name"}
                value={new Map(Object.entries(expenseDetails)).get(
                  "property_name"
                )}
                variant="outlined"
              />
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                id="outlined-basic"
                label={"House number"}
                value={
                  new Map(Object.entries(expenseDetails)).get(
                    "house_number"
                  ) === ""
                    ? "-"
                    : new Map(Object.entries(expenseDetails)).get(
                        "house_number"
                      )
                }
                variant="outlined"
              />
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                id="outlined-basic"
                label={"Expense type"}
                value={new Map(Object.entries(expenseDetails)).get(
                  "expense_type"
                )}
                variant="outlined"
              />
              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                id="outlined-basic"
                label={"Amount"}
                value={new Map(Object.entries(expenseDetails)).get("amount")}
                variant="outlined"
              />

              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                id="outlined-multiline-flexible"
                label="Description"
                multiline
                maxRows={5}
                variant="outlined"
                value={new Map(Object.entries(expenseDetails)).get(
                  "description"
                )}
              />

              <TextField
                style={{ width: "100%", marginBottom: "10px" }}
                id="outlined-basic"
                label={"Date"}
                value={new Map(Object.entries(expenseDetails)).get(
                  "date_created"
                )}
                variant="outlined"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <span style={{ color: "red" }}>Close</span>
          </Button>
        </DialogActions>
      </Dialog>

      {/*Add Expense MODAL*/}
      <Dialog
        open={addExpenseModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Add Expense
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "10px",
          }}
        >
          <Formik initialValues={initialValues} onSubmit={addExpense}>
            {(props) => (
              <Form noValidate>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                      disableFuture
                      id="outlined-basic"
                      variant="outlined"
                      label="Expense Date"
                      openTo="year"
                      views={["year", "month", "day"]}
                      value={expenseDate}
                      onChange={(newValue) => {
                        setExpenseDate(newValue);
                      }}
                      renderInput={(params) => (
                          <TextField  style={{
                            marginTop: "10px",
                            width: "100%",
                          }} variant={"outlined"} {...params} />
                      )}
                  />
                </LocalizationProvider>
                <FormControl
                  required
                  style={{
                    marginTop: "10px",
                    width: "100%",
                  }}
                  label="Property *"
                  defaultValue={addTenantPropertyName}
                >
                  <InputLabel id="demo-simple-select-required-label">
                    Select Property
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={addTenantPropertyName}
                    onChange={(e) => {
                      setAddTenantPropertyName(e.target.value);
                      propertyID = e.target.value;
                      setPropertyID(e.target.value);
                      getUnits();
                    }}
                  >
                    {new Map(JSON.parse(originalText2))
                      .get("properties")
                      .map((property, index) => (
                        <MenuItem key={index} value={property.id}>
                          {property.property_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {units !== null ? (
                  <>
                    <FormControl
                      name="house_number"
                      required
                      style={{
                        marginTop: "10px",
                        width: "100%",
                      }}
                      label="Property *"
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Select House NO
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={selectedUnit}
                        label="Unit *"
                        onChange={(e) => {
                          setSelectedUnit(e.target.value);
                        }}
                      >
                        {units.map((unit, index) => {
                          let hse = unit.state;
                          return (
                            <MenuItem
                              key={index}
                              value={unit.unit}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{unit.unit}</span>
                              <small>{unit.state.toLowerCase()}</small>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>Required</FormHelperText>
                    </FormControl>
                    <br />
                  </>
                ) : (
                  <>
                    <FormControl
                      name="house_number"
                      required
                      style={{
                        marginTop: "10px",
                        width: "100%",
                      }}
                      label="Property *"
                      disabled={true}
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Select House NO
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={selectedUnit}
                        label="Unit *"
                        onChange={(e) => {
                          setSelectedUnit(e.target.value);
                        }}
                      ></Select>
                      <FormHelperText>Required</FormHelperText>
                    </FormControl>
                    <br />
                  </>
                )}
                <FormControl
                  required
                  style={{
                    marginTop: "10px",
                    width: "100%",
                  }}
                  label="Expense Type"
                  defaultValue={selectedExpenseType}
                >
                  <InputLabel id="demo-simple-select-required-label">
                    Expense Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={selectedExpenseType}
                    onChange={(e) => {
                      setSelectedExpenseType(e.target.value);
                      // getUnits();
                    }}
                  >
                    {expenseTypes.map((expense, index) => (
                      <MenuItem key={index} value={expense.id}>
                        {expense.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Field
                  as={TextField}
                  name="amount"
                  label="Amount"
                  type="number"
                  fullwidth
                  required
                  variant="outlined"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                />

                <div
                  style={{
                    border: "2px solid black",
                  }}
                >
                  <div>
                    <TextField
                      id="filled-textarea"
                      placeholder={"Enter description"}
                      onChange={(event) => {
                        setDescription(event.target.value);
                      }}
                      multiline
                      maxRows={5}
                      variant="outlined"
                      style={{
                        width: "100%",
                      }}
                    />
                    <br />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "20px",
                  }}
                >
                  <Button onClick={handleClose}>
                    <span style={{ color: "red" }}>Cancel</span>
                  </Button>
                  <Button type="submit">
                    <span style={{ color: "green" }}>Submit</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <div className="userList">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "10px 10px 10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                padding: "0px 10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  id="outlined-basic"
                  variant="outlined"
                  label="Date From ... "
                  openTo="year"
                  views={["year", "month"]}
                  value={dateFrom}
                  onChange={(newValue) => {
                    setDateFrom(newValue);
                    dateFromVar = newValue;
                    searchResults = expenses;
                    searchExpensesByDate();
                  }}
                  renderInput={(params) => (
                    <TextField variant={"outlined"} {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                marginRight: "10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  id="outlined-basic"
                  variant="outlined"
                  label="Date To ..."
                  openTo="year"
                  views={["year", "month"]}
                  value={dateTo}
                  onChange={(newValue) => {
                    setDateTo(newValue);
                    dateToVar = newValue;
                    searchResults = expenses;
                    searchExpensesByDate();
                  }}
                  renderInput={(params) => (
                    <TextField style={{}} variant={"outlined"} {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {matches ? (
              <></>
            ) : (
              <TextField
                id="outlined-basic"
                label="Search Description ... "
                variant="outlined"
                name="firstname"
                onChange={(e) => {
                  searchExpense(e);
                }}
                style={{ paddingRight: "10px" }}
              />
            )}
          </div>

          {matches ? (
            <>
              {" "}
              <AddCircleOutlined
                onClick={(e) => {
                  setAddExpenseModal(true);
                }}
                variant="outlined"
                style={{
                  marginLeft: "10px",
                  marginRight: "20px",
                  color: "green",
                  scale: "1.5",
                }}
              />
            </>
          ) : (
            <Button
              onClick={(e) => {
                setAddExpenseModal(true);
              }}
              variant="outlined"
              style={{
                color: "white",
                text_transform: "uppercase",
                // width: "200px",
                line_height: "50px",
                border_radius: "5px",
                background: "#00B000",
                text_align: "center",
                box_shadow: "inset 0 0 25px rgba(0,0,0,.25)",
                marginRight: "20px",
              }}
            >
              Add Expense
            </Button>
          )}
        </div>
        {detailsFetched ? (
          <div className={"userListDataGrid"}>
            <DataGrid
              className={classes.root}
              rows={searchResults}
              disableSelectionOnClick
              rowHeight={45}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        ) : (
          <div className={"userListDataGrid"}>
            <DataGrid
              className={classes.root}
              rows={searchResults}
              disableSelectionOnClick
              rowHeight={45}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        )}
      </div>
    </>
  );
}
